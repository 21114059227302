import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const galleries = document.querySelectorAll('.js-swiperGallery')

  galleries.forEach((gallery) => {
    const nextButton = gallery.closest('.swiper-gallery').querySelector('.js-swiperGalleryButtonNext')
    const prevButton = gallery.closest('.swiper-gallery').querySelector('.js-swiperGalleryButtonPrev')
    const pagination = gallery.closest('.swiper-gallery').querySelector('.js-swiperGalleryPagination')

    new Swiper(gallery, {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
        disabledClass: 'swiper-gallery__button-disabled',
      },
      pagination: {
        el: pagination,
        clickable: true,
        bulletClass: 'swiper-gallery__pagination-bullet',
        bulletActiveClass: 'swiper-gallery__pagination-bullet-active',
      },
      spaceBetween: 20,
      autoHeight: true,
      on: {
        init() {
          this.update()
          loadImages(this.slides[this.activeIndex])
          if (this.slides[this.activeIndex + 1]) {
            loadImages(this.slides[this.activeIndex + 1])
          }
        },
        slideChange() {
          loadImages(this.slides[this.activeIndex])
          if (this.slides[this.activeIndex + 1]) {
            loadImages(this.slides[this.activeIndex + 1])
          }
          if (this.slides[this.activeIndex - 1]) {
            loadImages(this.slides[this.activeIndex - 1])
          }
        }
      }
    })

    function loadImages(slide) {
      const images = slide.querySelectorAll('img[data-src], source[data-srcset]')
      images.forEach((img) => {
        if (img.tagName === 'IMG') {
          img.setAttribute('src', img.getAttribute('data-src'))
          img.removeAttribute('data-src')
        } else if (img.tagName === 'SOURCE') {
          img.setAttribute('srcset', img.getAttribute('data-srcset'))
          img.removeAttribute('data-srcset')
        }
      })
    }
  })
})
