import { ready } from '../../../js/utils/documentReady'
import * as d3 from 'd3'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  const diogramElement = document.querySelector('#chart')

  if(diogramElement) {
    const adaptiveConfig = {
      desktop: {
        width: 820 - 16,
        height: 820 - 16,
        radius: 160,
        expandedRadius: 1.1,
      },
      netbook: {
        width: 615 - 12,
        height: 615 - 12,
        radius: 120,
        expandedRadius: 1.1,
      },
      tablet: {
        width: 615 - 12,
        height: 615 - 12,
        radius: 120,
        expandedRadius: 1.06,
      },
      mobile: {
        width: 280 - 6,
        height: 280 - 6,
        radius: 55,
        expandedRadius: 1.06,
      },
    }

    const {isDesktop, isNetbook, isTablet} = getSize()
    let config

    if(isDesktop) {
      config = adaptiveConfig.desktop
    } else if(isNetbook) {
      config = adaptiveConfig.netbook
    } else if(isTablet) {
      config = adaptiveConfig.tablet
    } else {
      config = adaptiveConfig.mobile
    }

    const width = config.width
    const height = config.height
    const radius = Math.min(width, height) / 2
    const expandedRadius = radius * config.expandedRadius
    const titleElement = document.querySelector('.diogram__donut-center-title')
    const descriptionElement = document.querySelector('.diogram__donut-center-description')
    const legendItems = document.querySelectorAll('.diogram__legend-item')
    const progress = document.querySelector('.diogram__progress')

    const data = JSON.parse(diogramElement.dataset.params)

    const color = d3.scaleOrdinal() 
      .domain(data.map(d => d.label))
      .range(['rgba(1, 202, 242, 1)', 'rgba(0, 143, 232, 1)', 'rgba(0, 71, 255, 1)', 'rgba(112, 0, 255, 1)', 'rgba(255, 0, 122, 1)', 'rgba(204, 0, 255, 1)'])

    const arc = d3.arc()
      .innerRadius(radius - config.radius)
      .outerRadius(radius)

    const arcExpanded = d3.arc()
      .innerRadius(radius - config.radius)
      .outerRadius(expandedRadius)

    const pie = d3.pie()
      .value(d => d.value)
      .padAngle(0.015)
      .sort(null)

    const svg = d3.select('#chart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const arcs = svg.selectAll('.arc')
      .data(pie(data))
      .enter().append('g')
      .attr('class', 'arc')

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.label))
      .on('click', (event, d) => console.log('onclick', d.data))
      .on('mouseover', function() {
        const index = arcs.nodes().indexOf(this.parentNode)
        setActiveSlice(index)
        stopAutoSwitch()
      })
      .on('mouseout', function() {
        startAutoSwitch()
      })

    let activeSliceIndex = -1
    let autoSwitchInterval

    function setActiveSlice(index) {
      if (activeSliceIndex !== -1) {
        arcs.filter((d, i) => i === activeSliceIndex).select('path').transition().duration(200).attr('d', arc)
        legendItems[activeSliceIndex].classList.remove('diogram__legend-item_active')
        const images = document.querySelectorAll(`.diogram__image_group-${activeSliceIndex + 1}`)

        for(let i of images) {
          i.classList.add('diogram__image_disabled')
        }
      }

      activeSliceIndex = index
      legendItems[index].classList.add('diogram__legend-item_active')

      const images = document.querySelectorAll(`.diogram__image_group-${index + 1}`)

      for(let i of images) {
        i.classList.remove('diogram__image_disabled')
      }

      arcs.filter((d, i) => i === activeSliceIndex).select('path').transition().duration(200).attr('d', arcExpanded)
      const item = data[index]

      titleElement.innerHTML = `${item.value}%`
      descriptionElement.innerHTML = item.label
    }

    function switchToNextSlice() {
      progress.classList.add('diogram__progress_disabled')
      let nextIndex = (activeSliceIndex + 1) % data.length
      setActiveSlice(nextIndex)
      setTimeout(() => {
        progress.classList.remove('diogram__progress_disabled')
      }, 20)
    }

    function startAutoSwitch() {
      stopAutoSwitch()
      autoSwitchInterval = setInterval(switchToNextSlice, 4000)
      progress.classList.remove('diogram__progress_disabled')
    }

    function stopAutoSwitch() {
      progress.classList.add('diogram__progress_disabled')
      clearInterval(autoSwitchInterval)
    }

    legendItems.forEach((item, index) => {
      item.addEventListener('mouseover', () => {
        setActiveSlice(index)
        stopAutoSwitch()
      })

      item.addEventListener('mouseout', () => {
        startAutoSwitch()
      })
    })

    setActiveSlice(0)
    startAutoSwitch()
  }
})
