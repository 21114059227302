/* map */
import { ready } from '../../../js/utils/documentReady'
import { renderMap } from './scripts/render-map'
import {getAreasMapData} from '../areas-map/getAreasMapData'
import {getEventMapData} from '../event-map/getEventMapData'
import {renderPanel} from './scripts/render-map-content/render-panel'
import {renderContentPanel} from './scripts/render-map-content/render-content-panel'
import {marginsController} from './scripts/margins-controller'
import {filterAreasData} from './scripts/filter-data'

export let areasMapRef = null

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

ready(async () => {
  const mapComponent = document.querySelector('#js-map')
  const mapAreasComponent = document.querySelector('#js-map-areas')

  if (mapComponent) {
    const eventMapData = await getEventMapData()
    const { config, data } = eventMapData

    const ref = {
      map: null,
      data: data,
      config: config,
      mapElement: mapComponent,
    }

    ref.marginController = marginsController({ ref: ref })

    const renderMapComponent = async () => {
      const script = mapComponent.getAttribute('data-script')
      await loadScript(script)
      mapComponent.innerHTML = ` ${renderContentPanel({ ref })}
        ${renderMap({ ref, type: 'mapComponent' })}
      ${renderPanel({ ref })}`
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px 0px 75px 0px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          renderMapComponent().then(() => {
            observer.unobserve(entry.target)
          })
        }
      })
    }, observerOptions)

    observer.observe(mapComponent)
  }

  if (mapAreasComponent) {
    const areasMapData = await getAreasMapData()
    const { config, data } = areasMapData

    const ref = {
      map: null,
      data: data,
      config: config,
      mapElement: mapAreasComponent,
    }

    ref.marginController = marginsController({ ref: ref })

    areasMapRef = ref

    const renderMapComponent = async () => {
      const script = mapAreasComponent.getAttribute('data-script')
      await loadScript(script)

      const currentUrl = window.location.href
      const urlObj = new URL(currentUrl)
      const typeParam = urlObj.searchParams.get('type')

      if(typeParam) {
        ref.defaultData = filterAreasData(ref.data, typeParam)
      }

      mapAreasComponent.innerHTML = `
      ${renderContentPanel({ ref })}
        ${renderMap({ ref, type: 'mapAreasComponent' })}
      ${renderPanel({ ref })}`

    }

    const observerOptions = {
      root: null,
      rootMargin: '0px 0px 75px 0px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          renderMapComponent().then(() => {
            observer.unobserve(entry.target)
          })
        }
      })
    }, observerOptions)

    observer.observe(mapAreasComponent)
  }
})

