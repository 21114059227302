import Swiper from 'swiper'
import { ready } from '../../../js/utils/documentReady'
import { Autoplay } from 'swiper/modules'
import {executeOnScroll} from '../../../js/utils/executeOnScroll'

ready(() => {
  const swiperElement = document.querySelector('.js-swiperBanner')
  if (swiperElement) {
    const initSwiper = () => {
      new Swiper('.js-swiperBanner', {
        modules: [ Autoplay ],
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
      })
    }
    executeOnScroll(swiperElement, initSwiper)
  }
})
