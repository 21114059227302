import Hls from 'hls.js'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const preloader = document.querySelector('.modal-loader')
  
  function visiblePreloader() {
    if (preloader) {
      preloader.classList.add('visible')
    }
  }

  function hiddenPreloader() {
    if (preloader) {
      preloader.classList.remove('visible')
      preloader.classList.add('hide')
    }
  }

  if (preloader) {
    console.log(visiblePreloader)
    console.log(hiddenPreloader)
  }
  
  const videoPageInModal = () => {
    const videoContainers = document.querySelectorAll('.js-video-in-modal')
    if (videoContainers) {
      videoContainers.forEach((videoContainer) => {
        if (videoContainer) {
          const video = videoContainer.querySelector('video')
          const videoSrc = video.getAttribute('data-src')

          if (videoSrc && Hls.isSupported()) {
            const hls = new Hls()

            setTimeout(() => {
              hls.loadSource(videoSrc)
              hls.attachMedia(video)
            }, 3600)
          } else {
            setTimeout(() => {
              videoSrc && (video.src = videoSrc)
            }, 3600)
          }
        }
      })
    }
  }
  function checkImgSizes() {
    const images = document.querySelectorAll('.img-in-nodal')

    images.forEach((image) => {
      const img = image.querySelector('img')
      if (img) {
        const elWidth = image.offsetWidth
        const imgWidth = img.naturalWidth

        if (imgWidth < elWidth) {
          img.style.objectFit = 'none'
          img.style.objectPosition = 'left'
          img.style.width = 'auto'
        } else  {
          img.style.objectFit = 'cover'
          img.style.width = '100%'
          img.style.objectPosition = 'center'
        }
      }
    })
  }
  
  window.initImagePageInModal = () => checkImgSizes()
  window.initVideoPageInModal = () => videoPageInModal()
})
