import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'
import { createControlPoints } from './utils/createControlPoints'
import { fromProgressToScrollbar } from './utils/fromProgressToScrollbar'
import { fromScrollbarToProgress } from './utils/fromScrollbarToProgress'

ready(() => {
  const timelineElement = document.querySelector('.timeline3d')
  const { isMobile, isTablet } = getSize()
  const isMainPage = timelineElement?.getAttribute('data-is-main')
  if(timelineElement && !isMobile && !isTablet) {
    setTimeout(() => {
      window.timeline.forEach((el) => {
        const cards = el.cards
        const descriptions = el.descriptions
  
        if (cards && cards.length > 0) {
          const lastCards = cards[cards.length - 1]
          const newPosition = +lastCards.position + 8
          
          const newDescription = {
            offsetX: 0,
            position: newPosition,
            title: 'Москва <span>2030</span>',
            type: 'description',
            'last': true
          }

          if (descriptions && descriptions.length > 0) descriptions.push(newDescription)
        }
      })
    })

    const cardLast = document.createElement('div')
    cardLast.className = ['timeline3d-card', 'timeline3d-card_last']

    function getIsSafari() {
      const ua = navigator.userAgent
      return ua.includes('Safari') &&
        !ua.includes('Chrome') &&
        !ua.includes('Chromium') &&
        !ua.includes('Android')
    }

    const step = 416.65
    const timelineData = window.timeline
    const showMoreButton = window.showMoreStrategy

    timelineElement.innerHTML = `
      <div class="timeline3d-preview">
        ${!isMainPage ? '<div class="timeline3d-preview__title MOSCOW2024">Выберите направление</div>' : ''}
        <div class="timeline3d-preview__cards-wrapper">
          <div class="timeline3d-preview__cards">
            <div class="timeline3d-preview__cards-inner ${timelineData.length === 9 ? 'has-nine-items' : ''}">
              ${timelineData.map((item) => `
                <div class="timeline3d-preview__card">
                  <div class="timeline3d-preview__card-image">
                    <img src="${item.image}"/>
                  </div>
                  <div class="timeline3d-preview__card-description">${item.name}</div>
                </div>
              `).join('')}
            </div>
          </div>
        </div>
        ${isMainPage ? `<div class="timeline3d-preview__btn"><a class="button button-theme_blue-dark-with-light text-18 font-600 lh-22 text-tab-16 lh-tab-20 text-mob-14" href=${showMoreButton.link}><span class="button__text">${showMoreButton.name}</span></a></div>` : ''}
      </div>
      <div class="container timeline3d__reduce-hitbox">
        <div class="timeline3d__tabs"></div>
        <div class="timeline3d__tabs-description-wrapper">
          <div class="timeline3d__tabs-description"></div>
        </div>
      </div>
      <div class="timeline3d__timeline">
        <div class="timeline3d__floor"></div>
        <div class="timeline3d__timeline-cards"></div>
      </div>
      <div class="timeline3d__scrollbar">
        <div class="timeline3d__scrollbar-shadow"></div>
        <div class="timeline3d__scrollbar-inner">
          <div class="timeline3d__thumb"></div>
        </div>
        <div class="timeline3d__description">
          <div class="timeline3d__description-years">
           </div>
          <div class="timeline3d__description-hitbox"></div>
        </div>
      </div>
    `

    for(let i of timelineData) {
      for(let k of i.cards) {
        k.position -= 4
        k.type = 'card'
      }
      for(let j of i.descriptions) {
        j.position -= 4
        j.type = 'description'
      }
    }

    let timelineTab
    let cardsData
    let maxPosition
    let scrollPosition
    let maxScroll
    let scrollFactor
    let maxSpeed
    let accelerationFactor
    let controlPoints
    let isFirstChange = true
    let isPreventNegativeThumbValues = false
    let originalUrl = window.location.href.split('?')[0].split('#')[0]

    if (originalUrl.endsWith('/')) {
      originalUrl = originalUrl.slice(0, -1)
    }

    let framesToReachMaxSpeed = 30

    if(window.timelineActualUrl) {
      if (originalUrl.endsWith('/' + window.timelineActualUrl)) {
        originalUrl = originalUrl.slice(0, -window.timelineActualUrl.length - 1)
      }
    }

    const defaultActiveIndex = timelineData.findIndex(item => item.url === window.timelineActualUrl)
    const offsetYMin = -105
    const offsetYMax = 405

    let prevCards = []
    let prevCardsData = []
    let cards = []
    const cardsElement = timelineElement.querySelector('.timeline3d__timeline-cards')
    const timelineHitBox = timelineElement.querySelector('.timeline3d__timeline')
    const tabs = timelineElement.querySelector('.timeline3d__tabs')
    const scrollbarInnerElement = timelineElement.querySelector('.timeline3d__scrollbar-inner')
    const thumbElement = scrollbarInnerElement.querySelector('.timeline3d__thumb')
    const scrollbarDescription = timelineElement.querySelector('.timeline3d__description')
    const scrollbarDescriptionYears = timelineElement.querySelector('.timeline3d__description-years')
    const scrollbarDescriptionHitBox = timelineElement.querySelector('.timeline3d__description-hitbox')
    const tabDescription = timelineElement.querySelector('.timeline3d__tabs-description')
    const previewCards = timelineElement.querySelectorAll('.timeline3d-preview__card')
    const previewCardsWrapper = timelineElement.querySelector('.timeline3d-preview__cards')
    let tabsElements
    let forceAnimate = false
    let activeIndex
    const isSafari = getIsSafari()

    setTimeout(() => {
      timelineHitBox.classList.add('timeline3d__timeline_init')
    }, 1)

    if(isSafari) {
      timelineElement.classList.add('timeline3d_ios')
    }

    function getOS() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('win') !== -1) return 'windows'
      if (userAgent.indexOf('mac') !== -1) return 'mac'
      if (userAgent.indexOf('linux') !== -1) return 'linux'
      return 'unknown'
    }

    const os = getOS()

    if (os === 'mac') {
      timelineElement.classList.add('timeline3d_bg-gradient-mac')
    } else {
      timelineElement.classList.add('timeline3d_bg-gradient')
    }

    document.addEventListener('DOMContentLoaded', function () {
      const hash = window.location.hash
      const target = document.querySelector(hash)
      const header = document.querySelector('.header')
      if (target) {
        setTimeout(() => {
          const headerOffset = header.offsetHeight
          const elementPosition = target.getBoundingClientRect().top + window.scrollY
          window.scrollTo({ top: elementPosition - headerOffset, behavior: 'smooth' })
        }, 500)
      }
    })

    const renderYears = (years) => {
      const generateYearBlock = (year) => {
        const generateHrLines = (count) => {
          return Array(count).fill('<hr>').join('')
        }

        return `
          <div class="timeline3d__description-year-block">
            <div class="timeline3d__description-year-title">${year}</div>
            <div class="timeline3d__description-year-block-lines">
              ${generateHrLines(12)}
            </div>
          </div>`

      }

      const startYear = Math.min(...years)

      scrollbarDescriptionYears.innerHTML = `
         ${years.map(year => generateYearBlock(year)).join('')}
         <div class="timeline3d__description-decor-left">
           ${generateYearBlock(startYear - 2)}
           ${generateYearBlock(startYear - 1)}
           ${generateYearBlock(startYear - 1)}
        </div>
         <div class="timeline3d__description-decor-right">
           ${generateYearBlock(2032)}
           ${generateYearBlock(2033)}
        </div>
      `

      setTimeout(() => {
        const yearBlocks = scrollbarDescriptionYears.querySelectorAll(':scope > .timeline3d__description-year-block')

        const lastYearBlock = yearBlocks[yearBlocks.length - 1]

        scrollbarDescriptionYears.style.setProperty('--width-year-block', `${lastYearBlock.clientWidth}px`)
      }, 1)
    }

    renderYears([2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031])

    const renderTabs = () => {
      tabs.innerHTML = `
        ${timelineData.map((tabData, index) => `
          <div class="timeline3d__tabs-item" data-index="${index}">${tabData.name}</div>
        `).join('')}
      `

      tabsElements = tabs.querySelectorAll('.timeline3d__tabs-item')

      for(let tabElement of tabsElements) {
        tabElement.addEventListener('click', () => {
          const index = tabElement.dataset.index
          if(!tabElement.classList.contains('timeline3d__tabs-item_active')) {
            setActiveTab(index)
          }
        })
      }
    }

    renderTabs()

    const renderCards = () => {
      prevCards = []
      cards.forEach((card)=> {
        if (card.style.display === 'none') {
          card.remove()
        } else {
          const cardIndex = card.getAttribute('data-index')
          prevCards.push(structuredClone(prevCardsData[cardIndex]))
          card.setAttribute('data-index', prevCards.length + cardsData.length - 1)
          card.style.zIndex -= 1000
        }
      })

      cardsData.forEach((cardData, index) => {
        const card = document.createElement('div')
        const whichCursor = cardData.open_popup ? 'pointer' : 'unset'
        card.className = 'timeline3d-card'
        card.dataset.index = index
        card.style.zIndex = -cardData.position
        cardsElement.appendChild(card)

        if (cardData.last) card.classList.add('timeline3d-card_last')

        if(cardData.type === 'card') {
          card.classList.add('timeline3d-card_card')
          card.innerHTML = `
          <div class="timeline3d-card__inner">
            <div class="timeline3d-card__inner-2" style="background-color: ${cardData.bg}; cursor: ${whichCursor}" onclick="${cardData.open_popup} ? window.openTimelinePopup('${cardData.id}') : '' ">
              ${cardData.image ? `<div class="timeline3d-card__image"><img src="${cardData.image}"/></div>` : ''}
              <div class="timeline3d-card__description">${cardData.description}</div>
              ${cardData.open_popup ? '<div class="timeline3d-card__button">Подробнее</div>' : ''}
            </div>
          </div>
        `
        } else {
          card.innerHTML = `
          <div class="timeline3d-card__inner">
            <div class="timeline3d-card__inner-2">
              <div class="timeline3d-card__title MOSCOW2024">${cardData.title}</div>
            </div>
          </div>
        `
        }
      })

      if(prevCards.length) {
        const currentPosition = Math.ceil(scrollPosition / step)

        prevCards.forEach((prevCard) => {
          prevCard.position = prevCard.position - currentPosition - 20
        })
      }
      cardsData = [...cardsData, ...prevCards]
      cards = document.querySelectorAll('.timeline3d-card')
    }

    let videoClickListener

    const renderTabDescription = () => {
      const existingVideoElement = tabDescription.querySelector('.timeline3d__tabs-description-video')
      if (existingVideoElement && videoClickListener) {
        existingVideoElement.removeEventListener('click', videoClickListener)
      }

      tabDescription.innerHTML = `
        ${timelineTab.videoIframe ? '<div class="timeline3d__tabs-description-video">Смотреть видео</div>' : ''}
      `
      const videoElement = tabDescription.querySelector('.timeline3d__tabs-description-video')

      if (videoElement) {
        videoClickListener = () => {
          window.openVideoPopup(timelineTab.videoIframe, timelineTab.videoTitle, timelineTab.isVertical)
        }
        videoElement.addEventListener('click', videoClickListener)
      }
    }

    const setActiveTab = (index, isSkipAnimation) => {
      if(tabsElements) {
        if(activeIndex || activeIndex === 0) {
          tabsElements[activeIndex].classList.remove('timeline3d__tabs-item_active')
        }
        activeIndex = index
        timelineTab = timelineData[activeIndex]

        if (timelineTab && timelineTab.url) {
          const urlObject = new URL(window.location.href)
          const query = urlObject.search || ''
          const newPath = `${originalUrl}/${timelineTab.url}/${query}`
          window.history.pushState({}, '', newPath)
        }

        const gapScrollPosition = scrollPosition % step

        tabsElements[index].classList.add('timeline3d__tabs-item_active')
        prevCardsData = cardsData
        cardsData = [...timelineTab.cards, ...timelineTab.descriptions]
        targetProgress = 0
        renderCards()
        renderTabDescription()
        scrollPosition = 0

        maxPosition = cardsData.reduce((max, card) => {
          return card.position > max ? card.position : max
        }, 0)

        maxPosition -= 4

        const startPosition = -4

        const filteredDescriptions = timelineTab.descriptions
          .filter(item => {
            return [
              '2010','2011','2012','2013','2014','2015','2016','2017',
              '2018','2019','2020','2021','2022','2023','2024','2025',
              '2026','2027','2028','2029','2030'
            ].includes(item.title)
          })
          .sort((a, b) => Number(a.title) - Number(b.title))

        const years = filteredDescriptions.map(item => +item.title)

        const startYear = Math.min(...years)

        const yearsWithoutStartYear = years.filter(year => year !== startYear)

        renderYears([startYear, ...yearsWithoutStartYear, 2031])

        controlPoints = createControlPoints(filteredDescriptions, startPosition, maxPosition)

        maxScroll = maxPosition * step + step * 4
        const scrollWidth = scrollbarDescriptionHitBox.clientWidth
        const kSpeed = scrollWidth / maxPosition  / 5

        maxSpeed = 0.003 * kSpeed
        accelerationFactor = 0.031
        scrollFactor = 0.00007 * kSpeed

        if(isFirstChange) {
          isFirstChange = false
          if(isSkipAnimation) {
            realProgress = 0
            targetProgress = realProgress
            framesToReachMaxSpeed = 30
          } else {
            const position = -16 * step
            updateFloor(position)
            realProgress = position / maxScroll
            framesToReachMaxSpeed = 100
            setTimeout(() => {
              framesToReachMaxSpeed = 30
            }, 300)
          }
        } else {
          isPreventNegativeThumbValues = true
          timelineElement.classList.add('timeline3d__animation')
          const steps = gapScrollPosition > 0 ? - 21 : -20
          const position = steps * step + gapScrollPosition
          realProgress = position / maxScroll

          framesToReachMaxSpeed = 100
          setTimeout(() => {
            framesToReachMaxSpeed = 30
            timelineElement.classList.remove('timeline3d__animation')
          }, 1000)
        }

        forceAnimate = true
        if(!isAnimated) {
          isAnimated = true
          requestAnimationFrame(animate)
        }
      }
    }

    window.setActiveTab = setActiveTab

    function getYPosition(progress, minY, maxY) {
      const scale = 1 - progress
      const y = minY + (maxY - minY) * Math.pow(scale, 2)
      return y
    }

    function createFloor(floorElementId, numHorizontalLines) {
      const floor = document.getElementsByClassName(floorElementId)[0]

      if (!floor) {
        return null
      }

      const floorRect = floor.getBoundingClientRect()
      const floorHeight = floorRect.height

      const horizontalLines = []

      function createHorizontalLine(depth) {
        const line = document.createElement('div')
        line.className = 'timeline3d__line'
        floor.appendChild(line)
        return { element: line, depth: depth }
      }

      for (let i = 0; i < numHorizontalLines; i++) {
        const depth = i / numHorizontalLines
        const line = createHorizontalLine(depth)
        horizontalLines.push(line)
      }

      let totalProgress = 0
      let lastScrollFraction = 0

      function updateHorizontalLines(scrollFraction) {
        const speedFactor = 0.0002
        const deltaScroll = -scrollFraction - lastScrollFraction
        lastScrollFraction = -scrollFraction

        const progressIncrement = deltaScroll * speedFactor
        totalProgress = ((totalProgress || 0) + progressIncrement + 1) % 1

        for (let i = 0; i < horizontalLines.length; i++) {
          const line = horizontalLines[i]
          const lineProgress = (totalProgress + i / numHorizontalLines) % 1
          const y = getYPosition(lineProgress, 0, floorHeight)

          line.element.style.top = y + 'px'

          if (lineProgress >= 0.8) {
            line.element.style.opacity = 0
          } else {
            line.element.style.opacity = ((1 - lineProgress) - 0.2) / (1 - 0.2)
          }
        }
      }

      return updateHorizontalLines
    }

    const updateFloor = createFloor('timeline3d__floor', 12)

    updateFloor(0)

    function updateScene() {
      updateFloor(scrollPosition)

      cards.forEach((card) => {
        const index = card.dataset.index
        const cardData = cardsData[index]
        const depth = ((scrollPosition - 120) * 1.9 - cardData.position * 500 * 1.584) / 5
        if (depth < -1000 || depth > 1400) {
          card.style.display = 'none'
        } else {
          card.style.display = 'block'
          const progress = ((depth + 1000) / 2000) * 100
          const offsetY = getYPosition(1 - progress / 100, offsetYMin, offsetYMax)
          const progressY = 100 - 100 * (1 - (offsetY - offsetYMin) / (offsetYMax - offsetYMin))
          const minScale = 0.2
          const maxScale = 1.3
          const scale = minScale + (maxScale - minScale) * (progressY / 100)
          const offsetXKf = 0.05 + (progressY / 100) * (1 - 0.05)

          card.style.transform = `translate(${cardData.offsetX * offsetXKf * 12}px, ${offsetY}px)`
          card.style.setProperty('--scale', scale)

          let opacity = 1
          if (depth >= -1000 && depth <= -100) {
            if(isSafari) {
              opacity = 1
            } else {
              opacity = (depth + 1000) / 900
            }
          } else if (depth >= 1000 && depth <= 1400) {
            if(isSafari) {
              opacity = 0
            } else {
              opacity = 1 - (depth - 1000) / 400
            }
          } else if (depth < -1000 || depth > 1400) {
            opacity = 0
          }

          card.style.opacity = opacity
        }
      })
    }

    updateScene()

    let targetProgress = 0
    let realProgress

    let prevRealProgress = 0
    let isAnimated = false

    function updateScrollbarDescription() {
      const innerWidth = timelineElement.offsetWidth
      const descriptionWidth = scrollbarDescription.offsetWidth

      const maxOffset = descriptionWidth - innerWidth

      const translateX = -maxOffset * fromScrollbarToProgress(realProgress, controlPoints, false)

      scrollbarDescription.style.transform = `translateX(${translateX}px)`
    }

    let prevSpeed = 0

    function animate() {
      let distance = targetProgress - realProgress
      let speed = distance * accelerationFactor
      const speedChangePerFrame = maxSpeed / framesToReachMaxSpeed
      const speedChange = prevSpeed += speedChangePerFrame

      speed = Math.max(-speedChange, Math.min(speedChange, speed))
      speed = Math.max(-maxSpeed, Math.min(maxSpeed, speed))
      prevSpeed = Math.abs(speed)
      realProgress += speed
      realProgress = Math.min(1, realProgress)
      realProgress = parseFloat(realProgress.toFixed(4))
      updateScrollbar()

      if(realProgress !== prevRealProgress || forceAnimate) {
        scrollPosition = realProgress * maxScroll
        updateScene()
        updateScrollbarDescription()
        requestAnimationFrame(animate)
        isAnimated = true

        if(forceAnimate) {
          forceAnimate = false
        }
      } else {
        isAnimated = false
      }
      prevRealProgress = realProgress
    }

    function updateScrollbar() {
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      let thumbPosition
      if(!isPreventNegativeThumbValues) {
        thumbPosition = fromScrollbarToProgress(realProgress, controlPoints, true) * maxThumbPosition
      } else {
        thumbPosition = Math.max(0, fromScrollbarToProgress(realProgress, controlPoints, true) * maxThumbPosition)
      }
      thumbElement.style.transform = `translateX(${thumbPosition}px)`
    }

    scrollbarDescriptionHitBox.addEventListener('click', function(e) {
      const rect = scrollbarDescriptionHitBox.getBoundingClientRect()
      const clickX = e.clientX - rect.left
      const scrollbarWidth = scrollbarDescriptionHitBox.clientWidth
      targetProgress = fromProgressToScrollbar(Math.max(0, Math.min(1, clickX / scrollbarWidth)), controlPoints)

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    })

    timelineHitBox.addEventListener('wheel', function(e) {
      e.preventDefault()
      const delta = e.deltaY || -e.wheelDelta
      targetProgress += delta * scrollFactor
      targetProgress = Math.max(0, Math.min(1, targetProgress))

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    })

    let isDragging = false
    let dragStartX = 0
    let thumbStartX = 0

    thumbElement.addEventListener('mousedown', function(e) {
      e.preventDefault()
      isDragging = true
      scrollbarInnerElement.classList.add('timeline3d__scrollbar-inner_drag')
      dragStartX = e.clientX
      const rect = thumbElement.getBoundingClientRect()
      thumbStartX = rect.left - scrollbarInnerElement.getBoundingClientRect().left
      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', onDragEnd)
    })

    function onDrag(e) {
      if (!isDragging) return
      const deltaX = e.clientX - dragStartX
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      let newThumbPosition = thumbStartX + deltaX
      newThumbPosition = Math.max(0, Math.min(maxThumbPosition, newThumbPosition))
      targetProgress = fromProgressToScrollbar(newThumbPosition / maxThumbPosition, controlPoints)

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    }

    function onDragEnd() {
      scrollbarInnerElement.classList.remove('timeline3d__scrollbar-inner_drag')
      isDragging = false
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', onDragEnd)
    }

    thumbElement.addEventListener('onclick', (e) => {
      e.stopPropagation()
    })

    thumbElement.addEventListener('touchstart', function(e) {
      scrollbarInnerElement.classList.add('timeline3d__scrollbar-inner_drag')
      isDragging = true
      dragStartX = e.touches[0].clientX
      const rect = thumbElement.getBoundingClientRect()
      thumbStartX = rect.left - scrollbarInnerElement.getBoundingClientRect().left
      document.addEventListener('touchmove', onTouchMove)
      document.addEventListener('touchend', onTouchEnd)
    })

    function onTouchMove(e) {
      if (!isDragging) return
      e.stopPropagation()
      const deltaX = e.touches[0].clientX - dragStartX
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      let newThumbPosition = thumbStartX + deltaX
      newThumbPosition = Math.max(0, Math.min(maxThumbPosition, newThumbPosition))
      targetProgress = fromProgressToScrollbar(newThumbPosition / maxThumbPosition, controlPoints)

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    }

    function onTouchEnd() {
      scrollbarInnerElement.classList.remove('timeline3d_scrollbar-inner_drag')
      isDragging = false
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', onTouchEnd)
    }

    if(defaultActiveIndex !== -1) {
      setTimeout(() => {
        timelineElement.classList.add('timeline3d_prevent-preview-animation')
        stopAnimation()

        const observer = new IntersectionObserver((entries, observerInstance) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
              setActiveTab(defaultActiveIndex)
              timelineElement.classList.remove('timeline3d_preview')

              observerInstance.unobserve(timelineElement)
            }
          })
        }, {
          threshold: [0.3]
        })

        observer.observe(timelineElement)
      }, 10)
    }

    function createPreviewAnimation(timelineElement) {
      const previewCards = timelineElement.querySelectorAll('.timeline3d-preview__card')

      let currentCursorPosition = { x: 0, y: 0 }

      const interpolationSpeed = 0.04
      let animationFrameId
      let isAnimation = false
      let isCursorOutside = true

      const previousTransforms = []
      const numCards = previewCards.length

      const currentRotateX = Array(numCards).fill(0)
      const currentRotateY = Array(numCards).fill(0)
      const currentScale = Array(numCards).fill(1)

      const targetRotateX = Array(numCards).fill(0)
      const targetRotateY = Array(numCards).fill(0)
      const targetScale = Array(numCards).fill(1)

      function updateCards() {
        let hasChanged = false

        previewCards.forEach((card, index) => {
          if (isCursorOutside) {
            targetRotateX[index] = 0
            targetRotateY[index] = 0
            targetScale[index] = 1
          } else {
            const cardRect = card.getBoundingClientRect()
            const cardCenterX = cardRect.left + cardRect.width / 2
            const cardCenterY = cardRect.top + cardRect.height / 2

            const deltaX = currentCursorPosition.x - cardCenterX
            const deltaY = currentCursorPosition.y - cardCenterY

            let rotateY = deltaX / 12
            let rotateX = -deltaY / 16
            const maxRotateY = 60
            const maxRotateX = 18

            rotateY = maxRotateY * Math.tanh(rotateY / maxRotateY)
            rotateX = maxRotateX * Math.tanh(rotateX / maxRotateX)

            targetRotateX[index] = rotateX
            targetRotateY[index] = rotateY
            targetScale[index] = 1.2
          }

          currentRotateX[index] += (targetRotateX[index] - currentRotateX[index]) * interpolationSpeed
          currentRotateY[index] += (targetRotateY[index] - currentRotateY[index]) * interpolationSpeed
          currentScale[index] += (targetScale[index] - currentScale[index]) * interpolationSpeed

          const newTransform = `rotateY(${currentRotateY[index].toFixed(1)}deg) rotateX(${currentRotateX[index].toFixed(1)}deg) translate3d(0, 0, -200px) scale(${currentScale[index].toFixed(3)})`

          if (previousTransforms[index] !== newTransform) {
            hasChanged = true
            previousTransforms[index] = newTransform
            card.style.transform = newTransform
          }
        })

        if (hasChanged) {
          animationFrameId = requestAnimationFrame(updateCards)
        } else {
          isAnimation = false
        }
      }

      updateCards()

      function onMouseMove(event) {
        currentCursorPosition.x = event.clientX
        currentCursorPosition.y = event.clientY
        isCursorOutside = false

        if (!isAnimation) {
          isAnimation = true
          updateCards()
        }
      }

      function onMouseLeave() {
        isCursorOutside = true

        if (!isAnimation) {
          isAnimation = true
          updateCards()
        }
      }

      timelineElement.addEventListener('mousemove', onMouseMove)
      timelineElement.addEventListener('mouseleave', onMouseLeave)

      function stopAnimation() {
        cancelAnimationFrame(animationFrameId)
        timelineElement.removeEventListener('mousemove', onMouseMove)
        timelineElement.removeEventListener('mouseleave', onMouseLeave)
        previewCards.forEach((card, index) => {
          currentRotateX[index] = 0
          currentRotateY[index] = 0
          currentScale[index] = 1
        })
        isAnimation = false
      }

      return { stopAnimation }
    }

    const { stopAnimation } = createPreviewAnimation(timelineElement)

    previewCards.forEach((previewCard, index) => {
      previewCard.addEventListener('mouseenter', () => {
        previewCardsWrapper.classList.add('timeline3d-preview__cards_hover')
      })

      previewCard.addEventListener('mouseleave', () => {
        previewCardsWrapper.classList.remove('timeline3d-preview__cards_hover')
      })

      previewCard.addEventListener('click', () => {
        if (window.isRedirectOn3dTimeline) {
          timelineTab = timelineData[index]
          if (timelineTab && timelineTab.link) {
            window.location.href = `${timelineTab.link}#strategy`
          }
        } else {
          timelineElement.classList.remove('timeline3d_preview')
          stopAnimation()
          setActiveTab(index)
        }
      })
    })
  }
})
