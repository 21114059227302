import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

ready(() => {
  const switchButtons = document.querySelectorAll('.js-radioSwitchButton')
  const { isMobile } = getSize()

  if (switchButtons && isMobile) {
    switchButtons.forEach(button => {
      const valueInput = button.querySelector('input').getAttribute('value')

      if (valueInput === 'excursions') {
        button.addEventListener('click', () => {
          const excursionsField = document.querySelector('.js-excursionsFieldModal')
          excursionsField.classList.add('events-page__excursions-field-modal_show')
          document.body.style.overflow = 'hidden'
        })
      }
    })
  }
})
