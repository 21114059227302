import { ready } from '../../../js/utils/documentReady'
import Swiper from 'swiper'
import {Mousewheel, Navigation, Pagination} from 'swiper/modules'

ready(() => {
  const swiperEl = document.querySelector('.js-swiperFullPage')
  const tabs = document.querySelector('.article-skills__intro')?.querySelectorAll('.tag-skills__text-wrapper')

  if (swiperEl) {
    let swiper
    const initSwiperPage = () => {
      if (window.matchMedia('(min-width: 1279px)').matches) {
        if (!swiper) {
          let isLock = false
          const swiperWrapper = document.querySelector('.swiper-full-page')

          const swiper = new Swiper(swiperEl, {
            modules: [Navigation, Pagination, Mousewheel],
            direction: 'vertical',
            slidesPerView: 1,
            spaceBetween: 0,
            mousewheel: true,
            speed: 1000,
            touchReleaseOnEdges: true,
            navigation: {
              prevEl: '.js-swiperPageButtonPrev',
              nextEl: '.js-swiperPageButtonNext',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          })

          swiperWrapper.classList.add('swiper-full-page_disabled')

          window.addEventListener('scroll', onScroll)

          const lockPageScroll = () => {
            isLock = true
            document.body.style.overflow = 'hidden'
            document.querySelector('.header').classList.add('is-force-hide-up')
            swiperWrapper.classList.remove('swiper-full-page_disabled')
          }

          const unlockPageScroll = (slidePosition) => {
            isLock = false
            document.body.style.overflow = 'auto'
            setTimeout(() => {
              document.querySelector('.header').classList.remove('is-force-hide-up')
              document.querySelector('.header').classList.add('is-hide-up')
            }, 100)
            swiperWrapper.classList.add('swiper-full-page_disabled')

            if (slidePosition === 'start') {
              const rect = swiperWrapper.getBoundingClientRect()

              const topPosition = rect.top + window.scrollY
              window.scrollTo({
                top: topPosition,
              })

            } else if (slidePosition === 'end') {
              const rect = swiperWrapper.getBoundingClientRect()

              const scrollOffset = rect.bottom - window.innerHeight

              window.scrollTo({
                top: window.scrollY + scrollOffset,
              })
            }
          }

          function isElementFullyVisible(el) {
            const rect = el.getBoundingClientRect()
            return rect.top < -10 && (rect.bottom - window.innerHeight) > 10
          }

          function onScroll() {
            if (isElementFullyVisible(swiperWrapper)) {
              if (!isLock) {
                lockPageScroll()
              }
            }
          }

          swiper.on('transitionEnd', checkSlidesPosition)

          function checkSlidesPosition() {
            const firstSlide = swiper.slides[0]
            const lastSlide = swiper.slides[swiper.slides.length - 1]

            if (!firstSlide || !lastSlide) return

            const containerRect = swiperEl.getBoundingClientRect()

            const firstSlideRect = firstSlide.getBoundingClientRect()
            const lastSlideRect = lastSlide.getBoundingClientRect()

            if (Math.abs(firstSlideRect.top - containerRect.top) <= 1) {
              unlockPageScroll('start')
            }

            if (swiper.isEnd && Math.abs(lastSlideRect.bottom - containerRect.bottom) <= 1) {
              unlockPageScroll('end')
            }
          }

          swiperEl.addEventListener('wheel', (e) => {
            if (!isLock) return

            if (e.deltaY < 0 && swiper.isBeginning) {
              checkSlidesPosition()
            } else if (e.deltaY > 0 && swiper.isEnd) {
              checkSlidesPosition()
            }
          })

          let touchStartY = 0
          let touchEndY = 0

          swiperEl.addEventListener('touchstart', (e) => {
            touchStartY = e.touches[0].clientY
          })

          swiperEl.addEventListener('touchmove', (e) => {
            touchEndY = e.touches[0].clientY
            const deltaY = touchStartY - touchEndY
            if (!isLock) return

            if (deltaY < 0 && swiper.isBeginning) {
              checkSlidesPosition()
            } else if (deltaY > 0 && swiper.isEnd) {
              checkSlidesPosition()
            }
          })

          swiper.on('slideChangeTransitionEnd', () => {
            if (swiper.isBeginning) {
              unlockPageScroll('start')
            } else if (swiper.isEnd) {
              unlockPageScroll('end')
            }
          })

          if (tabs) {
            tabs.forEach(tab => {
              tab.addEventListener('click', () => {
                const slideIndex = tab.dataset.slideIndex
                swiper.slideTo(slideIndex)
                const yPos = swiperEl.getBoundingClientRect().top + window.pageYOffset
                window.scrollTo({ top: yPos, behavior: 'smooth' })
                lockPageScroll()
              })
            })
          }
          
        }
      } else {
        if (swiper) {
          swiper.destroy()
          document.body.style.overflow = 'auto'
          swiper = null
        }
        if (tabs) {
          function scrollToSkill(id) {
            console.log(id, 'id')
            const targetElement = document.getElementById(id)
            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              })
            } else {
              console.error(`Element with id "${id}" not found.`)
            }
          }

          tabs.forEach(tab => {
            tab.addEventListener('click', () => {
              const slideIndex = tab.dataset.slideIndex
              scrollToSkill(`slide_${slideIndex}`)
            })
          })
        }
      }
    }

    initSwiperPage()
    window.addEventListener('resize', initSwiperPage)
  }
})
