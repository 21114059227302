import { ready } from '../../../js/utils/documentReady'
import { executeOnScroll } from '../../../js/utils/executeOnScroll'

ready(() => {
  let previousWidth = window.innerWidth

  const createKeyframes = (index, widthTickerBlock, gapValue) => {
    const slash = window.innerWidth < 1100 ? 1.55 : 1
    return `@keyframes tickerAnimation${index} {
        0% { margin-left: -1px; }
        100% { margin-left: -${widthTickerBlock/slash  + gapValue}px; }
      }`
  }

  function applyAnimationStyle(index, keyframes) {
    let styleElement = document.getElementById(`ticker-style-${index}`)
    if (!styleElement) {
      styleElement = document.createElement('style')
      styleElement.id = `ticker-style-${index}`
      document.head.appendChild(styleElement)
    }

    const styleSheet = styleElement.sheet
    while (styleSheet.cssRules.length > 0) {
      styleSheet.deleteRule(0)
    }
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length)
  }

  function createTickerAnimation(ticker, index) {
    const tickerBlock = ticker.querySelector('.ticker__block')
    const { clientWidth: widthTickerBlock } = tickerBlock
    const gapValue = parseFloat(getComputedStyle(tickerBlock).gap || 0)
    let speed = ticker.dataset.speed || 200
    if(window.innerWidth < 768) {
      speed /=1.7
    }

    const direction = ticker.dataset.direct || 'left'
    const timeDuration = widthTickerBlock / speed

    const keyframes = createKeyframes(index, widthTickerBlock, gapValue)
    applyAnimationStyle(index, keyframes)

    tickerBlock.style.animation = `tickerAnimation${index} ${timeDuration}s linear infinite ${direction === 'left' ? 'reverse' : 'normal'}`
  }

  function cloneTickerBlock(ticker) {
    const tickerBlock = ticker.querySelector('.ticker__block')
    const screenWidth = window.innerWidth

    const tagsLst = tickerBlock.querySelectorAll('.tags-gradient__tag')
    if (ticker.scrollWidth < screenWidth && tagsLst.length < 18 || window.innerWidth > 1100) {
      const clone = tickerBlock.cloneNode(true)
      ticker.appendChild(clone)
    }
  }

  function refreshTickersAnimation() {
    document.querySelectorAll('.ticker__block').forEach((tickerBlock) => {
      tickerBlock.style.animation = ''
    })
  }

  function initializeTickers() {
    refreshTickersAnimation()
    document.querySelectorAll('.ticker').forEach((ticker, index) => {
      const initTicker = () => {
        setTimeout(function () {
          cloneTickerBlock(ticker)
          createTickerAnimation(ticker, index)
          initHitboxClick(ticker)
        },10)

      }
      executeOnScroll(ticker, initTicker)
    })
  }

  function debounce(fn, delay) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => fn(...args), delay)
    }
  }

  const handleResize = debounce(() => {
    if (window.innerWidth !== previousWidth) {
      previousWidth = window.innerWidth
      initializeTickers()
    }
  }, 300)

  setTimeout(initializeTickers, 300)
  window.addEventListener('resize', handleResize)
})

function initHitboxClick(ticker) {
  ticker.querySelectorAll('.hit-box').forEach((item) => {
    const href = item.dataset.href
    const title = item.dataset.title

    if (href) {
      item.addEventListener('click', () => {
        if (title) {
          window.analytics.send('customEvent', {
            url: window.location.href,
            text: title,
          }, 'tag_click')
        }

        if (item.dataset.target === '_blank') {
          window.open(href, '_blank')
        } else {
          window.location.href = href
          console.log('href', href)
        }
      })
    }
  })
}
