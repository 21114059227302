import { ready } from '../../../js/utils/documentReady'

ready(() => {
  function updateTagVisibility() {
    const tagsContainers = document.querySelectorAll('.tag-list')

    tagsContainers.forEach((tagsContainer) => {
      const tags = Array.from(
        tagsContainer.querySelectorAll('.tag-list__inner .tag'),
      )
      const moreTag = tagsContainer.querySelector('.tag-list__more .tag')

      if (tags.length === 0) return

      tags.forEach((tag) => (tag.style.display = 'block'))
      moreTag.style.display = 'none'
      let countHiddenTags = 0
      const firstTagTop = tags[0].offsetTop

      const lastTag = tags[tags.length - 1]

      if (lastTag.offsetTop !== firstTagTop) {
        moreTag.style.display = 'block'

        tags.forEach((tag) => {
          if (tag.offsetTop !== firstTagTop) {
            countHiddenTags += 1
            tag.style.display = 'none'
          }
        })

        moreTag.innerHTML = `+${countHiddenTags}`
      } else {
        moreTag.style.display = 'none'
      }

      moreTag.addEventListener('click', () => {
        tagsContainer.classList.add('tag-list_visible_all')
      })
    })
  }

  updateTagVisibility()

  window.addEventListener('resize', updateTagVisibility)
})
