/* swiper */
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  new Swiper('.js-swiperDidYouKnow', {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.js-didYouKnowButtonNext',
      prevEl: '.js-didYouKnowButtonPrev',
      disabledClass: 'did-you-know__button-disabled',
    },
    autoHeight: true,
    pagination: {
      el: '.js-didYouKnowPagination',
      type: 'fraction',
    },
    speed: 500,
    on: {
      init() {
        this.update()
      },
    },
  })

  const textElements = document.querySelectorAll('.did-you-know__slide-text')
  const textMaxLength = 340

  if (textElements) {
    textElements.forEach(text => {
      const innerText = text.innerText
      const altBlock = text.closest('.main-3__relative-2')
      
      if (innerText.length > textMaxLength && !altBlock) {
        text.innerText = innerText.slice(0, textMaxLength) + '...'
      }
    })
  }
})
