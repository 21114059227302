import { ready } from '../../../js/utils/documentReady'

ready(() => {
  function checkTagsContent(container) {
    const tags = Array.from(container.children).filter(tag =>
      !tag.classList.contains('select-tags__favorites-tag_close'),
    )

    if (tags.length > 0) {
      container.style.display = 'flex'
    } else {
      container.style.display = 'none'
    }
  }

  function initObserver(container) {
    const observer = new MutationObserver(() => checkTagsContent(container))
    const clearTagsElement = container.querySelector('.select-tags__favorites-tag_close')

    clearTagsElement.addEventListener('click', () => {
      const selectors = JSON.parse(clearTagsElement.dataset.inputSelectors)

      for (let selector of selectors) {
        const input = document.querySelector(selector)
        if (input) {
          input.value = ''
          const changeEvent = new Event('change')
          input.dispatchEvent(changeEvent)
        }
      }
    })

    observer.observe(container, {
      childList: true,
      subtree: true,
      characterData: true,
    })

    checkTagsContent(container)
  }

  const containers = document.querySelectorAll('.select-tags')
  containers.forEach(container => {
    const childDiv = container.querySelector('div')
    if (childDiv && childDiv.parentElement === container) {
      initObserver(childDiv)
    }
  })
})
