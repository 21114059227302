import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const acceptButtonModal = document.querySelector('.js-excursionsFieldAcceptButton')
  const closeButtonModal = document.querySelector('.js-excursionsFieldCloseButton')
  const excursionsField = document.querySelector('.js-excursionsFieldModal')
  const excursionsFieldInner = document.querySelector('.js-excursionsFieldModal > div')

  const hideModal = () => {
    excursionsField.classList.remove('events-page__excursions-field-modal_show')
    document.body.style.overflow = 'auto'
  }

  if (acceptButtonModal) {
    acceptButtonModal.addEventListener('click', () => {
      hideModal()
    })
  }

  if (closeButtonModal) {
    closeButtonModal.addEventListener('click', () => {
      hideModal()
    })
  }

  if (excursionsField) {
    excursionsField.addEventListener('click', () => {
      hideModal()
    })
  }

  if (excursionsFieldInner) {
    excursionsFieldInner.addEventListener('click', () => {
      event.stopPropagation()
    })
  }
})
