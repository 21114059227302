// const buttons = document.querySelectorAll('.bitrix-content .button')
//
// buttons.forEach((button) => {
//   const nextElement = button.nextElementSibling
//   if (nextElement && nextElement.tagName.toLowerCase() === 'span') {
//     const wrapper = document.createElement('div')
//     wrapper.classList.add('button-wrapper')
//
//     button.parentNode.insertBefore(wrapper, button)
//     wrapper.appendChild(button)
//     wrapper.appendChild(nextElement)
//   }
// })
