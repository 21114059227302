window.scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

window.scrollToSelector = (selector) => {
  const element = document.querySelector(selector)
  const header = document.querySelector('.header')

  if (element && header) {
    const headerHeight = header.offsetHeight
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset

    window.scrollTo({
      top: elementPosition - headerHeight,
      behavior: 'smooth'
    })
  }
}
