import {loadImages} from './loadImage'

export function loadingSlides(activeIndex, slides, leftCount, rightCount) {
  loadImages(slides[activeIndex])

  for (let i = 1; i <= leftCount; i++) {
    if (slides[activeIndex - i]) {
      loadImages(slides[activeIndex - i])
    }
  }

  for (let i = 1; i <= rightCount; i++) {
    if (slides[activeIndex + i]) {
      loadImages(slides[activeIndex + i])
    }
  }
}
