import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

ready(() => {
  const select = document.querySelector('.js-gallerySelectInputWrapper')
  const buttonModalBack = document.querySelector('.js-gallerySelectHeaderButton')
  const buttonModalAccept = document.querySelector('.js-gallerySelectAcceptButton')
  const selectMenu = document.querySelector('.js-gallerySelectMenu')
  const optinons = document.querySelector('.gallery-select__items')
  const label = document.querySelector('.js-gallerySelectLabel')
  const selectItems = document.querySelectorAll('.js-gallerySelectItem')
  const modal = document.querySelector('.js-gallerySelectModal')
  const { isMobile, isTablet } = getSize()
  const isgallerySelect = document.querySelector('.js-gallerySelect')

  if (isgallerySelect) {
    function setMaxHeight() {
      const rect = selectMenu.getBoundingClientRect()
      const distanceToBottom = window.innerHeight - rect.top - 20
      const maxHeight = Math.max(200, distanceToBottom)
      selectMenu.style.maxHeight = `${maxHeight}px`
      optinons.style.maxHeight = `${maxHeight - 50}px`
    }

    const onSelect = (item) => {
      const id = item.getAttribute('data-value')
      const defaultLabel = label.getAttribute('data-default-label')
      label.setAttribute('data-current-checked', id)

      if (id === 'all-areas') {
        label.textContent = defaultLabel
        return
      }

      label.textContent = item.textContent
    }

    if(isMobile || isTablet) {
      setMaxHeight()
      window.addEventListener('resize', setMaxHeight)
    }

    const setPrevCheckedElement = () => {
      const prevCheckedElem = label.getAttribute('data-current-checked')

      selectItems.forEach(item => {
        const currentElement = item.getAttribute('data-value')
        const currentInputElement = item.querySelector('input')

        currentInputElement.checked = currentElement === prevCheckedElem
      })
    }

    selectItems.forEach(item => {
      item.addEventListener('click', () => {
        if (!isMobile) {
          onSelect(item)
          select.classList.remove('active')
        }
      })
    })

    // Главный инпут (сам селект)
    select.addEventListener('click', () => {
      event.stopPropagation()
      select.classList.toggle('active')
      setMaxHeight()

      if(select.classList.contains('active')) {
        if(isMobile) {
          document.body.classList.add('body-overflow-hidden')
        }
      } else {
        if(isMobile) {
          document.body.classList.remove('body-overflow-hidden')
        }
      }
    })

    // Кнопка назад в модалке
    buttonModalBack.addEventListener('click', () => {
      event.stopPropagation()

      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
      if(isMobile) {
        document.body.classList.remove('body-overflow-hidden')
      }
    })

    // Кнопка "выбрать" на модалке
    buttonModalAccept.addEventListener('click', () => {
      event.preventDefault()
      event.stopPropagation()

      selectItems.forEach(item => {
        const currentInputElement = item.querySelector('input')

        if (currentInputElement.checked) {
          onSelect(item)
        }
      })

      select.classList.remove('active')
      if(isMobile) {
        document.body.classList.remove('body-overflow-hidden')
      }
    })

    selectMenu.addEventListener('click', () => {
      event.stopPropagation()
    })

    // Затемнённая зона модалки
    modal.addEventListener('click', () => {
      event.stopPropagation()

      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
      if(isMobile) {
        document.body.classList.remove('body-overflow-hidden')
      }
    })

    window.addEventListener('click', () => {
      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
      if(isMobile) {
        document.body.classList.remove('body-overflow-hidden')
      }
    })
  }
})

