import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const closeButtons = document.querySelectorAll('.js-closePopupSubscribe')

  closeButtons.forEach(function(button) {
    button.onclick = function() {
      const popup = document.querySelector('.popup-subscribe')
      if (popup) {
        popup.classList.remove('popup-subscribe_open')
        document.body.classList.remove('body-overflow-hidden')

        setTimeout(() => {
          if (!popup.classList.contains('popup-subscribe_open')) {
            popup.classList.remove('popup-subscribe_hidden')
          }
        }, 300)
      }
    }
  })
})
