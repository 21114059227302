document.querySelectorAll('.bitrix-content iframe').forEach((iframe) => {
  const parentElement = iframe.parentNode
  const iframeWrapper = document.createElement('div')
  iframeWrapper.classList.add('bitrix-content-iframe')
  iframeWrapper.innerHTML = '<div class="bitrix-content-iframe__inner"></div>'

  const iframeInner = iframeWrapper.querySelector('.bitrix-content-iframe__inner')

  const previewDiv = document.createElement('div')
  previewDiv.innerHTML = '<div class="bitrix-content-iframe__button"></div>'
  previewDiv.classList.add('bitrix-content-iframe__preview')

  const previewImageElement = document.createElement('img')
  previewImageElement.src = iframe.getAttribute('preview') // Используйте data-preview для хранения URL превью
  previewImageElement.alt = 'Preview Image'

  previewDiv.appendChild(previewImageElement)

  const iframeClone = iframe.cloneNode(true)
  iframeInner.appendChild(iframeClone)
  iframeInner.appendChild(previewDiv)

  parentElement.replaceChild(iframeWrapper, iframe)

  function onPreviewClick() {
    previewDiv.classList.add('bitrix-content-iframe__preview_hidden')

    let srcAttribute = iframeClone.getAttribute('src')

    if (srcAttribute.indexOf('?') !== -1) {
      srcAttribute += '&autoplay=1'
    } else {
      srcAttribute += '?autoplay=1'
    }

    iframeClone.setAttribute('src', srcAttribute)
  }

  previewDiv.addEventListener('click', onPreviewClick)
})
