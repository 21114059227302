// Подключаем скрипты из components/*

import './header/header'
import './breadcrumbs/breadcrumbs'
import './picture/lazy-picture'
// import './pagination/pagination'
import './modal/modal'
import './scroll-link/scroll-link'
import './burger/burger'
import './field-text/field-text'
import './to-top/to-top'
import './field-select/field-select'
import './simple-bar/simple-bar'
import './field-num/field-num'
import './bitrix-content/bitrix-content'
import './accordion/accordion'
import './dropdown/dropdown'
import './cookie/cookie'
import './swiper/swiper'
import './swiper-main/swiper-main'
import './gallery/gallery'
import './map3/map3'
import './tabs/tabs'
import './form/index'
import './search/search'
import './yandex-share/yandex-share'
import './burger-menu/burger-menu'
import './ticker/ticker'
import './banner-with-mask/banner-with-mask'
import './animation/animation'
import './animation/paralax'
import './animation/bg-paralax'
import './swiper-news/swiper-news'
import './header-mock/header-mock'
import './banner-tg-mock/banner-tg-mock'
import './popup-subscribe/popup-subscribe'
import './events/events'
import './gallery/swiper-video'
import './gallery/swiper-images'
import './bg-overlay/bg-overlay'
import './tags-gradient/tags-gradient'
import './ticker/ticker'
import './event-program/event-program'
import './swiper-mask-banner/swiper-mask-banner'
import './area-detail-preview/area-detail-preview'
import './area-schemes/area-schemes'
import './tag-list/tag-list'
import './link-list/link-list'
import './partners/partners'
import './contacts/contacts'
import './platforms/platforms'
import './popup-accreditation/popup-accreditation'
import './field-custom-text/field-custom-text'
import './field-custom-select/field-custom-select'
import './field-single-checkbox/field-single-checkbox'
import './popup-accreditation/close-accreditation'
import './popup-accreditation/open-accreditation'
import './swiper-special-projects/swiper-special-projects'
import './main-video/main-video'
import './event-online/event-online'
import './event-program-form/event-program-form'
import './faq/faq'
import './event-detail-preview/event-detail-preview'
import './slider-poster/slider-poster'
import './poster/poster'
import './personal-account-tabs/personal-account-tabs'
import './event-program-list/event-program-list'
import './tickets-list/tickets-list'
import './field-custom-multiple-select/field-custom-multiple-select'
import './option-list/option-list'
import './field-custom-checkbox/field-custom-checkbox'
import './popup-warning/popup-warning'
import './personal-account-empty/personal-account-empty'
import './toast-notification/toast-notification'
import './popup-signin-profile/popup-signin-profile'
import './files/files'
import './search-placeholder-block/search-placeholder-block'
import './search-card/search-card'
import './select-areas/select-areas'
import './switch-areas/switch-areas'
import './program-picker-popup/program-picker-popup'
import './select-tags/select-tags'
import './swiper-speakers/swiper-speakers'
import './swiper-gallery/swiper-gallery'
import './photo-albums/photo-albums'
import './gallery-select/gallery-select'
import './gallery-tab/gallery-tab'
import './video-list/video-list'
import './video-popup/video-popup'
import './gallery-mansory/gallery-mansory'
import './warning-info/warning-info'
import './gallery-popup/gallery-popup'
import './card-event/card-event'
import './platforms-card/platforms-card'
import './radio-switch-button/radio-switch-button'
import './image-block/image-block'
import './excursions-field/excursions-field'
import './do-you-know-card/do-you-know-card'
import './do-you-know-cards/do-you-know-cards'
import './preloader/preloader'
import './event-table-row/event-table-row'
import './switch-view-events/switch-view-events'
import './banner-main/banner-main'
import './desc-main/desc-main'
import './box-video/box-video'
import './news-2/news-2'
import './ticker-slider/ticker-slider'
import './mask-decor-banner/mask-decor-banner'
import './did-you-know/did-you-know'
import './icon-2030/icon-2030'
import './header/header-main-3'
import './how-it-was/how-it-was'
import './moscow-territory/moscow-territory'
import './sites/sites'
import './reviews/reviews'
import './unique-events/unique-events'
import './moscow-best/moscow-best'
import './international-formats/international-formats'
import './quote-block/quote-block'
import './visitors/visitors'
import './moscow-territory-item/moscow-territory-item'
import './decorate-btn/decorate-btn'
import './event-card/event-card'
import './card-event-notLazy/card-event-notLazy'
import './sites-stat/sites-stat'
import './diogram/diogram'
import './reviews-card-main/reviews-card-main'
import './reviews-card/reviews-card'
import './art-gallery/art-gallery'
import './d-text-block/d-text-block'
import './diagram-circle/diagram-circle'
import './partners-alt/partners-alt'
import './article-alfa-slider/article-alfa-slider'
import './a-city-window/a-city-window'
import './galleries-future-banner/galleries-future-banner'
import './msc-banner/msc-banner'
import './page-in-modal/page-in-modal'
import './strategy-hero/strategy-hero'
import './timeline3d/timeline3d'
import './timeline3d/timeline2d'
import './timeline3d-popup/timeline3d-popup'
import './directions-card/directions-card'
import './directions-polygon/directions-polygon'
import './full-page-scroll/full-page-scroll'
import './swiper-full-page/swiper-full-page'
import './articles-list/articles-list'
import './books-list/books-list'
import './books-preview/books-preview'
import './triangle-decor/triangle-decor'
import './parallax-image/parallax-image'
