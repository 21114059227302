// import { ready } from '../../../js/utils/documentReady'
//
// ready(() => {
//   const gapAnchorHeader = 20
//
//
//   document.addEventListener('click', function(e) {
//     if (e.target.tagName === 'A' && e.target.hash && e.target.pathname === window.location.pathname) {
//       e.preventDefault()
//       const hash = e.target.hash
//       const target = document.querySelector(hash)
//
//       if (target) {
//         const headerHeight = document.querySelector('.header').offsetHeight
//         const targetPosition = target.getBoundingClientRect().top + window.pageYOffset
//         const offsetPosition = targetPosition - headerHeight - gapAnchorHeader
//
//         scrollbar.scrollTo(0, offsetPosition)
//
//         // window.scrollTo({
//         //   top: offsetPosition,
//         //   behavior: 'smooth'
//         // })
//       }
//     }
//   })
//
//   const hash = window.location.hash
//   if (hash) {
//     const target = document.querySelector(hash)
//     if (target) {
//       setTimeout(() => {
//         const headerHeight = document.querySelector('.header')?.offsetHeight || document.querySelector('.header-mock')?.offsetHeight
//         const targetPosition = target.getBoundingClientRect().top + window.pageYOffset
//         const offsetPosition = targetPosition - headerHeight - gapAnchorHeader
//
//         scrollbar.scrollTo(0, offsetPosition)
//
//         // window.scrollTo({
//         //   top: offsetPosition,
//         //   behavior: 'smooth'
//         // })
//       }, 500)
//     }
//   }
// })

import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const gapAnchorHeader = 20

  document.addEventListener('click', function(e) {
    if (e.target.tagName === 'A' && e.target.hash && e.target.pathname === window.location.pathname) {
      e.preventDefault()
      const hash = e.target.hash
      const target = document.querySelector(hash)

      if (target) {
        const headerHeight = document.querySelector('.header').offsetHeight
        const targetPosition = target.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = targetPosition - headerHeight - gapAnchorHeader

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    }
  })

  const hash = window.location.hash
  if (hash) {
    const target = document.querySelector(hash)
    if (target) {
      setTimeout(() => {
        const headerHeight = document.querySelector('.header')?.offsetHeight || document.querySelector('.header-mock')?.offsetHeight
        const targetPosition = target.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = targetPosition - headerHeight - gapAnchorHeader

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }, 500)
    }
  }
})
