import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const tabs = document.querySelectorAll('.tabs')

  for(let tab of tabs) {
    const navElements = document.querySelectorAll('.js-tabsNav')

    for (let navElement of navElements) {
      navElement.addEventListener('click', () => {
        tab.querySelector('.tabs__nav-item_active')?.classList.remove('tabs__nav-item_active')
        navElement.classList.add('tabs__nav-item_active')
        tab.querySelector('.tab-pane_active')?.classList.remove('tab-pane_active')
        const activeTab = tab.querySelector(`#${navElement.dataset.id}`)
        activeTab?.classList.add('tab-pane_active')
      })
    }
  }
})
