import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('numberCustomMax', function(value, element) {
    const customMax = $(element).attr('custommax');

    if (value.length > customMax) {
      return false;
    }

    return true;
  }, function(params, element) {
    const customMax = $(element).attr('custommax');
    return `Максимальное количество символов: ${customMax}`;
  });
})
