import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

ready(() => {
  const customSelects = document.querySelectorAll('.field-custom-multiple-select')

  customSelects.forEach(customSelect => {
    const input = customSelect.querySelector('.field-custom-multiple-select__input')
    const inputHitBox = customSelect.querySelector('.field-custom-multiple-select__input-hit-box')
    const options = customSelect.querySelector('.field-custom-multiple-select__options')
    const optionElements = customSelect.querySelectorAll('.field-custom-multiple-select__option input')
    const header = document.querySelector('.header')
    const searchInput = customSelect.querySelector('.field-custom-multiple-select__search input')
    const emptyMessage = customSelect.querySelector('.field-custom-multiple-select__empty')
    const resultSelector = customSelect.dataset.resultSelector
    const resultElement = resultSelector ? document.querySelector(resultSelector) : undefined
    const defaultTitle = customSelect.querySelector('.field-custom-multiple-select__title').innerHTML

    if (resultElement) {
      const name = input.name

      const changeEvent = () => {
        const value = input.value ? input.value.split(',') : []

        const renderTag = (i, inputName, label) => {
          const tag = document.createElement('div')
          tag.classList.add('select-tags__favorites-tag')

          const title = document.createElement('div')
          title.classList.add('select-tags__favorites-tag-title')
          title.textContent = label

          const icon = document.createElement('div')
          icon.classList.add('select-tags__favorites-tag-icon')
          icon.setAttribute('data-input', inputName)
          icon.setAttribute('data-value', i)

          tag.appendChild(title)
          tag.appendChild(icon)

          return tag
        }

        const existingTags = resultElement.querySelectorAll(`.select-tags__favorites-tag [data-input="${name}"]`)
        existingTags.forEach(tag => {
          const tagValue = tag.getAttribute('data-value')
          if (!value.includes(tagValue)) {
            tag.parentNode.remove()
          }
        })

        value.forEach(i => {
          if (!resultElement.querySelector(`.select-tags__favorites-tag [data-input="${name}"][data-value="${i}"]`)) {
            const input = customSelect.querySelector(`.field-custom-multiple-select__option input[value="${i}"]`)
            const label = input?.getAttribute('data-label')

            const newTag = renderTag(i, name, label)
            resultElement.appendChild(newTag)
          }
        })

        if (value?.length) {
          customSelect.classList.add('field-custom-multiple-select_selected')
        } else {
          customSelect.classList.remove('field-custom-multiple-select_selected')
        }
      }

      input.addEventListener('change', changeEvent)
      changeEvent()

      resultElement.addEventListener('click', (event) => {
        if (event.target.classList.contains('select-tags__favorites-tag-icon')) {
          const tagValue = event.target.getAttribute('data-value')
          let values = input.value ? input.value.split(',') : []
          values = values.filter(value => value !== tagValue)
          input.value = values.join(',')

          const changeEvent = new Event('change')
          input.dispatchEvent(changeEvent)
        }
      })
    } else {
      const titleElement = customSelect.querySelector('.field-custom-multiple-select__title')
      const changeEvent = () => {
        const value = input.value ? input.value.split(',') : []

        let result = []

        value.forEach(i => {
          const input = customSelect.querySelector(`.field-custom-multiple-select__option input[value="${i}"]`)
          const label = input?.getAttribute('data-label')
          if(label) {
            result.push(label)
          }
        })

        const resultValue = result.join(', ')

        if(resultValue) {
          titleElement.innerHTML = resultValue
        } else {
          titleElement.innerHTML = defaultTitle
        }
      }

      input.addEventListener('change', changeEvent)
      changeEvent()
    }

    searchInput.addEventListener('input', function() {
      const query = searchInput.value.toLowerCase()
      let hasVisibleOptions = false

      optionElements.forEach(optionElement => {
        const optionLabel = optionElement.closest('.field-custom-multiple-select__option')
        const optionText = optionLabel.querySelector('p').textContent.toLowerCase()

        if (optionText.includes(query)) {
          optionLabel.style.display = ''
          hasVisibleOptions = true
        } else {
          optionLabel.style.display = 'none'
        }

        options.style.height = 'auto'
        const contentHeight = options.scrollHeight

        requestAnimationFrame(() => {
          options.style.height = `${contentHeight}px`
        })

        if( hasVisibleOptions ) {
          emptyMessage.classList.remove('field-custom-multiple-select__empty_visible')
        } else {
          emptyMessage.classList.add('field-custom-multiple-select__empty_visible')
        }
      })
    })

    const initEvents = (element) => {
      const optionElements = element.querySelectorAll('.field-custom-multiple-select__option input')
      const closeIconElement = element.querySelector('.field-custom-multiple-select__options-close')
      const closeButtonElement = element.querySelector('.field-custom-multiple-select__options-button')

      closeIconElement.addEventListener('click', () => {
        inputHitBox.click()
      })

      closeButtonElement.addEventListener('click', () => {
        inputHitBox.click()
      })

      optionElements.forEach(option => {
        option.addEventListener('change', () => {
          updateInputValue(optionElements)
        })
      })
    }

    initEvents(customSelect)

    if (input.value) {
      customSelect.classList.add('field-custom-multiple-select_selected')
    }

    inputHitBox.addEventListener('click', () => {
      const { isMobile } = getSize()
      if (customSelect.classList.contains('field-custom-multiple-select_open')) {
        if (isMobile) {
          removeOptionsFromBody()
        } else {
          options.style.height = `${options.scrollHeight}px`
          requestAnimationFrame(() => {
            options.style.height = '0'
          })
        }
      } else {
        if (isMobile) {
          addOptionsToBody()
        } else {
          options.style.height = 'auto'
          const contentHeight = options.scrollHeight
          options.style.height = '0'
          requestAnimationFrame(() => {
            options.style.height = `${contentHeight}px`
          })
          setTimeout(() => {
            searchInput.focus()
          })
        }
      }

      customSelect.classList.toggle('field-custom-multiple-select_open')
    })

    input.addEventListener('change', () => {
      updateCheckboxes()
    })

    document.addEventListener('click', (event) => {
      const { isMobile } = getSize()
      if (!customSelect.contains(event.target) && !isMobile) {
        customSelect.classList.remove('field-custom-multiple-select_open')
      }
    })

    function updateInputValue(optionElements) {
      const result = []
      optionElements.forEach(option => {
        if (option.checked) {
          result.push(option.value)
        }
      })
      input.value = result.join(',')

      const changeEvent = new Event('change')
      input.dispatchEvent(changeEvent)
    }

    function updateCheckboxes() {
      const values = input.value.split(',')
      const isRadio = optionElements.length > 0 && optionElements[0].type === 'radio'

      if (isRadio) {
        optionElements.forEach(option => {
          option.checked = false
        })

        for (let option of optionElements) {
          if (values.includes(option.value)) {
            option.checked = true
            break
          }
        }
      } else {
        optionElements.forEach(option => {
          option.checked = values.includes(option.value)
        })
      }
    }

    function addOptionsToBody() {
      header.classList.add('header_static')

      const clonedOptions = options.cloneNode(true)

      const formElement = document.createElement('form')

      formElement.className = clonedOptions.className
      Array.from(clonedOptions.attributes).forEach(attr => {
        formElement.setAttribute(attr.name, attr.value)
      })

      while (clonedOptions.firstChild) {
        formElement.appendChild(clonedOptions.firstChild)
      }

      formElement.classList.add('field-custom-multiple-select__options_cloned')

      document.body.prepend(formElement)

      initEvents(formElement)

      setTimeout(() => {
        formElement.classList.add('field-custom-multiple-select__options_cloned-open')
      }, 0)
    }

    function removeOptionsFromBody() {
      header.classList.remove('header_static')
      const clonedOptions = document.querySelector('.field-custom-multiple-select__options_cloned')
      clonedOptions.classList.remove('field-custom-multiple-select__options_cloned-open')

      setTimeout(() => {
        const clonedOptions = document.querySelector('.field-custom-multiple-select__options_cloned')
        if (clonedOptions) {
          document.body.removeChild(clonedOptions)
        }
      }, 300)

    }

    updateCheckboxes()
  })
})
