import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const openButtons = document.querySelectorAll('.js-openPopupAccreditation')

  openButtons.forEach(function(button) {
    button.onclick = function() {
      const popup = document.querySelector('.popup-accreditation')
      if (popup) {
        popup.classList.add('popup-accreditation_open')
      }
    }
  })
})
