import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const options = document.querySelectorAll('.js-galleryTabOption')
  const switchBlock = document.querySelector('.js-galleryTab')

  if (switchBlock) {
    const activeId = switchBlock.getAttribute('data-active-id')

    document.querySelectorAll('#photo, #video').forEach(content => {
      if (content.id !== activeId) {
        content.style.display = 'none'
      } else {
        content.style.display = 'block'
      }
    })

    options.forEach(option => {
      option.addEventListener('click', () => {
        const currentId = option.getAttribute('data-id')
        const isCurrentActiveElement = switchBlock.getAttribute('data-active-id')

        if (isCurrentActiveElement === currentId) return

        switchBlock.setAttribute('data-active-id', currentId)

        document.querySelectorAll('#photo, #video').forEach(content => {
          content.style.display = 'none'
        })

        const activeContent = document.getElementById(currentId)
        if (activeContent) {
          activeContent.style.display = 'block'
        }
      })
    })
  }
})
