import { ready } from '../../../../js/utils/documentReady'
import { structureData } from '../../../../js/utils/structureFormData'

ready(() => {
  $.extend($.validator.messages, {
    required: 'Поле обязательно для заполнения',
    equalTo: 'Пароли не совпадают',
    range: $.validator.format('Введите число от {0} до {1}'),
    max: $.validator.format('Максимальная длина - {0} символов'),
    min: $.validator.format('Введите число, большее или равное {0}'),
    maxlength: $.validator.format('Максимальная длина - {0} символов'),
    minlength: $.validator.format('Введите не меньше {0} символов'),
  })

  function validateForm($form, additionalSubmitHandler) {
    const $submitButton = $form.find('button[type="submit"]')

    $form.on('submit', function(event) {
      event.preventDefault()
    })

    $form.validate({
      // ignore: ':hidden',
      submitHandler: function(form) {
        const formData = $(form).serializeArray()

        if (additionalSubmitHandler) {
          additionalSubmitHandler(structureData(formData))
        } else {
          form.submit()
        }
      },
      success: function() {
        $submitButton.removeClass('disabled')
      },
      invalidHandler: function() {
        $submitButton.addClass('disabled')
      },
    })
  }

  $('.js-validate').each(function() {
    validateForm($(this))
  })

  $('.js-subscribeValidate').each(function() {
    const $form = $(this)
    const $submitButton = $form.find('button[type="submit"]')
    const $input = $form.find('input')

    $form.validate({
      submitHandler: function(form) {
        const formData = $(form).serializeArray()

        if (formData[0].value !== '') {
          document.querySelector('.popup-subscribe')?.classList.add('popup-subscribe_open')
          document.querySelector('.popup-subscribe')?.classList.remove('popup-subscribe_hidden')
          document.body.classList.add('body-overflow-hidden')
        } else {
          $submitButton.addClass('disabled')
          $input.addClass('error')
        }
      },
    })

    $form.on('keyup change', 'input, filter-select, textarea', function() {
      if ($form.valid()) {
        $submitButton.removeClass('disabled')
      } else {
        $submitButton.addClass('disabled')
      }
    })
  })

  $('.js-validate-first-step').each(function() {
    validateForm($(this), function(data) {
      localStorage.setItem('firstStepData', JSON.stringify(data))
      const contentElement = document.querySelector('.popup-accreditation__content')

      if (contentElement.classList.contains('popup-accreditation__content_step-1')) {
        contentElement.classList.remove('popup-accreditation__content_step-1')
        contentElement.classList.add('popup-accreditation__content_step-2')
      }
    })
  })

  $('.js-validate-second-step').each(function() {
    validateForm($(this), function(data) {
      const firstStepData = JSON.parse(localStorage.getItem('firstStepData'))

      const finalData = {
        ...data,
        ...firstStepData,
        general: {
          ...data.general,
          ...firstStepData.general
        }
      }

      if (finalData.general['document-type'] === 'Паспорт РФ') {
        delete finalData['not-rf-resident']
      } else {
        delete finalData['rf-resident']
      }

      console.log(finalData)

      $.ajax({
        url: '/api/v1/registration-media',
        type: 'POST',
        data: finalData,
      })

      const email = finalData.general.email
      const emailElement = document.querySelector('.popup-accreditation__finish-description a')

      if(emailElement) {
        emailElement.innerHTML = ' ' + email
      }

      const contentElement = document.querySelector('.popup-accreditation__content')

      if (contentElement.classList.contains('popup-accreditation__content_step-2')) {
        contentElement.classList.remove('popup-accreditation__content_step-2')
        contentElement.classList.add('popup-accreditation__content_step-3')
      }
    })
  })

  $('.js-validate-options').each(function() {
    var $form = $(this)
    var $submitBtn = $form.find('button[type="submit"]')

    $submitBtn.prop('disabled', true)

    $form.on('input change', function() {
      $submitBtn.prop('disabled', false)
    })

    $form.validate({
      submitHandler: function() {
        // form.submit()
      },
      invalidHandler: function(event) {
        event.preventDefault()
      }
    })
  })
})
