import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { loadImages } from '../../../js/utils/loadImage'

ready(() => {
  const sliderElement = document.querySelector('.js-sliderPoster')

  if (!sliderElement) {
    return
  }

  const navigationElement = document.querySelector('.slider-poster__navigation_mobile')

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px 75px 0px',
    threshold: 0
  }

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const swiperInstance = new Swiper('.js-sliderPoster', {
          modules: [Navigation, Pagination],
          navigation: {
            nextEl: '.js-sliderPosterButtonNext',
            prevEl: '.js-sliderPosterButtonPrev',
            disabledClass: 'swiper-news__button-disabled',
          },
          pagination: {
            el: '.js-sliderPosterPagination',
            clickable: true,
            bulletClass: 'slider-poster__pagination-bullet',
            bulletActiveClass: 'slider-poster__pagination-bullet-active',
          },
          slidesPerView: 'auto',
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          spaceBetween: 40,
          speed: 500,
          on: {
            init() {
              this.update()
              loadImages(this.slides[this.activeIndex])
              if (this.slides[this.activeIndex + 1]) {
                loadImages(this.slides[this.activeIndex + 1])
              }
            },
            slideChange() {
              loadImages(this.slides[this.activeIndex])
              if (this.slides[this.activeIndex + 1]) {
                loadImages(this.slides[this.activeIndex + 1])
              }
              if (this.slides[this.activeIndex - 1]) {
                loadImages(this.slides[this.activeIndex - 1])
              }
            }
          }
        })

        if (swiperInstance.slides.length === 0 && navigationElement) {
          navigationElement.style.display = 'none'
        }

        observer.unobserve(sliderElement)
      }
    })
  }

  const observer = new IntersectionObserver(observerCallback, observerOptions)

  observer.observe(sliderElement)
})
