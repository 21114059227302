import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('customFile', function(value, element) {
    if (value === 'true' || value === 'to-many') {
      return false
    } else if (value === 'false') {
      return true
    } else if (value === 'empty') {
      return !$(element).data('required')
    }
    return true
  }, function(value, element) {
    console.log('element.value',element, element.value)
    if(element.value === 'empty') {
      return 'Данное поле обязательно'
    } else if(element.value === 'to-many') {
      return 'Слишком много файл'
    } else {
      return 'Одно из полей некоректно'
    }
  })
})
