import Swiper from 'swiper'
import { Pagination, Autoplay, EffectFade } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  let initFlag = false

  // defaultAutoplayTime - время загрузки слайда (картинки) в ms
  const defaultAutoplayTime = 4000

  // waitingPageAnimation - время прогрузки анимации слайдера
  const waitingPageAnimation = 3000

  // speed - скорость перехода между слайдами
  const speed = 1500

  // autoplayTime - время работы слайда
  const autoplayTime = (videoTag) => {
    if (videoTag) {
      const duration = Number(videoTag.getAttribute('data-v-duration'))

      return duration - 3500
    }

    return defaultAutoplayTime
  }

  // bulletAnimationTime - время работы булета (пагинации)
  const bulletAnimationTime = (videoTag) => {
    if (videoTag) {
      const duration = Number(videoTag.getAttribute('data-v-duration'))

      return (duration - speed) / 1000
    }

    return (defaultAutoplayTime + speed) / 1000
  }

  new Swiper('.js-swiperMain', {
    modules: [Pagination, Autoplay, EffectFade],
    loop: true,
    autoplay: {
      delay: defaultAutoplayTime,
      disableOnInteraction: false,
    },
    runCallbacksOnInit: false,
    pagination: {
      el: '.js-swiperPagination',
      clickable: true,
      bulletClass: 'swiper-main__pagination-bullet',
      bulletActiveClass: 'swiper-main__pagination-bullet_active',
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    allowTouchMove: false,
    speed: speed,
    on: {
      init() {
        this.update()
        this.autoplay.stop()

        updateActiveBullets(this)
        addPrevVisibleClass(this)
        setTimeout(() => {
          setDataSwiperAutoplay(this)
          this.slides.forEach(slide => {
            if (slide.querySelector('video')) {
              slide.querySelector('video').play()
            }
          })
        }, waitingPageAnimation)

        setTimeout(() => {
          this.autoplay.start()
        }, waitingPageAnimation + 500)
      },
      slideChangeTransitionStart(swiper) {
        const videoTag = swiper.visibleSlides[0].querySelector('video')
        updateActiveBullets(this)
        addPrevVisibleClass(this)

        // сбрасываем видеоплеер в начало
        if (videoTag) {
          videoTag.currentTime = 0
        }
      },
    },
  })

  // setDataSwiperAutoplay - тут мы сохраняем время для слайда и для его булета (пагинации)
  function setDataSwiperAutoplay(swiper) {
    swiper.slides.forEach((slide, index) => {
      const videoTag = slide.querySelector('video')
      const bullets = document.querySelectorAll('.js-swiperPagination .swiper-main__pagination-bullet')

      if (videoTag) {
        let time = autoplayTime(videoTag)

        if (!initFlag) {
          time = time + 1500
          initFlag = true
        }

        bullets[index].style.setProperty('--before-animation', `loadAnimation ${bulletAnimationTime(videoTag)}s linear forwards`)
        slide.setAttribute('data-swiper-autoplay', time)
      } else {
        const time = String(autoplayTime(null))
        bullets[index].style.setProperty('--before-animation', `loadAnimation ${bulletAnimationTime(null)}s linear forwards`)
        slide.setAttribute('data-swiper-autoplay', time)
      }
    })
  }

  function addPrevVisibleClass(swiper) {
    swiper.slides.forEach(slide => slide.classList.remove('swiper-slide-prev-visible'))

    let prevIndex = swiper.previousIndex

    if (typeof prevIndex !== 'undefined') {
      let prevSlide = swiper.slides[prevIndex]
      if (prevSlide) {
        prevSlide.classList.add('swiper-slide-prev-visible')
      }
    }
  }

  function updateActiveBullets(swiper) {
    const bullets = document.querySelectorAll('.js-swiperPagination .swiper-main__pagination-bullet')

    bullets.forEach((bullet, index) => {
      if (index === swiper.realIndex) {
        bullet.classList.add('swiper-main__pagination-bullet_load')
      } else {
        bullet.classList.remove('swiper-main__pagination-bullet_load')
      }

      if (index < swiper.realIndex) {
        bullet.classList.add('swiper-main__pagination-bullet_active')
      } else {
        bullet.classList.remove('swiper-main__pagination-bullet_active')
      }
    })
  }
})

// ready(() => {
//   const dateIcon = document.querySelector('.swiper-main__date-icon')
//   const dateIconWrapper = document.querySelector('.swiper-main__date-icon-wrapper')
//   // const parent = document.querySelector('.swiper-main__inner')
//
//   const scrollContent = document.querySelector('.page-layout')
//
//   if (scrollContent && dateIcon) {
//     let gap = 0
//
//     const updateGap = () => {
//       const dateIconRect = dateIconWrapper.getBoundingClientRect()
//       const scrollContentRect = scrollContent.getBoundingClientRect()
//
//       const relativeTop = dateIconRect.top + dateIconRect.height - scrollContentRect.top
//       if (relativeTop < window.innerHeight) {
//         gap = relativeTop - window.innerHeight
//       } else {
//         gap = 0
//       }
//     }
//
//     // function updatePosition() {
//     //   updateGap()
//     //   const parentRect = parent.getBoundingClientRect()
//     //   const windowHeight = window.innerHeight
//     //   const translateY = windowHeight - parentRect.bottom + gap
//     //
//     //   dateIcon.style.transform = `translate3d(0, ${translateY}px, 0)`
//     // }
//
//     updateGap()
//     // updatePosition()
//     setTimeout(() => {
//       updateGap()
//       // updatePosition()
//     }, 1)
//
//     // window.addEventListener('resize', updatePosition)
//     // scrollbar.addListener(updatePosition)
//   }
// })
