export const renderMapContent = async ({ref}, newData) => {
  const {YMapClusterer, clusterByGrid} = await window.ymaps3.import('@yandex/ymaps3-clusterer@0.0.1')
  const { map } = ref
  const data = newData || ref.data

  const { YMapMarker } = window.ymaps3

  if(ref.layer) {
    map.removeChild(ref.layer)
  }

  const marker = (feature) => {
    const contentPin = document.createElement('div')

    contentPin.innerHTML = `
      <a class="pin" href="${feature.properties.link}" target="_blank">
        <div class="pin-content">
          <div class="pin-hint"><div class="pin-hint-title">${feature.properties.title}</div></div>
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="40" viewBox="0 0 29 40" fill="none">
            <ellipse cx="14.4249" cy="36.6693" rx="9.44444" ry="3.33333" fill="#333333" fill-opacity="0.2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.705 32.2607C15.1824 32.7789 14.6565 33.3004 14.1306 33.8264C13.6046 33.3004 13.0787 32.7789 12.5561 32.2607C6.55553 26.3104 1 20.8014 1 14.1306C1 6.87875 6.87875 1 14.1306 1C21.3824 1 27.2611 6.87875 27.2611 14.1306C27.2611 20.8014 21.7056 26.3104 15.705 32.2607ZM19.0564 14.1363C19.0564 16.8557 16.8518 19.0603 14.1324 19.0603C11.413 19.0603 9.20846 16.8557 9.20846 14.1363C9.20846 11.4169 11.413 9.21234 14.1324 9.21234C16.8518 9.21234 19.0564 11.4169 19.0564 14.1363Z" fill="currentColor"/>
            <path d="M14.1306 33.8264L13.4234 34.5335C13.814 34.924 14.4471 34.924 14.8377 34.5335L14.1306 33.8264ZM15.705 32.2607L15.0009 31.5506L15.705 32.2607ZM12.5561 32.2607L11.852 32.9708H11.852L12.5561 32.2607ZM14.8377 34.5335C15.3619 34.0093 15.8863 33.4892 16.4091 32.9708L15.0009 31.5506C14.4786 32.0686 13.9511 32.5916 13.4234 33.1193L14.8377 34.5335ZM11.852 32.9708C12.3748 33.4892 12.8992 34.0093 13.4234 34.5335L14.8377 33.1193C14.31 32.5916 13.7826 32.0686 13.2602 31.5507L11.852 32.9708ZM0 14.1306C0 17.7582 1.51386 21.0076 3.71779 24.0522C5.914 27.0862 8.86677 30.0106 11.852 32.9708L13.2602 31.5507C10.2449 28.5606 7.41958 25.7553 5.33788 22.8795C3.26391 20.0144 2 17.1738 2 14.1306H0ZM14.1306 0C6.32646 0 0 6.32646 0 14.1306H2C2 7.43103 7.43103 2 14.1306 2V0ZM28.2611 14.1306C28.2611 6.32646 21.9346 0 14.1306 0V2C20.8301 2 26.2611 7.43103 26.2611 14.1306H28.2611ZM16.4091 32.9708C19.3943 30.0106 22.3471 27.0862 24.5433 24.0522C26.7473 21.0076 28.2611 17.7582 28.2611 14.1306H26.2611C26.2611 17.1738 24.9972 20.0144 22.9232 22.8795C20.8415 25.7553 18.0162 28.5606 15.0009 31.5506L16.4091 32.9708ZM14.1324 20.0603C17.4041 20.0603 20.0564 17.408 20.0564 14.1363H18.0564C18.0564 16.3034 16.2996 18.0603 14.1324 18.0603V20.0603ZM8.20846 14.1363C8.20846 17.408 10.8607 20.0603 14.1324 20.0603V18.0603C11.9653 18.0603 10.2085 16.3034 10.2085 14.1363H8.20846ZM14.1324 8.21234C10.8607 8.21234 8.20846 10.8646 8.20846 14.1363H10.2085C10.2085 11.9692 11.9653 10.2123 14.1324 10.2123V8.21234ZM20.0564 14.1363C20.0564 10.8646 17.4041 8.21234 14.1324 8.21234V10.2123C16.2996 10.2123 18.0564 11.9692 18.0564 14.1363H20.0564Z" fill="white"/>
          </svg>
        </div>
      </a>
    `

    contentPin.addEventListener('click', () => {
      console.log('???')
    })

    return new YMapMarker(
      {
        coordinates: feature.geometry.coordinates,
        source: 'my-source'
      },
      contentPin
    )
  }

  const cluster = (coordinates, features) => {
    const clusterElement = circle(features.length).cloneNode(true)

    clusterElement.addEventListener('click', () => {
      // map.setLocation({zoom: map.zoom + 2, center: coordinates})

      if(clusterElement.classList.contains('cluster_active')) {
        ref.panel.setActive({active: false})
      } else {
        // clusterElement.classList.add('cluster_active')

        ref.panel.setActive({active: true, elements: features, clusterElement: clusterElement, center: coordinates})
      }
    })

    return new YMapMarker(
      {
        coordinates,
        source: 'my-source'
      },
      clusterElement
    )
  }

  function circle(count) {
    const circle = document.createElement('div')
    circle.classList.add('cluster')
    circle.innerHTML = `
        <div class="cluster-content">
            <span class="cluster-text">${count}</span>
        </div>
    `
    return circle
  }

  const points = data.map((item) => ({
    type: 'Feature',
    id: JSON.stringify(item),
    geometry: {coordinates: [...item.coordinates].reverse()},
    properties: {title: item.title, link: item.link, place: item.place, type: item.type}
  }))

  const clusterer = new YMapClusterer({
    method: clusterByGrid({gridSize: 64}),
    features: points,
    marker,
    cluster
  })

  ref.layer = clusterer

  map.addChild(clusterer)

  const initialMapEvent = new CustomEvent('initialMap', {
    bubbles: false,
  })

  ref.mapElement.dispatchEvent(initialMapEvent)
}
