import { ready } from '../../../js/utils/documentReady'
import { wrapTextLines } from '../animation/animation'

ready(() => {
  const likeElements = document.querySelectorAll('.js-platformsCardLikeButton')
  const titleCards = document.querySelectorAll('.js-platformCardTitle')

  titleCards.forEach(element => {
    wrapTextLines(element)
  })

  if (likeElements) {
    likeElements.forEach(element => {
      element.addEventListener('click', () => {
        event.preventDefault()
      })
    })
  }
})
