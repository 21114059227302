import { ready } from '../../../js/utils/documentReady'
import { getIsSafari } from '../../../js/utils/isSafari'
import { executeOnScroll } from '../../../js/utils/executeOnScroll'

ready(() => {
  const tagGradients = document.querySelectorAll('.tags-gradient')

  const isSafari = getIsSafari()

  if (isSafari) {
    document.querySelector('body').classList.add('is-safari')
  }

  for (let tagGradient of tagGradients) {
    const initTag = (tagGradient) => {
      const mainTags = tagGradient.querySelector('.tags-gradient__svg-inner')

      if (mainTags) {
        const mainTagsRect = mainTags.getBoundingClientRect()

        const svgNS = 'http://www.w3.org/2000/svg'
        const svg = document.createElementNS(svgNS, 'svg')
        svg.id = 'svgMask'
        svg.setAttribute('width', mainTagsRect.width)
        svg.setAttribute('height', mainTagsRect.height)

        const style = document.createElementNS(svgNS, 'style')

        svg.appendChild(style)

        const svgWrapperElements = mainTags.querySelectorAll(
          '.tags-gradient__tag-svg',
        )

        svgWrapperElements.forEach((svgWrapperElement) => {
          const svgWrapperRect = svgWrapperElement.getBoundingClientRect()

          const x = svgWrapperRect.left - mainTagsRect.left
          const y = svgWrapperRect.top - mainTagsRect.top

          const rect = document.createElementNS(svgNS, 'rect')
          rect.setAttribute('x', x)
          rect.setAttribute('y', y)
          rect.setAttribute('width', svgWrapperRect.width)
          rect.setAttribute('height', svgWrapperRect.height)
          rect.setAttribute('fill', 'none')
          rect.setAttribute('class', 'hit-box')
          rect.setAttribute('pointer-events', 'all')
          svg.appendChild(rect)

          const href = svgWrapperElement.getAttribute('href')
          if (href) {
            rect.addEventListener('click', () => {
              if (svgWrapperElement.getAttribute('target') === '_blank') {
                window.open(href, '_blank')
              } else {
                window.location.href = href
              }
            })
          }

          const innerSvg = svgWrapperElement.querySelector('svg')
          if (innerSvg) {
            const clonedSvg = innerSvg.cloneNode(true)
            clonedSvg.classList.add('wrapper-svg')
            clonedSvg.setAttribute('width', svgWrapperRect.width)
            clonedSvg.setAttribute('height', svgWrapperRect.height)
            clonedSvg.setAttribute('x', x)
            clonedSvg.setAttribute('y', y)
            svg.appendChild(clonedSvg)
          }
        })

        const tags = mainTags.querySelectorAll('.tags-gradient__tag')

        tags.forEach((tag) => {
          const tagRect = tag.getBoundingClientRect()
          const spanElement = tag.querySelector('span')
          const spanRect = spanElement.getBoundingClientRect()

          const x = tagRect.left - mainTagsRect.left
          const y = tagRect.top - mainTagsRect.top

          const rect = document.createElementNS(svgNS, 'rect')
          rect.setAttribute('x', x)
          rect.setAttribute('y', y)
          rect.setAttribute('width', tagRect.width)
          rect.setAttribute('height', tagRect.height)

          const computedStyle = window.getComputedStyle(tag)

          rect.setAttribute('rx', computedStyle.borderRadius.split(' ')[0]) // Радиус границ
          rect.setAttribute(
            'ry',
            computedStyle.borderRadius.split(' ')[1] ||
              computedStyle.borderRadius.split(' ')[0],
          ) // Радиус границ

          rect.setAttribute('stroke', computedStyle.borderColor)
          rect.setAttribute('stroke-width', computedStyle.borderWidth)
          rect.setAttribute('fill', 'none')
          rect.setAttribute('class', 'border')

          const hitbox = rect.cloneNode()
          hitbox.setAttribute('stroke', 'none')
          hitbox.setAttribute('class', 'hit-box')
          hitbox.setAttribute('data-title', spanElement.innerHTML)

          hitbox.setAttribute('pointer-events', 'all')

          hitbox.setAttribute('data-href', tag.getAttribute('href'))
          if (tag.getAttribute('target')) {
            hitbox.setAttribute('data-target', tag.getAttribute('target'))
          }

          // const href = tag.getAttribute('href')
          // if (href) {
          //   hitbox.addEventListener('click', () => {
          //     if (tag.getAttribute('target') === '_blank') {
          //       window.open(href, '_blank')
          //     } else {
          //       window.location.href = href
          //     }
          //   })
          // }

          svg.appendChild(hitbox)
          svg.appendChild(rect)

          const textX = spanRect.left - mainTagsRect.left
          const textY = spanRect.top - mainTagsRect.top

          const text = document.createElementNS(svgNS, 'text')
          const tagComputedStyle = window.getComputedStyle(tag)
          text.setAttribute('x', textX)
          text.setAttribute(
            'y',
            textY + parseInt(tagComputedStyle.lineHeight, 10) / 2 + 1,
          )
          text.setAttribute('font-size', tagComputedStyle.fontSize)
          text.setAttribute('line-height', tagComputedStyle.lineHeight)
          text.setAttribute('font-family', tagComputedStyle.fontFamily)
          text.setAttribute('font-weight', tagComputedStyle.fontWeight)
          text.setAttribute('textLength', spanRect.width)
          text.setAttribute('lengthAdjust', 'spacingAndGlyphs')
          text.textContent = tag.textContent

          svg.appendChild(text)
        })

        tagGradient.querySelector('.tags-gradient__tags-svg').appendChild(svg)

        const svgElement = tagGradient.querySelector(
          '.tags-gradient__tags-svg svg',
        )
        const svgString = new XMLSerializer().serializeToString(svgElement)
        const blob = new Blob([svgString], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)
        const customMaskElement = tagGradient.querySelector(
          '.tags-gradient__custom-mask',
        )

        customMaskElement.style.maskImage = `url(${url})`
        customMaskElement.style.webkitMaskImage = `url(${url})`
        customMaskElement.style.height = `${mainTagsRect.height}px`
        customMaskElement.style.width = `${mainTagsRect.width}px`
      }
    }

    executeOnScroll(tagGradient, () => initTag(tagGradient))
  }
})
