export const renderContent = (data, selector) => {
  const contentElement = document.querySelector(selector)
  contentElement.innerHTML = `
    <div class="event-program__list-events">
      ${data.map((item) => `
        <div class="event-program__event">
          <div class="event-program__date">
            <div class="event-program__date-title">${item.date}</div>
          </div>
          <div class="event-program__description">
            <div class="event-program__description-title">${item.title}</div>
            <div class="event-program__description-text">${item.subTitle}</div>
          </div>
        </div>
      `).join('')}
    </div>
  `
}
