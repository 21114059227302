import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const favoriteButton = document.querySelectorAll('.js-posterFavoriteButton')

  favoriteButton.forEach(el => {
    el.addEventListener('click', () => {
      event.preventDefault()
    })
  })
})
