import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('stringDate', function(value) {
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/
    if (!datePattern.test(value)) {
      return false
    }

    const [day, month, year] = value.split('.').map(Number)
    const date = new Date(year, month - 1, day)

    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return false
    }

    const today = new Date()
    const maxPastDate = new Date(today.getFullYear() - 150, today.getMonth(), today.getDate())
    const maxFutureDate = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate())

    if (date < maxPastDate || date > maxFutureDate) {
      return false
    }

    return true
  }, 'Введите корректную дату')
})
