
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { loadImages } from '../../../js/utils/loadImage'

ready(() => {
  const swiperElement = document.querySelector('.js-swiperNews')

  if (!swiperElement) return

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px 75px 0px',
    threshold: 0
  }

  const initSwiper = () => {
    new Swiper('.js-swiperNews', {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: '.js-swiperNewsButtonNext',
        prevEl: '.js-swiperNewsButtonPrev',
        disabledClass: 'swiper-news__button-disabled',
      },
      spaceBetween: 40,
      speed: 500,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      on: {
        init() {
          loadImages(this.slides[this.activeIndex])
          if (this.slides[this.activeIndex + 1]) {
            loadImages(this.slides[this.activeIndex + 1])
          }

          if (this.slides[this.activeIndex + 2]) {
            loadImages(this.slides[this.activeIndex + 2])
          }

          if (this.slides[this.activeIndex + 3]) {
            loadImages(this.slides[this.activeIndex + 3])
          }

          if (this.slides[this.activeIndex - 1]) {
            loadImages(this.slides[this.activeIndex - 1])
          }
        },
        slideChange() {
          loadImages(this.slides[this.activeIndex])
          if (this.slides[this.activeIndex + 1]) {
            loadImages(this.slides[this.activeIndex + 1])
          }
          if (this.slides[this.activeIndex + 2]) {
            loadImages(this.slides[this.activeIndex + 2])
          }

          if (this.slides[this.activeIndex + 3]) {
            loadImages(this.slides[this.activeIndex + 3])
          }
          if (this.slides[this.activeIndex - 1]) {
            loadImages(this.slides[this.activeIndex - 1])
          }
        }
      },
    })
    observer.disconnect()
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        initSwiper()
      }
    })
  }, observerOptions)

  observer.observe(swiperElement)
})
