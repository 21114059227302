import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const burgerElement = document.querySelector('.js-burger')
  const header = document.querySelector('.header')
  const burgetMenuLink = document.querySelectorAll('.burger-menu__link')

  const closeMenu = () => {
    const burgerMenu = document.querySelector('.js-burgerMenu')

    burgerMenu.classList.remove('burger-menu_opened')
    header.classList.remove('header_opened')
    burgerElement.classList.remove('burger_is_active')
    document.body.classList.remove('body-overflow-hidden')
  }

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  burgerElement?.addEventListener(isTouchDevice ? 'touchend' : 'click', () => {
    const burgerMenu = document.querySelector('.js-burgerMenu')

    if (burgerElement && burgerMenu) {
      if (burgerElement.classList.contains('burger_is_active')) {
        closeMenu()
      } else {
        burgerElement.classList.add('burger_is_active')
        burgerMenu.classList.add('burger-menu_opened')
        header.classList.add('header_opened')
        document.body.classList.add('body-overflow-hidden')
      }
    }
  })

  if (burgetMenuLink) {
    burgetMenuLink.forEach((item) => {
      item.addEventListener('click', () => {
        closeMenu()
      })
    })
  }
})
