import { ready } from '../utils/documentReady'

ready(() => {
  const sendEvent = (type, data, name) => {
    events.push({ type, data, name })
    if (observeEnabled) {
      console.log(`Event sent: ${type}`, { data, name })
    }

    window.ym(97760082, 'reachGoal', name, { [name]: data })
  }

  const events = []
  let observeEnabled = false

  const links = document.querySelectorAll('.js-analyticsClickLink')
  const clicks = document.querySelectorAll('.js-analyticsClick')
  const searches = document.querySelectorAll('.js-analyticsSearch')
  const views = document.querySelectorAll('.js-analyticsView')

  links.forEach(link => {
    if (link.tagName.toLowerCase() === 'a') {
      link.addEventListener('click', (event) => {
        const url = window.location.href
        const urlTo = event.currentTarget.href
        const name = link.getAttribute('data-name')
        sendEvent('click_link', { 'url': url, 'url_to': urlTo }, name)
      })
    } else {
      link.addEventListener('click', (event) => {
        const target = event.target.closest('a')
        if (target && link.contains(target)) {
          const url = window.location.href
          const urlTo = target.href
          const name = link.getAttribute('data-name')
          sendEvent('click_link', { 'url': url, 'url_to': urlTo }, name)
        }
      })
    }
  })

  clicks.forEach(click => {
    click.addEventListener('click', (event) => {
      const url = window.location.href
      const name = event.currentTarget.getAttribute('data-name')
      sendEvent('click', { 'url': url }, name)
    })
  })

  searches.forEach(search => {
    search.addEventListener('search', (event) => {
      const query = event.target.value
      const name = event.target.getAttribute('data-name')
      sendEvent('search', { 'query': query }, name)
    })
  })

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const url = window.location.href
        const name = entry.target.getAttribute('data-name')
        sendEvent('view', { 'url': url }, name)
        observer.unobserve(entry.target)
      }
    })
  })

  views.forEach(view => {
    observer.observe(view)
  })

  window.analytics = {
    observe() {
      observeEnabled = true
    },
    eventsHappened() {
      console.log(events)
    },
    showEvents() {
      console.log('Links:', links)
      console.log('Clicks:', clicks)
      console.log('Searches:', searches)
      console.log('Views:', views)
    },
    send: sendEvent
  }
})
