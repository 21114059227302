import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('stringPhone', function(value) {
    let digitCount = 0

    for (let i = 0; i < value.length; i++) {
      if (!isNaN(value[i]) && value[i] !== ' ') {
        digitCount++
      }
    }

    return digitCount === 0 || digitCount === 11
  }, 'Введите корректный номер телефона')
})
