import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const tabContainers = document.querySelectorAll('.personal-account-tabs')

  tabContainers.forEach(container => {
    const tabs = container.querySelectorAll('.personal-account-tabs__tab')
    const name = container.getAttribute('name')
    const contents = document.querySelectorAll(`.tab-content[data-name="${name}"]`)

    function hideAllContents() {
      contents.forEach(content => {
        content.classList.add('tab-content_hidden')
      })
    }

    function showActiveTabContent() {
      const activeTab = container.querySelector('.personal-account-tabs__tab_active')
      if (activeTab) {
        const tabId = activeTab.getAttribute('data-id')
        const activeContents = document.querySelectorAll(`${tabId}[data-name="${name}"]`)

        for(let i of activeContents) {
          i.classList.remove('tab-content_hidden')
        }
      }
    }

    function scrollToActiveTab() {
      const firstTab = container.querySelector('.personal-account-tabs__tab')
      const activeTab = container.querySelector('.personal-account-tabs__tab_active')

      if (firstTab && activeTab) {
        const firstTabLeft = firstTab.offsetLeft
        const activeTabLeft = activeTab.offsetLeft

        const scrollAmount = activeTabLeft - firstTabLeft

        container.parentElement.scrollLeft += scrollAmount
      }
    }

    hideAllContents()
    showActiveTabContent()
    setTimeout(scrollToActiveTab, 0)

    tabs.forEach(tab => {
      tab.addEventListener('click', function() {
        tabs.forEach(t => t.classList.remove('personal-account-tabs__tab_active'))
        this.classList.add('personal-account-tabs__tab_active')

        hideAllContents()
        showActiveTabContent()
      })
    })
  })
})
