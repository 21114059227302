import { ready } from '../../../js/utils/documentReady'
import SimpleBar from 'simplebar'

ready(() => {
  const timelineElement = document.querySelector('.timeline3d')

  if (timelineElement) {
    const body = document.querySelector('body')

    window.openTimelinePopup = function (id) {
      const popupHtml = `
        <div class="timeline3d-popup">
          <div class="timeline3d-popup__bg"></div>
          <div class="timeline3d-popup__inner">
            <div class="timeline3d-popup__close"></div>
            <div class="timeline3d-popup__content">
              <div class="timeline3d-popup__content-article">
                <div class="timeline3d-popup__content-article-inner">
                  
                </div>
                <div class="timeline3d-popup__content-article-loader">
                  <div class="body-loader">
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="8" viewBox="0 0 70 8" fill="none">
                      <path d="M-1.74847e-07 3.99997L3.99998 7.99995L65.9996 8L69.9996 4.00003L65.9996 4.57525e-05L3.99998 2.68618e-06L-1.74847e-07 3.99997Z" fill="#A3B9DD"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="8" viewBox="0 0 70 8" fill="none">
                      <path d="M-1.74847e-07 3.99997L3.99998 7.99995L65.9996 8L69.9996 4.00003L65.9996 4.57525e-05L3.99998 2.68618e-06L-1.74847e-07 3.99997Z" fill="#A3B9DD"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="8" viewBox="0 0 70 8" fill="none">
                      <path d="M-1.74847e-07 3.99997L3.99998 7.99995L65.9996 8L69.9996 4.00003L65.9996 4.57525e-05L3.99998 2.68618e-06L-1.74847e-07 3.99997Z" fill="#A3B9DD"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="8" viewBox="0 0 70 8" fill="none">
                      <path d="M-1.74847e-07 3.99997L3.99998 7.99995L65.9996 8L69.9996 4.00003L65.9996 4.57525e-05L3.99998 2.68618e-06L-1.74847e-07 3.99997Z" fill="#A3B9DD"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="8" viewBox="0 0 70 8" fill="none">
                      <path d="M-1.74847e-07 3.99997L3.99998 7.99995L65.9996 8L69.9996 4.00003L65.9996 4.57525e-05L3.99998 2.68618e-06L-1.74847e-07 3.99997Z" fill="#A3B9DD"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`

      document.body.insertAdjacentHTML('beforeend', popupHtml)

      const contentElement = document.querySelector('.timeline3d-popup__content-article-inner')
      const scrollElement = document.querySelector('.timeline3d-popup__content-article')
      const popupElement = document.querySelector('.timeline3d-popup')

      new SimpleBar(scrollElement)

      setTimeout(() => {
        document.querySelector('.timeline3d-popup')?.classList.add('timeline3d-popup_visible')
        body.classList.add('body-overflow-hidden')
      }, 1)

      document.querySelector('.timeline3d-popup__close').addEventListener('click', function () {
        closePopup()
      })

      document.querySelector('.timeline3d-popup__bg').addEventListener('click', function () {
        closePopup()
      })

      window.BX.ajax.runComponentAction('project:strategy.element', 'getElement', {
        mode: 'class',
        data: {
          id: id,
        },
      }).then(function (response) {
        popupElement.classList.add('timeline3d-popup_loaded')

        if (response.status === 'success') {
          contentElement.innerHTML = response.data.html
          window.initSliderInPopup()
          window.initMainVideo()
          window.initVideoPageInModal()
          window.initImagePageInModal()
        } else {
          contentElement.innerHTML = '<p>Error loading content</p>'
        }

      }, function (error) {
        popupElement.classList.add('timeline3d-popup_loaded')
        contentElement.innerHTML = '<p>Error loading content</p>'
        console.error('Error fetching content:', error)
      })

      function closePopup() {
        const popup = document.querySelector('.timeline3d-popup')
        if (popup) {
          popup.classList.remove('timeline3d-popup_visible')
          body.classList.remove('body-overflow-hidden')

          setTimeout(() => {
            popup.remove()
          }, 300)
        }
      }
    }
  }
})
