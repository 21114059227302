import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import { ready } from '../../../js/utils/documentReady'

const masonryInstances = []

const addMasonryInstances = () => {
  const eventsMasonryElements = document.querySelectorAll('.js-eventsMasonry')

  eventsMasonryElements.forEach((eventsMasonry) => {
    var msnry = new Masonry(eventsMasonry, {
      itemSelector: '.grid-events-masonry-item',
      gutter: '.grid-events-masonry-gutter',
      percentPosition: true,
      // originLeft: true,
      // horizontalOrder: true
    })

    imagesLoaded(eventsMasonry).on('progress', function() {
      msnry.layout()
    })

    masonryInstances.push(msnry)
  })
}

window.masonryAppend = (newsElements, id) => {
  masonryInstances.forEach(msnry => {
    if (id) {
      if (msnry.$element[0].id === id) {
        msnry.remove(msnry.getItemElements())
        msnry.appended(newsElements)
        imagesLoaded(msnry.element).on('progress', function() {
          msnry.layout()
        })
      }
    } else {
      msnry.appended(newsElements)
      imagesLoaded(msnry.element).on('progress', function() {
        msnry.layout()
      })
    }
  })
}

window.masonryInit = (newsElements, id) => {
  masonryInstances.forEach(msnry => {
    if (id) {
      if (msnry.$element[0].id === id) {
        msnry.remove(msnry.getItemElements())
        msnry.appended(newsElements)
        imagesLoaded(msnry.element).on('progress', function() {
          msnry.layout()
        })
      }
    } else {
      msnry.remove(msnry.getItemElements())
      msnry.appended(newsElements)
      imagesLoaded(msnry.element).on('progress', function() {
        msnry.layout()
      })
    }
  })
}

window.masonryInitWithoutImage = (newsElements) => {
  masonryInstances.forEach(msnry => {
    msnry.remove(msnry.getItemElements())
    msnry.appended(newsElements)
    msnry.layout()
  })
}

window.addMasonryInstances = addMasonryInstances

ready(() => {
  addMasonryInstances()
})
