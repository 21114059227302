import { ready } from '../../../js/utils/documentReady'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  function updateScale() {
    const banners = document.querySelectorAll('.banner-with-mask__descriptions')
    const width = window.innerWidth

    let scale = 1

    if (width >= 320 && width < 768) {
      scale = 1 + (width - 320) / 320 * 0.65
    } else if (width >= 768 && width < 1280) {
      scale = 1 + (width - 768) / 512 * 0.65
    } else if (width >= 1280 && width < 1900) {
      scale = 1
    } else if (width >= 1900) {
      scale = 1
    }

    banners.forEach(banner => {
      const { isMobile } = getSize()

      banner.style.transform = `scale(${scale})`

      if (isMobile) {
        const contentElement = banner.closest('.banner-with-mask__content')
        const imageElement = contentElement.querySelector('.banner-with-mask__image-wrapper')

        const contentHeight = contentElement.offsetHeight
        const bannerHeight = banner.offsetHeight

        const maxImageHeight = contentHeight - bannerHeight* scale

        imageElement.style.maxHeight = `${maxImageHeight - ( 16) * scale}px`
      }
    })
  }

  updateScale()

  window.addEventListener('resize', updateScale)
})
