import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const likeElements = document.querySelectorAll('.js-eventTableRowLikeButton')

  if (likeElements) {
    likeElements.forEach(element => {
      element.addEventListener('click', () => {
        event.preventDefault()
      })
    })
  }
})
