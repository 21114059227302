export function executeOnScroll(target, callback) {
  let targetElement

  if (typeof target === 'string') {
    targetElement = document.querySelector(target)
  } else if (target instanceof Element) {
    targetElement = target
  }

  if (targetElement) {
    const observerOptions = {
      root: null,
      rootMargin: '0px 0px 150px 0px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          callback()
          observer.unobserve(entry.target)
        }
      })
    }, observerOptions)

    observer.observe(targetElement)
  }
}
