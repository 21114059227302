/* swiper */
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  new Swiper('.js-swiperMaskBanner', {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.js-swiperButtonNext',
      prevEl: '.js-swiperButtonPrev',
      disabledClass: 'swiper-mask-banner__button-disabled',
    },
    pagination: {
      el: '.js-swiperPagination',
      clickable: true,
      bulletClass: 'swiper-mask-banner__pagination-bullet',
      bulletActiveClass: 'swiper-mask-banner__pagination-bullet-active',
    },
    on: {
      init() {
        this.update()
      }
    }
  })
})
