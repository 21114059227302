export function structureData(data) {
  var structuredData = {}

  data.forEach(function(item) {
    var keys = item.name.split('.')
    var lastKey = keys.pop()
    var currentLevel = structuredData

    keys.forEach(function(key) {
      if (!currentLevel[key]) {
        currentLevel[key] = {}
      }
      currentLevel = currentLevel[key]
    })

    currentLevel[lastKey] = item.value
  })

  return structuredData
}
