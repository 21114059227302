export function animate(_ref) {
  const timing = _ref.timing,
    draw = _ref.draw,
    duration = _ref.duration
  const start = performance.now()
  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration
    if (timeFraction > 1) timeFraction = 1
    const progress = timing(timeFraction)
    draw(progress)
    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    }
  })
}
