export const ready = (fn) => {
  const onReady = () => {
    if (document.fonts && document.fonts.ready) {
      document.fonts.ready.then(fn)
    } else {
      fn()
    }
  }

  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    onReady()
  } else {
    document.addEventListener('DOMContentLoaded', onReady)
  }
}

window.ready = ready
