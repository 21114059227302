import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

ready(() => {
  const header = document.querySelector('.header')
  const linkMore = document.querySelector('.js-headerLinkMore')
  const dropdownMore = document.querySelector('.js-headerDropdownMore')
  const dropdownMoreInner = document.querySelector('.js-headerDropdownMoreInner')
  const headerLinksContainer = document.querySelector('.header__links')
  const innerContainer = headerLinksContainer.querySelector('.js-headerDropdownMoreInner')
  const arrowIcon = document.querySelector('.js-headerArrowIcon')
  const { isDesktop, isTablet } = getSize()
  const isMain3 = document.querySelector('body.main-3')

  if (!isTablet) {
    const widthLinksHeader = () => {
      let width = isDesktop ? -52 : -32

      headerLinksContainer.querySelectorAll('.header__link').forEach(element => {
        const isInnerMore = element.classList.contains('js-isInnerMore')
        if (!isInnerMore) {
          const gap = isDesktop ? 52 : 32
          const elementWidth = element.clientWidth
          width += elementWidth + gap
        }
      })

      return width
    }

    const transferElementsToInnerContainer = () => {
      let width = widthLinksHeader()
      const widthContainer = headerLinksContainer.clientWidth

      const elements = headerLinksContainer.querySelectorAll('.header__link')

      Array.from(elements).reverse().forEach(element => {
        if (width > widthContainer) {
          const searchElement = element.classList.contains('header__link-search')
          const moreElement = element.classList.contains('js-headerLinkMore')

          if (!searchElement && !moreElement) {
            const firstChild = innerContainer.firstChild
            innerContainer.insertBefore(element, firstChild)
            element.classList.add('js-isInnerMore')
            width = widthLinksHeader()
          }
        }
      })
    }

    if (headerLinksContainer) {
      const widthContainer = headerLinksContainer.clientWidth
      const width = widthLinksHeader()

      if (width > widthContainer) {
        transferElementsToInnerContainer()
      }
    }
  }

  if (linkMore) {
    linkMore.addEventListener('mouseenter', () => {
      dropdownMore.setAttribute('data-active', 'true')
      dropdownMore.classList.add('header__dropdown-more-wrapper-active')
      dropdownMoreInner.classList.add('header__dropdown-more-active')
      arrowIcon.classList.add('header__link-arrow-icon-rotate')
    })

    linkMore.addEventListener('mouseleave', () => {
      dropdownMore.classList.remove('header__dropdown-more-wrapper-active')
      dropdownMoreInner.classList.remove('header__dropdown-more-active')
      arrowIcon.classList.remove('header__link-arrow-icon-rotate')
    })

    dropdownMore.addEventListener('mouseenter', () => {
      if (dropdownMore.getAttribute('data-active') === 'true') {
        dropdownMore.classList.add('header__dropdown-more-wrapper-active')
        dropdownMoreInner.classList.add('header__dropdown-more-active')
        arrowIcon.classList.add('header__link-arrow-icon-rotate')
      }
    })

    dropdownMore.addEventListener('mouseleave', () => {
      dropdownMore.setAttribute('data-active', 'false')
      dropdownMore.classList.remove('header__dropdown-more-wrapper-active')
      dropdownMoreInner.classList.remove('header__dropdown-more-active')
      arrowIcon.classList.remove('header__link-arrow-icon-rotate')
    })
  }

  if (!isMain3 && header) {
    const checkScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      if (scrollTop > 10) {
        header.classList.add('header_scrolled')
      } else {
        header.classList.remove('header_scrolled')
      }
    }

    const checkScrollWithDelay = () => {
      setTimeout(checkScroll, 100)
    }

    window.addEventListener('scroll', checkScroll)
    document.addEventListener('DOMContentLoaded', checkScrollWithDelay)
    window.addEventListener('load', checkScrollWithDelay)
    window.addEventListener('pageshow', checkScrollWithDelay)
  }

  const authElement = document.querySelector('.header__auth')

  if (authElement) {
    function toggleAuthOptions(event) {
      event.stopPropagation() // Останавливаем всплытие события

      if (authElement.classList.contains('header__auth_open')) {
        closeAuthOptions()
      } else {
        openAuthOptions()
      }
    }

    function openAuthOptions() {
      authElement.classList.add('header__auth_open')
      window.addEventListener('click', handleClickOutside)
    }

    function closeAuthOptions() {
      authElement.classList.remove('header__auth_open')
      window.removeEventListener('click', handleClickOutside)
    }

    function handleClickOutside(event) {
      if (!authElement.contains(event.target)) {
        closeAuthOptions()
      }
    }

    authElement.addEventListener('click', toggleAuthOptions)

    const optionsElement = document.querySelector('.header__auth-options')

    optionsElement.addEventListener('click', (e) => e.stopPropagation())

    const optionElements = document.querySelectorAll('.js-header-tab')

    for (let i of optionElements) {
      i.addEventListener('click', () => {
        const id = i.dataset.id

        if (id) {
          const tab = document.querySelector(`.personal-account-tabs__tab[data-id="${id}"]`)
          if (tab) {
            tab.click()
          }
          const burgerMenu = document.querySelector('.burger_is_active')

          if (burgerMenu) {
            burgerMenu.click()
          }
        }
      })
    }
  }
})
