import { styleMap } from './style-map'
import {renderMapContent} from './render-map-content/render-map-content'
import {initMapOverlay} from './map-overlay'
import {styleLoader} from './style-loader'

export const renderMap = ({ ref }) => {
  const { config } = ref

  setTimeout(() => {
    window.ymaps3?.ready.then(() => {

      const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapLayer, YMapFeatureDataSource } = window.ymaps3

      ref.map = new YMap(document.getElementById('ymap'), {
        location: {
          center: [config.map.center[1], config.map.center[0]],
          zoom: config.map.zoom,
        },
        zoomRange: {
          max: config.map.maxZoom,
          min: config.map.minZoom,
        },
      })
      const layer = new YMapDefaultSchemeLayer({ customization: styleMap })

      ref.map.addChild(new YMapDefaultFeaturesLayer())
      ref.map.addChild(layer)
      ref.map.addChild(new YMapFeatureDataSource({id: 'my-source'}))
      ref.map.addChild(new YMapLayer({source: 'my-source', type: 'markers', zIndex: 1800}))

      if(ref.defaultData) {
        renderMapContent({ref: ref}, ref.defaultData)
      } else {
        renderMapContent({ref: ref})
      }

      initMapOverlay()

      setTimeout(() => {
        const mapElement = document.querySelector('.map3')
        mapElement.classList.add('map3_view')
      }, 2000)
    })
  }, 1)

  styleLoader()
  return `
    <div class="map3__wrapper">
      <div class="map3__preload">
        <div class="map3__preload-item">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
        </div>
      </div>
      <div class="map3__overlay js-mapOverlay"></div>
      <div class="map3__container js-ymap" id='ymap'></div>
    </div>`
}
