import { ready } from '../../../js/utils/documentReady'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  const parallaxNodeList = document.querySelectorAll('.js-parallax, .js-observe')

  if (parallaxNodeList.length) {
    const parallaxElements = {}
    const selectorsData = {}
    const { isDesktop, isNetbook } = getSize()

    parallaxNodeList.forEach((element) => {
      const name = element.getAttribute('data-name')
      const selectors = element.getAttribute('data-selectors')
      const type = element.getAttribute('data-type')
      const selectorsElements = document.querySelectorAll(selectors)

      parallaxElements[name] = {
        element: element,
        selectors: selectorsElements,
        isView: false,
        type: type,
      }

      selectorsElements.forEach((selectorElement, index) => {
        const key = `${name}-${index}`
        selectorsData[key] = {
          element: selectorElement,
          isView: false,
        }
      })
    })

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const targetElement = entry.target
        const key = Object.keys(selectorsData).find(
          key => selectorsData[key].element === targetElement
        )

        if (key) {
          selectorsData[key].isView = entry.isIntersecting
        }
      })

      updateParallaxElementsView()
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    Object.values(selectorsData).forEach(({ element }) => {
      observer.observe(element)
    })

    const updateParallaxElementsView = () => {
      Object.keys(parallaxElements).forEach(name => {
        const elementData = parallaxElements[name]
        elementData.isView = Array.from(elementData.selectors).some(selectorElement =>
          Object.values(selectorsData).some(data =>
            data.element === selectorElement && data.isView
          )
        )
      })
    }

    let viewportHeight = window.innerHeight

    window.addEventListener('resize', () => {
      viewportHeight = window.innerHeight
    })

    function onScroll() {
      Object.entries(parallaxElements).forEach(([, parallaxElement]) => {
        if (parallaxElement.isView) {

          if(parallaxElement.type === 'custom-1') {
            const element = parallaxElement.element
            const decorElement = document.querySelector('.mask-decor-banner__header-description-decor')
            const rect = element.getBoundingClientRect()
            const decorRect = element.getBoundingClientRect()
            const distanceFromBottom = window.innerHeight - rect.bottom

            if(distanceFromBottom > -50) {
              element.classList.add('parallax-scrolled')
            } else {
              element.classList.remove('parallax-scrolled')
            }

            if (decorRect.top <= 0) {
              if (!element.classList.contains('parallax-fixed')) {
                const rect = decorElement.getBoundingClientRect()

                decorElement.style.top = rect.top + 'px'

                element.classList.add('parallax-fixed')
              }
            } else {
              element.classList.remove('parallax-fixed')

              decorElement.style.top = ''
            }
          } else if (parallaxElement.type === 'custom-2') {
            const element = parallaxElement.element
            const rect = element.parentElement.getBoundingClientRect()
            const parallaxOffset = parseFloat(element.dataset.offset || '0.5')
            const parallaxOffsetEnd = parseFloat(element.dataset.offsetEnd || parallaxOffset)
            const elementMiddle = rect.top + rect.height / 2
            const middlePercentage = (-(elementMiddle / viewportHeight) * 100 + 50)
            const middlePercentageAbs = Math.abs(middlePercentage)
            const gap = middlePercentageAbs - 7
            if (gap < 0) {
              element.style.transform = `translateY(${0}%)`
            } else {
              if(middlePercentage > 0) {
                if(isDesktop || isNetbook) {
                  element.style.transform = `translateY(${gap * parallaxOffsetEnd}%)`
                }
              } else {
                element.style.transform = `translateY(${gap * parallaxOffset}%)`
              }
            }
          } else {
            const element = parallaxElement.element
            const rect = element.parentElement.getBoundingClientRect()
            const parallaxOffset = parseFloat(element.dataset.offset || '0.5')
            const isReverse = element.dataset.isReverse
            const elementMiddle = rect.top + rect.height / 2
            const middlePercentage = (-(elementMiddle / viewportHeight) * 100 + 50) * parallaxOffset

            if(isReverse) {
              element.style.transform = `translateY(${-middlePercentage}%)`
            } else {
              element.style.transform = `translateY(${middlePercentage}%)`
            }
          }
        }
      })
    }

    let lastScrollY = window.scrollY
    function handleScroll() {
      const currentScrollY = window.scrollY
      if (Math.abs(currentScrollY - lastScrollY) > 2) {
        requestAnimationFrame(onScroll)
        lastScrollY = currentScrollY
      }
    }

    window.addEventListener('scroll', handleScroll)
  }
})
