export const addClassRelativeOS = () => {
  const userAgent = window.navigator.userAgent
  let os = ''

  if (userAgent.includes('Windows')) {
    os = 'windows'
  } else if (userAgent.includes('Macintosh')) {
    os = 'mac'
  } else if (userAgent.includes('Linux')) {
    os = 'linux'
  } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    os = 'ios'
  } else if (userAgent.includes('Android')) {
    os = 'android'
  }

  if (os) {
    document.body.classList.add(`os-${os}`)
  }
}

addClassRelativeOS()
