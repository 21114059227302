import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { loadImages } from '../../../js/utils/loadImage'

const initSwiper = () => {
  new Swiper('.js-swiperInternationalFormats', {
    modules: [Navigation, Pagination, Autoplay],
    navigation: {
      nextEl: '.js-swiperInternationalFormatsButtonNext',
      prevEl: '.js-swiperInternationalFormatsButtonPrev',
      disabledClass: 'swiper-special-projects__button-disabled',
    },
    pagination: {
      el: '.js-swiperInternationalFormatsPagination',
      bulletClass: 'swiper-special-projects__pagination-bullet',
      bulletActiveClass: 'swiper-special-projects__pagination-bullet-active',
      clickable: true,
    },
    spaceBetween: 40,
    slidesPerView: 1,
    autoHeight: true,
    loop: true,
    speed: 500,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: true,
    // },
    on: {
      init() {
        const swiper = this
        const slidesCount = swiper.slides.length

        if (slidesCount <= 1) {
          const navigationElement = swiper.el.closest('.swiper-special-projects')?.querySelector('.swiper-special-projects__navigation')
          if(navigationElement) {
            navigationElement.style.display = 'none'
          }
        }

        loadImages(this.slides[this.activeIndex])
        if (this.slides[this.activeIndex + 1]) {
          loadImages(this.slides[this.activeIndex + 1])
        }

        if (this.slides[this.activeIndex - 1]) {
          loadImages(this.slides[this.activeIndex - 1])
        }
      },
      slideChange() {
        loadImages(this.slides[this.activeIndex])
        if (this.slides[this.activeIndex + 1]) {
          loadImages(this.slides[this.activeIndex + 1])
        }
        if (this.slides[this.activeIndex - 1]) {
          loadImages(this.slides[this.activeIndex - 1])
        }
      }
    },
  })
}

const observeSwiper = () => {
  const swiperElement = document.querySelector('.js-swiperInternationalFormats')

  if (!swiperElement) return

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px 75px 0px',
    threshold: 0
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        initSwiper()
        observer.unobserve(swiperElement)
      }
    })
  }, observerOptions)

  observer.observe(swiperElement)
}

window.initSwiperInternationalFormats = () => observeSwiper()

ready(() => {
  observeSwiper()
})
