import { ready } from '../../../js/utils/documentReady'
import { insertSoftHyphens } from '../../../js/utils/insertSoftHyphens'

ready(() => {
  const likeElements = document.querySelectorAll('.js-cardEventLikeButton')
  const titles = document.querySelectorAll('.js-wordWrap')

  if (likeElements) {
    likeElements.forEach(element => {
      element.addEventListener('click', () => {
        event.preventDefault()
      })
    })
  }

  if (titles) {
    titles.forEach(element => {
      const text = element.textContent || element.innerText
      element.innerHTML = insertSoftHyphens(text)
    })
  }
})
