import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const images = document.querySelectorAll('.book__img')
  const picture = document.querySelectorAll('.book__pic')
  const wrapBooks = document.querySelectorAll('.book__main-wrapper')
  const booksWrappers = document.querySelectorAll('.books-list__wrapper')
  const introPreview = document.querySelectorAll('.article-books__intro-head')
  const header = document.querySelector('header')
  
  const observerOptions = {
    root: null,
    rootMargin: '0px', // Без отступов
    threshold: 0.3 // Срабатывание при пересечении 50% элемента
  }
  
  const observerOptions2 = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  }
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const attributeEl = entry.target.getAttribute('data-num')
      if (!attributeEl) return // пропуск если атрибут отсутствует

      if (entry.isIntersecting) {
        // удаляем класс active у всех картинок и блоков с текстом
        picture.forEach((pic) => pic.classList.remove('active'))
        wrapBooks.forEach((bookEl) => bookEl.classList.remove('active'))

        // добавляем класс active к текущей картинке
        const currentElIndex = Array.from(wrapBooks).indexOf(entry.target) // определяем индекс текущего элемента
        if (currentElIndex !== -1) {
          // добавляем класс active к текущей картинке
          if (picture[currentElIndex]) {
            picture[currentElIndex].classList.add('active')
          }

          // Добавляем класс active к текущему тексту
          wrapBooks[currentElIndex].classList.add('active')
        }
        
        // обновление текст номера книги
        images.forEach((imageEl) => {
          const numbers = imageEl.querySelectorAll('.book__number')
          numbers.forEach((el) => {
            el.innerText = attributeEl
          })
        })
      }
    })
  }, observerOptions)

  const observerBookWraps = new IntersectionObserver((entries) => {
    let anyIntersecting = false
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        anyIntersecting = true
      }
    })
    if (header) {
      header.style.display = anyIntersecting ? 'flex' : 'none'
    }
  }, observerOptions2)

  introPreview.forEach((el) => observerBookWraps.observe(el))
  wrapBooks.forEach((book) => observer.observe(book))
  
  function handleResize() {
    const windowWidth = window.innerWidth

    if (windowWidth <= 1279) {
      observer.disconnect()
    } else if (windowWidth <= 767) {
      observerBookWraps.disconnect()
    } else {
      wrapBooks.forEach((book) => observer.observe(book))
      booksWrappers.forEach((el) => observerBookWraps.observe(el))
    }
  }
  
  handleResize()
  window.addEventListener('resize', handleResize)
})
