/* simple-bar */
import SimpleBar from 'simplebar'

document.addEventListener('DOMContentLoaded', function () {
  const simpleBars = document.querySelectorAll('[data-simplebar]')

  simpleBars.forEach(function(simpleBarElement) {
    const simpleBar = new SimpleBar(simpleBarElement, { autoHide: false })
    const simpleBarContent = simpleBar.getContentElement()

    let isDown = false
    let startX
    let scrollLeft

    simpleBarContent.addEventListener('mousedown', (e) => {
      if(simpleBarContent.scrollWidth > simpleBarContent.clientWidth) {
        isDown = true
        startX = e.pageX - simpleBarContent.offsetLeft
        scrollLeft = simpleBarContent.parentElement.scrollLeft
        simpleBarElement.classList.add('simple-bar__active')
      }
    })

    simpleBarContent.addEventListener('mouseleave', () => {
      isDown = false
      simpleBarElement.classList.remove('simple-bar__active')
    })

    simpleBarContent.addEventListener('mouseup', () => {
      isDown = false
      simpleBarElement.classList.remove('simple-bar__active')
    })

    simpleBarContent.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - simpleBarContent.parentElement.offsetLeft
      const walk = (x - startX) * 1.2
      simpleBarContent.parentElement.scrollLeft = scrollLeft - walk
    })
  })
})
