export function animateNumber(element, start, end, duration = 1100) {
  const startTimestamp = performance.now()
  const range = end - start

  function update(timestamp) {
    const elapsed = timestamp - startTimestamp
    const progress = Math.min(elapsed / duration, 1) // Прогресс от 0 до 1
    const currentValue = Math.floor(start + range * progress) // Округляем до целого числа

    element.textContent = currentValue // Устанавливаем текущее значение

    if (progress < 1) {
      requestAnimationFrame(update) // Запрашиваем следующий кадр
    }
  }

  requestAnimationFrame(update) // Начинаем анимацию
}
