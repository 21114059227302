import { ready } from '../../utils/documentReady'

ready(() => {
  const galleryBlock = document.querySelectorAll('.js-animate-images')

  if (galleryBlock) {
    galleryBlock.forEach((el) => {
      const pictures = el.querySelectorAll('picture')
      let currentPicture = 1
      let intervalId

      function animateImages() {
        pictures[currentPicture].classList.add('animated')

        pictures.forEach((el, index) => {
          if (index !== currentPicture) {
            el.classList.remove('animated')
          }
        })
        currentPicture = (currentPicture + 1) % pictures.length
      }

      function startAnimation() {
        if (!intervalId) {
          animateImages()
          intervalId = setInterval(animateImages, 2000)
        }
      }

      function stopAnimation() {
        if (intervalId) {
          clearInterval(intervalId)
          intervalId = null
        }
      }

      function checkWindowSize() {
        const screenWidth = window.innerWidth
        if (screenWidth <= 767) {
          stopAnimation()
          pictures.forEach((el) => {
            el.classList.add('unanimated')
            el.classList.remove('animated')
          })
        } else {
          startAnimation()
        }
      }

      checkWindowSize()

      window.addEventListener('resize', checkWindowSize)

      window.addEventListener('unload', () => {
        clearInterval(intervalId)
      })
    })
  }
})
