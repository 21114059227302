import {getSize} from '../../../../../js/utils/breakpoints'

export const renderPanel = ({ ref }) => {
  ref.mapElement.addEventListener('initialMap', () => {
    const {  map } = ref
    const center = map.center
    const plusButton = document.querySelector('.map-panel__plus')
    const minusButton = document.querySelector('.map-panel__minus')
    const navigationButton = document.querySelector('.map-panel__navigation')
    const fullScreenButton = document.querySelector('.map-panel__full-screen')

    let previousZoom = map.zoom

    fullScreenButton.addEventListener('click', () => {
      const { isMobile } = getSize()
      const body = document.body

      if(body.classList.contains('body-full-map')) {
        body.classList.remove('body-full-map')
      } else {
        body.classList.add('body-full-map')
      }

      if(isMobile) {
        if(ref.panel) {
          ref.panel.setActive({active: false})
        }
      }
    })

    const updateZoomButtonsState = () => {
      const currentZoom = map.zoom

      if (currentZoom === previousZoom) return
      previousZoom = currentZoom

      if(ref.panel) {
        ref.panel.setActive({active: false})
      }

      if (currentZoom >= map.zoomRange.max) {
        plusButton.classList.add('map-panel__item_disable')
      } else {
        plusButton.classList.remove('map-panel__item_disable')
      }
      if (currentZoom <= map.zoomRange.min) {
        minusButton.classList.add('map-panel__item_disable')
      } else {
        minusButton.classList.remove('map-panel__item_disable')
      }
    }

    plusButton.addEventListener('click', () => {
      map.setLocation({zoom: map.zoom + 1})
      // updateZoomButtonsState()
    })

    minusButton.addEventListener('click', () => {
      map.setLocation({zoom: map.zoom - 1})
      // updateZoomButtonsState()
    })

    navigationButton.addEventListener('click', () => {
      map.setLocation({center: center})
    })

    updateZoomButtonsState()

    const { YMapListener } = window.ymaps3
    const mapListener = new YMapListener({
      layer: 'any',
      onUpdate: updateZoomButtonsState,
      onActionStart: ({type}) => {
        if(type === 'drag') {
          if(ref.panel) {
            ref.panel.setActive({active: false})
          }
        }
        // if(type === 'scrollZoom') {
        //   updateZoomButtonsState()
        // }
      }
    })

    map.addChild(mapListener)

    const panelElement = document.querySelector('.map-panel')
    const widthPanel = panelElement.clientWidth
    ref.marginController.setMargins('panel', [0,0,0, widthPanel + 20])
  })

  return `
    <div class="map-panel">
      <div class="map-panel__full-screen map-panel__item">
        <div class="map-panel__full-screen-icon"></div>
      </div>
      
      <div class="map-panel__bottom-wrapper">
        <div class="map-panel__control-zoom">
          <div class="map-panel__item map-panel__plus">
            <div class="map-panel__plus-icon"></div>
          </div>
        
          <div class="map-panel__item map-panel__minus">
            <div class="map-panel__minus-icon"></div>
          </div>
        </div>
         
        <div class="map-panel__item map-panel__navigation">
          <div class="map-panel__navigation-icon"></div>
        </div>
      </div>
    </div>
  `
}
