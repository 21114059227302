import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const searchOpenElement = document.querySelector('.js-searchOpen')
  const searchCloseElement = document.querySelector('.js-searchClose')
  const container = searchOpenElement?.closest('.search')

  if(searchOpenElement && searchCloseElement && container) {
    searchOpenElement.addEventListener('click', () => {
      container.classList.add('search_opened')
    })

    searchCloseElement.addEventListener('click', () => {
      container.classList.remove('search_opened')
    })
  }
})
