import Hls from 'hls.js'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const playBoxVideo = document.querySelector('.js-playBoxVideo')

  if (playBoxVideo) {
    playBoxVideo.addEventListener('click', () => {
      playBoxVideo.classList.add('is-open')
      const videoContainer = document.querySelector('.js-boxVideo')
      const videoTag = document.createElement('video')

      videoTag.setAttribute('controls', '')
      videoContainer.appendChild(videoTag)
      
      if (videoContainer) {
        const video = videoContainer.querySelector('video')
        const videoSrc = videoContainer.getAttribute('data-src')
        
        if (videoSrc && Hls.isSupported()) {
          const hls = new Hls()
    
          hls.loadSource(videoSrc)
          hls.attachMedia(video)
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play()
          })
        } else {
          setTimeout(() => {
            video.src = videoSrc
          }, 3600)
        }
      } 
    })
  }
})
