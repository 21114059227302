import { ready } from '../../../js/utils/documentReady'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import { executeOnScroll } from '../../../js/utils/executeOnScroll'
import {loadingSlides} from '../../../js/utils/loadingSlides'

ready(() => {
  const swiperContainer = document.querySelector('.main-3__margins-ticker-slider')
  const swiperContainer2 = document.querySelector('.article-alfa-profession__slider')

  if (swiperContainer || swiperContainer2) {
    executeOnScroll(swiperContainer || swiperContainer2, () => {
      const duplicateSlides = (selector) => {
        const swiperWrapper = document.querySelector(`${selector} .swiper-wrapper`)
        const slides = swiperWrapper ? swiperWrapper.children : []
        const slidesCount = slides.length

        if (slidesCount > 0 && slidesCount < 7) {
          const slidesToDuplicate = Array.from(slides)
          slidesToDuplicate.forEach(slide => {
            const clone = slide.cloneNode(true)
            swiperWrapper.appendChild(clone)
          })
        }
      }

      duplicateSlides('.js-tickerSlider')
      duplicateSlides('.js-tickerSlider2')

      const createSwiper = (selector, additionalOptions = {}) => {
        let startX = 0

        return new Swiper(selector, {
          followFinger: false,
          modules: [Autoplay],
          loop: true,
          allowTouchMove: false,
          speed: 500,
          centeredSlides: true,
          slidesPerGroup: 1,
          breakpoints: {
            320: {
              spaceBetween: 20,
              slidesPerView: 4.6,
            },
            768: {
              slidesPerView: 5.9,
              spaceBetween: 28,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 32,
            },
            1900: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          },
          on: {
            init() {
              loadingSlides(this.activeIndex, this.slides, 3, 4)
              this.el.style.cursor = 'grab'
            },
            touchStart() {
              startX = event.pageX
              stopManualAutoplay()
              this.el.style.cursor = 'grabbing'
            },
            touchEnd(swiper, event) {
              const pageX = event.changedTouches?.[0]?.pageX || event.pageX
              this.el.style.cursor = 'grab'
              if(pageX) {
                const diffX = startX - pageX
                if (Math.abs(diffX) > 40) {
                  if (diffX < 0) {
                    this.slidePrev()
                  } else {
                    this.slideNext()
                  }
                }
                startManualAutoplay()
              }
            },
            slideChangeTransitionStart() {
              this.el.style.pointerEvents = 'none'
              loadingSlides(this.activeIndex, this.slides, 3, 4)
            },
            slideChangeTransitionEnd() {
              this.el.style.pointerEvents = ''
            },
          },
          ...additionalOptions,
        })
      }

      const swiper1 = createSwiper('.js-tickerSlider')
      const swiper2 = createSwiper('.js-tickerSlider2', {
        breakpoints: {
          320: {
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 28,
          },
          1280: {
            slidesPerView: 5.4,
            spaceBetween: 32,
          },
          1900: {
            slidesPerView: 5.4,
            spaceBetween: 40,
          },
        },
      })

      let autoplayInterval

      const startManualAutoplay = () => {
        autoplayInterval = setInterval(() => {
          swiper1.slideNext()
          swiper2.slidePrev()
        }, 3000)
      }

      const stopManualAutoplay = () => {
        clearInterval(autoplayInterval)
      }

      swiper1.on('slideChange', () => {
        setTimeout(() => {
          const totalSlides = swiper1.slides.length

          let diff = swiper1.previousRealIndex - swiper1.realIndex

          if (Math.abs(diff) > totalSlides / 2) {
            if (diff > 0) {
              diff = diff - totalSlides
            } else {
              diff = diff + totalSlides
            }
          }

          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              swiper2.slideNext()
            }
          } else if (diff < 0) {
            for (let i = 0; i < Math.abs(diff); i++) {
              swiper2.slidePrev()
            }
          }
        }, 10)
      })

      swiper2.on('slideChange', () => {
        setTimeout(() => {
          const totalSlides = swiper2.slides.length

          let diff = swiper2.previousRealIndex - swiper2.realIndex

          if (Math.abs(diff) > totalSlides / 2) {
            if (diff > 0) {
              diff = diff - totalSlides
            } else {
              diff = diff + totalSlides
            }
          }

          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              swiper1.slideNext()
            }
          } else if (diff < 0) {
            for (let i = 0; i < Math.abs(diff); i++) {
              swiper1.slidePrev()
            }
          }
        }, 10)
      })

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            startManualAutoplay()
          } else {
            stopManualAutoplay()
          }
        })
      }, { threshold: 0 })

      observer.observe(swiperContainer)
    })
  }
})
