import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const customTextFields = document.querySelectorAll('.field-custom-text');

  customTextFields.forEach(function(field) {
    const input = field.querySelector('input');

    function updateFieldState() {
      if (input === document.activeElement || input.value !== '') {
        field.classList.add('field-custom-text_active');
      } else {
        field.classList.remove('field-custom-text_active');
      }
    }

    input.addEventListener('focus', updateFieldState);
    input.addEventListener('input', updateFieldState);

    input.addEventListener('blur', updateFieldState);

    updateFieldState();
  });
})
