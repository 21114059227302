import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const buttonLogin = document.querySelector('.js-burgerLoginButton')
  const buttonBurger = document.querySelector('.js-burger')
  
  if (buttonLogin) {
    buttonLogin.addEventListener('click', () => {
      buttonBurger.click()
      document.querySelector('.popup-signin-profile')?.classList.add('popup-signin-profile_open')
    })
  }
})
