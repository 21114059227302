export const breakpoints = {
  mobile: 768,
  tablet: 1280,
  netbook: 1900,
}

export function getSize() {
  const width = window.innerWidth

  const isMobile = width < breakpoints.mobile
  const isTablet = width >= breakpoints.mobile && width < breakpoints.tablet
  const isNetbook = width >= breakpoints.tablet && width < breakpoints.netbook
  const isDesktop = width >= breakpoints.netbook

  return {
    isMobile, isTablet, isNetbook, isDesktop,
  }
}
