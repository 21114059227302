import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

let observers = []

const observeRefElement = (refElement, callback) => {
  const observer = new ResizeObserver(callback)
  observer.observe(refElement)
  observers.push(observer)
}

const disconnectObservers = () => {
  observers.forEach(observer => observer.disconnect())
  observers = []
}

export const changeBgHeight = () => {
  const { isMobile, isDesktop, isNetbook, isTablet } = getSize()

  disconnectObservers()

  for (let bgElement of document.querySelectorAll('.bg-overlay')) {
    if (!bgElement.classList.contains('bg-overlay_disabled')) {
      const style = getComputedStyle(bgElement)

      const refSelector = style.getPropertyValue('--ref').trim()
      const height = style.getPropertyValue('--height').trim()
      const netHeight = style.getPropertyValue('--net-height').trim()
      const tabHeight = style.getPropertyValue('--tab-height').trim()
      const mobHeight = style.getPropertyValue('--mob-height').trim()

      let finalHeight

      if (isMobile) {
        finalHeight = mobHeight || tabHeight || netHeight || height
      } else if (isTablet) {
        finalHeight = tabHeight || netHeight || height
      } else if (isNetbook) {
        finalHeight = netHeight || height
      } else if (isDesktop) {
        finalHeight = height
      }

      let isNeedRemoveForceHeight = true

      if (finalHeight && refSelector) {
        const refElement = document.querySelector(refSelector)
        const refElementHeight = refElement ? refElement.offsetHeight : 0
        let resultHeight

        if (finalHeight.includes('calc')) {
          const calcMatch = finalHeight.match(/calc\((\d+)% \+ (\d+)px\)/)
          if (calcMatch) {
            const percentage = parseFloat(calcMatch[1])
            const pixels = parseFloat(calcMatch[2])
            resultHeight = refElementHeight * (percentage / 100) + pixels
          }
        } else if (finalHeight.endsWith('%')) {
          resultHeight = refElementHeight * parseFloat(finalHeight) / 100
        } else if (finalHeight.endsWith('px')) {
          resultHeight = parseFloat(finalHeight)
        }

        if (resultHeight) {
          bgElement.style.setProperty('--force-height', `${resultHeight}px`)
          isNeedRemoveForceHeight = false
        }

        if (refElement) {
          observeRefElement(refElement, changeBgHeight)
        }
      }

      if (isNeedRemoveForceHeight) {
        bgElement.style.removeProperty('--force-height')
      }
    }
  }
}

ready(() => {
  changeBgHeight()
  window.addEventListener('resize', changeBgHeight)
})
