import './validation-rules/validate'
import './validation-rules/string/email'
import './validation-rules/number/max'
import './validation-rules/string/password'
import './validation-rules/string/phone'
import './validation-rules/string/url'
import './validation-rules/string/date'
import './validation-rules/file/file'
import './validation-rules/checkbox/required'

import './email-field/email-field'
import './password-field/password-field'
import './phone-field/phone-field'
import './field-file/field-file'
import './field-file/field-file-validation'
import './url-field/url-field'
import './date-field/date-field'
import './number-field/number-field'
