import { animate } from '../../../js/utils/animate'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  if (document.querySelector('.js-toTop')) {
    document.querySelector('.js-toTop').addEventListener(
      'click',
      function(e) {
        e.preventDefault()
        const scroll = window.scrollY
        const targetTop = 0
        const scrollDiff = (scroll - targetTop) * -1
        animate({
          duration: 500,
          timing: function(timeFraction) {
            return Math.pow(timeFraction, 4)
          },
          draw: function(progress) {
            const scrollNow = scroll + progress * scrollDiff
            window.scrollTo(0, scrollNow)
          },
        })
      },
      false,
    )

    window.addEventListener('scroll', visibilityToggle)
    visibilityToggle()
  }

  function visibilityToggle() {
    if (window.scrollY >= 500) {
      document.querySelector('.js-toTop').classList.add('to-top_visible')
    } else {
      document.querySelector('.js-toTop').classList.remove('to-top_visible')
    }
  }
})
