export const styleMap = [
  {
    'tags': 'country',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#859cad'
      },
      {
        'opacity': 0.8,
        'zoom': 0
      },
      {
        'opacity': 0.8,
        'zoom': 1
      },
      {
        'opacity': 0.8,
        'zoom': 2
      },
      {
        'opacity': 0.8,
        'zoom': 3
      },
      {
        'opacity': 0.8,
        'zoom': 4
      },
      {
        'opacity': 1,
        'zoom': 5
      },
      {
        'opacity': 1,
        'zoom': 6
      },
      {
        'opacity': 1,
        'zoom': 7
      },
      {
        'opacity': 1,
        'zoom': 8
      },
      {
        'opacity': 1,
        'zoom': 9
      },
      {
        'opacity': 1,
        'zoom': 10
      },
      {
        'opacity': 1,
        'zoom': 11
      },
      {
        'opacity': 1,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'country',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#9ad3fe'
      },
      {
        'opacity': 0.15,
        'zoom': 0
      },
      {
        'opacity': 0.15,
        'zoom': 1
      },
      {
        'opacity': 0.15,
        'zoom': 2
      },
      {
        'opacity': 0.15,
        'zoom': 3
      },
      {
        'opacity': 0.15,
        'zoom': 4
      },
      {
        'opacity': 0.15,
        'zoom': 5
      },
      {
        'opacity': 0.25,
        'zoom': 6
      },
      {
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'opacity': 0.47,
        'zoom': 8
      },
      {
        'opacity': 0.44,
        'zoom': 9
      },
      {
        'opacity': 0.41,
        'zoom': 10
      },
      {
        'opacity': 0.38,
        'zoom': 11
      },
      {
        'opacity': 0.35,
        'zoom': 12
      },
      {
        'opacity': 0.33,
        'zoom': 13
      },
      {
        'opacity': 0.3,
        'zoom': 14
      },
      {
        'opacity': 0.28,
        'zoom': 15
      },
      {
        'opacity': 0.25,
        'zoom': 16
      },
      {
        'opacity': 0.25,
        'zoom': 17
      },
      {
        'opacity': 0.25,
        'zoom': 18
      },
      {
        'opacity': 0.25,
        'zoom': 19
      },
      {
        'opacity': 0.25,
        'zoom': 20
      },
      {
        'opacity': 0.25,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'region',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 0
      },
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 1
      },
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 2
      },
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 3
      },
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 4
      },
      {
        'color': '#a3b5c2',
        'opacity': 0.5,
        'zoom': 5
      },
      {
        'color': '#a3b5c2',
        'opacity': 1,
        'zoom': 6
      },
      {
        'color': '#a3b5c2',
        'opacity': 1,
        'zoom': 7
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 8
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 9
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 10
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 11
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 12
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 16
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 17
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 18
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 19
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 20
      },
      {
        'color': '#859cad',
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'region',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#9ad3fe'
      },
      {
        'opacity': 0.15,
        'zoom': 0
      },
      {
        'opacity': 0.15,
        'zoom': 1
      },
      {
        'opacity': 0.15,
        'zoom': 2
      },
      {
        'opacity': 0.15,
        'zoom': 3
      },
      {
        'opacity': 0.15,
        'zoom': 4
      },
      {
        'opacity': 0.15,
        'zoom': 5
      },
      {
        'opacity': 0.25,
        'zoom': 6
      },
      {
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'opacity': 0.47,
        'zoom': 8
      },
      {
        'opacity': 0.44,
        'zoom': 9
      },
      {
        'opacity': 0.41,
        'zoom': 10
      },
      {
        'opacity': 0.38,
        'zoom': 11
      },
      {
        'opacity': 0.35,
        'zoom': 12
      },
      {
        'opacity': 0.33,
        'zoom': 13
      },
      {
        'opacity': 0.3,
        'zoom': 14
      },
      {
        'opacity': 0.28,
        'zoom': 15
      },
      {
        'opacity': 0.25,
        'zoom': 16
      },
      {
        'opacity': 0.25,
        'zoom': 17
      },
      {
        'opacity': 0.25,
        'zoom': 18
      },
      {
        'opacity': 0.25,
        'zoom': 19
      },
      {
        'opacity': 0.25,
        'zoom': 20
      },
      {
        'opacity': 0.25,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'admin',
      'none': [
        'country',
        'region',
        'locality',
        'district',
        'address'
      ]
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#859cad'
      },
      {
        'opacity': 0.5,
        'zoom': 0
      },
      {
        'opacity': 0.5,
        'zoom': 1
      },
      {
        'opacity': 0.5,
        'zoom': 2
      },
      {
        'opacity': 0.5,
        'zoom': 3
      },
      {
        'opacity': 0.5,
        'zoom': 4
      },
      {
        'opacity': 0.5,
        'zoom': 5
      },
      {
        'opacity': 1,
        'zoom': 6
      },
      {
        'opacity': 1,
        'zoom': 7
      },
      {
        'opacity': 1,
        'zoom': 8
      },
      {
        'opacity': 1,
        'zoom': 9
      },
      {
        'opacity': 1,
        'zoom': 10
      },
      {
        'opacity': 1,
        'zoom': 11
      },
      {
        'opacity': 1,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'admin',
      'none': [
        'country',
        'region',
        'locality',
        'district',
        'address'
      ]
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#9ad3fe'
      },
      {
        'opacity': 0.15,
        'zoom': 0
      },
      {
        'opacity': 0.15,
        'zoom': 1
      },
      {
        'opacity': 0.15,
        'zoom': 2
      },
      {
        'opacity': 0.15,
        'zoom': 3
      },
      {
        'opacity': 0.15,
        'zoom': 4
      },
      {
        'opacity': 0.15,
        'zoom': 5
      },
      {
        'opacity': 0.25,
        'zoom': 6
      },
      {
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'opacity': 0.47,
        'zoom': 8
      },
      {
        'opacity': 0.44,
        'zoom': 9
      },
      {
        'opacity': 0.41,
        'zoom': 10
      },
      {
        'opacity': 0.38,
        'zoom': 11
      },
      {
        'opacity': 0.35,
        'zoom': 12
      },
      {
        'opacity': 0.33,
        'zoom': 13
      },
      {
        'opacity': 0.3,
        'zoom': 14
      },
      {
        'opacity': 0.28,
        'zoom': 15
      },
      {
        'opacity': 0.25,
        'zoom': 16
      },
      {
        'opacity': 0.25,
        'zoom': 17
      },
      {
        'opacity': 0.25,
        'zoom': 18
      },
      {
        'opacity': 0.25,
        'zoom': 19
      },
      {
        'opacity': 0.25,
        'zoom': 20
      },
      {
        'opacity': 0.25,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'landcover',
      'none': 'vegetation'
    },
    'stylers': [
      {
        'hue': '#b9e0fe'
      }
    ]
  },
  {
    'tags': 'vegetation',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 0
      },
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 1
      },
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 2
      },
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 3
      },
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 4
      },
      {
        'color': '#86cafd',
        'opacity': 0.1,
        'zoom': 5
      },
      {
        'color': '#86cafd',
        'opacity': 0.2,
        'zoom': 6
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.3,
        'zoom': 7
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.4,
        'zoom': 8
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.6,
        'zoom': 9
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.8,
        'zoom': 10
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 11
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 12
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#c3e4fe',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 16
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 17
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 18
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 19
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 20
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'park',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 0
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 1
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 2
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 3
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 4
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 5
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.2,
        'zoom': 6
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.3,
        'zoom': 7
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.4,
        'zoom': 8
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.6,
        'zoom': 9
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.8,
        'zoom': 10
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 11
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 12
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#c3e4fe',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#cde9fe',
        'opacity': 0.9,
        'zoom': 16
      },
      {
        'color': '#cde9fe',
        'opacity': 0.8,
        'zoom': 17
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 18
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 19
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 20
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'national_park',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 0
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 1
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 2
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 3
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 4
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.1,
        'zoom': 5
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.2,
        'zoom': 6
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.3,
        'zoom': 7
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.4,
        'zoom': 8
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.6,
        'zoom': 9
      },
      {
        'color': '#b9e0fe',
        'opacity': 0.8,
        'zoom': 10
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 11
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 12
      },
      {
        'color': '#b9e0fe',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#c3e4fe',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#cde9fe',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 16
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 17
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 18
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 19
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 20
      },
      {
        'color': '#cde9fe',
        'opacity': 0.7,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'cemetery',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#b9e0fe',
        'zoom': 0
      },
      {
        'color': '#b9e0fe',
        'zoom': 1
      },
      {
        'color': '#b9e0fe',
        'zoom': 2
      },
      {
        'color': '#b9e0fe',
        'zoom': 3
      },
      {
        'color': '#b9e0fe',
        'zoom': 4
      },
      {
        'color': '#b9e0fe',
        'zoom': 5
      },
      {
        'color': '#b9e0fe',
        'zoom': 6
      },
      {
        'color': '#b9e0fe',
        'zoom': 7
      },
      {
        'color': '#b9e0fe',
        'zoom': 8
      },
      {
        'color': '#b9e0fe',
        'zoom': 9
      },
      {
        'color': '#b9e0fe',
        'zoom': 10
      },
      {
        'color': '#b9e0fe',
        'zoom': 11
      },
      {
        'color': '#b9e0fe',
        'zoom': 12
      },
      {
        'color': '#b9e0fe',
        'zoom': 13
      },
      {
        'color': '#c3e4fe',
        'zoom': 14
      },
      {
        'color': '#cde9fe',
        'zoom': 15
      },
      {
        'color': '#cde9fe',
        'zoom': 16
      },
      {
        'color': '#cde9fe',
        'zoom': 17
      },
      {
        'color': '#cde9fe',
        'zoom': 18
      },
      {
        'color': '#cde9fe',
        'zoom': 19
      },
      {
        'color': '#cde9fe',
        'zoom': 20
      },
      {
        'color': '#cde9fe',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'sports_ground',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 0
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 1
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 2
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 3
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 4
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 5
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 6
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 7
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 8
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 9
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 10
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 11
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 12
      },
      {
        'color': '#9fd5fe',
        'opacity': 0,
        'zoom': 13
      },
      {
        'color': '#a9d9fe',
        'opacity': 0,
        'zoom': 14
      },
      {
        'color': '#b3defe',
        'opacity': 0.5,
        'zoom': 15
      },
      {
        'color': '#b5dffe',
        'opacity': 1,
        'zoom': 16
      },
      {
        'color': '#b6dffe',
        'opacity': 1,
        'zoom': 17
      },
      {
        'color': '#b8e0fe',
        'opacity': 1,
        'zoom': 18
      },
      {
        'color': '#bae1fe',
        'opacity': 1,
        'zoom': 19
      },
      {
        'color': '#bbe1fe',
        'opacity': 1,
        'zoom': 20
      },
      {
        'color': '#bde2fe',
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'terrain',
    'elements': 'geometry',
    'stylers': [
      {
        'hue': '#d7edfe'
      },
      {
        'opacity': 0.3,
        'zoom': 0
      },
      {
        'opacity': 0.3,
        'zoom': 1
      },
      {
        'opacity': 0.3,
        'zoom': 2
      },
      {
        'opacity': 0.3,
        'zoom': 3
      },
      {
        'opacity': 0.3,
        'zoom': 4
      },
      {
        'opacity': 0.35,
        'zoom': 5
      },
      {
        'opacity': 0.4,
        'zoom': 6
      },
      {
        'opacity': 0.6,
        'zoom': 7
      },
      {
        'opacity': 0.8,
        'zoom': 8
      },
      {
        'opacity': 0.9,
        'zoom': 9
      },
      {
        'opacity': 1,
        'zoom': 10
      },
      {
        'opacity': 1,
        'zoom': 11
      },
      {
        'opacity': 1,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'geographic_line',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#1094f9'
      }
    ]
  },
  {
    'tags': 'land',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#d8eefd',
        'zoom': 0
      },
      {
        'color': '#d8eefd',
        'zoom': 1
      },
      {
        'color': '#d8eefd',
        'zoom': 2
      },
      {
        'color': '#d8eefd',
        'zoom': 3
      },
      {
        'color': '#d8eefd',
        'zoom': 4
      },
      {
        'color': '#ddf0fd',
        'zoom': 5
      },
      {
        'color': '#e2f2fd',
        'zoom': 6
      },
      {
        'color': '#e7f4fe',
        'zoom': 7
      },
      {
        'color': '#ecf6fe',
        'zoom': 8
      },
      {
        'color': '#ecf6fe',
        'zoom': 9
      },
      {
        'color': '#ecf6fe',
        'zoom': 10
      },
      {
        'color': '#ecf6fe',
        'zoom': 11
      },
      {
        'color': '#ecf6fe',
        'zoom': 12
      },
      {
        'color': '#ecf6fe',
        'zoom': 13
      },
      {
        'color': '#f0f8fe',
        'zoom': 14
      },
      {
        'color': '#f5fbfe',
        'zoom': 15
      },
      {
        'color': '#f6fbfe',
        'zoom': 16
      },
      {
        'color': '#f7fcfe',
        'zoom': 17
      },
      {
        'color': '#f7fcfe',
        'zoom': 18
      },
      {
        'color': '#f8fcff',
        'zoom': 19
      },
      {
        'color': '#f9fdff',
        'zoom': 20
      },
      {
        'color': '#fafdff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'residential',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 0
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 1
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 2
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 3
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 4
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 5
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 6
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'opacity': 0.5,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#e1f1fe',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#ebf6fe',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#edf7fe',
        'opacity': 1,
        'zoom': 16
      },
      {
        'color': '#eef8fe',
        'opacity': 1,
        'zoom': 17
      },
      {
        'color': '#f0f8fe',
        'opacity': 1,
        'zoom': 18
      },
      {
        'color': '#f2f9ff',
        'opacity': 1,
        'zoom': 19
      },
      {
        'color': '#f3faff',
        'opacity': 1,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'locality',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#d7edfe',
        'zoom': 0
      },
      {
        'color': '#d7edfe',
        'zoom': 1
      },
      {
        'color': '#d7edfe',
        'zoom': 2
      },
      {
        'color': '#d7edfe',
        'zoom': 3
      },
      {
        'color': '#d7edfe',
        'zoom': 4
      },
      {
        'color': '#d7edfe',
        'zoom': 5
      },
      {
        'color': '#d7edfe',
        'zoom': 6
      },
      {
        'color': '#d7edfe',
        'zoom': 7
      },
      {
        'color': '#d7edfe',
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'zoom': 13
      },
      {
        'color': '#e1f1fe',
        'zoom': 14
      },
      {
        'color': '#ebf6fe',
        'zoom': 15
      },
      {
        'color': '#edf7fe',
        'zoom': 16
      },
      {
        'color': '#eef8fe',
        'zoom': 17
      },
      {
        'color': '#f0f8fe',
        'zoom': 18
      },
      {
        'color': '#f2f9ff',
        'zoom': 19
      },
      {
        'color': '#f3faff',
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'structure',
      'none': [
        'building',
        'fence'
      ]
    },
    'elements': 'geometry',
    'stylers': [
      {
        'opacity': 0.9
      },
      {
        'color': '#d7edfe',
        'zoom': 0
      },
      {
        'color': '#d7edfe',
        'zoom': 1
      },
      {
        'color': '#d7edfe',
        'zoom': 2
      },
      {
        'color': '#d7edfe',
        'zoom': 3
      },
      {
        'color': '#d7edfe',
        'zoom': 4
      },
      {
        'color': '#d7edfe',
        'zoom': 5
      },
      {
        'color': '#d7edfe',
        'zoom': 6
      },
      {
        'color': '#d7edfe',
        'zoom': 7
      },
      {
        'color': '#d7edfe',
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'zoom': 13
      },
      {
        'color': '#e1f1fe',
        'zoom': 14
      },
      {
        'color': '#ebf6fe',
        'zoom': 15
      },
      {
        'color': '#edf7fe',
        'zoom': 16
      },
      {
        'color': '#eef8fe',
        'zoom': 17
      },
      {
        'color': '#f0f8fe',
        'zoom': 18
      },
      {
        'color': '#f2f9ff',
        'zoom': 19
      },
      {
        'color': '#f3faff',
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'building',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#c3e4fe'
      },
      {
        'opacity': 0.7,
        'zoom': 0
      },
      {
        'opacity': 0.7,
        'zoom': 1
      },
      {
        'opacity': 0.7,
        'zoom': 2
      },
      {
        'opacity': 0.7,
        'zoom': 3
      },
      {
        'opacity': 0.7,
        'zoom': 4
      },
      {
        'opacity': 0.7,
        'zoom': 5
      },
      {
        'opacity': 0.7,
        'zoom': 6
      },
      {
        'opacity': 0.7,
        'zoom': 7
      },
      {
        'opacity': 0.7,
        'zoom': 8
      },
      {
        'opacity': 0.7,
        'zoom': 9
      },
      {
        'opacity': 0.7,
        'zoom': 10
      },
      {
        'opacity': 0.7,
        'zoom': 11
      },
      {
        'opacity': 0.7,
        'zoom': 12
      },
      {
        'opacity': 0.7,
        'zoom': 13
      },
      {
        'opacity': 0.7,
        'zoom': 14
      },
      {
        'opacity': 0.7,
        'zoom': 15
      },
      {
        'opacity': 0.9,
        'zoom': 16
      },
      {
        'opacity': 0.6,
        'zoom': 17
      },
      {
        'opacity': 0.6,
        'zoom': 18
      },
      {
        'opacity': 0.6,
        'zoom': 19
      },
      {
        'opacity': 0.6,
        'zoom': 20
      },
      {
        'opacity': 0.6,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'building',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#9ad1fe'
      },
      {
        'opacity': 0.5,
        'zoom': 0
      },
      {
        'opacity': 0.5,
        'zoom': 1
      },
      {
        'opacity': 0.5,
        'zoom': 2
      },
      {
        'opacity': 0.5,
        'zoom': 3
      },
      {
        'opacity': 0.5,
        'zoom': 4
      },
      {
        'opacity': 0.5,
        'zoom': 5
      },
      {
        'opacity': 0.5,
        'zoom': 6
      },
      {
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'opacity': 0.5,
        'zoom': 8
      },
      {
        'opacity': 0.5,
        'zoom': 9
      },
      {
        'opacity': 0.5,
        'zoom': 10
      },
      {
        'opacity': 0.5,
        'zoom': 11
      },
      {
        'opacity': 0.5,
        'zoom': 12
      },
      {
        'opacity': 0.5,
        'zoom': 13
      },
      {
        'opacity': 0.5,
        'zoom': 14
      },
      {
        'opacity': 0.5,
        'zoom': 15
      },
      {
        'opacity': 0.5,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'urban_area',
      'none': [
        'residential',
        'industrial',
        'cemetery',
        'park',
        'medical',
        'sports_ground',
        'beach',
        'construction_site'
      ]
    },
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 0
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 1
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 2
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 3
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 4
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 5
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 6
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 7
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 8
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 9
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 10
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 11
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 12
      },
      {
        'color': '#c4e4fd',
        'opacity': 1,
        'zoom': 13
      },
      {
        'color': '#d0e9fd',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#dceffe',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#e8f5fe',
        'opacity': 0.67,
        'zoom': 16
      },
      {
        'color': '#f5fbff',
        'opacity': 0.33,
        'zoom': 17
      },
      {
        'color': '#f5fbff',
        'opacity': 0,
        'zoom': 18
      },
      {
        'color': '#f5fbff',
        'opacity': 0,
        'zoom': 19
      },
      {
        'color': '#f5fbff',
        'opacity': 0,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'opacity': 0,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'poi',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'poi',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'poi',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'outdoor',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'outdoor',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'outdoor',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'park',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'park',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'park',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'cemetery',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'cemetery',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'cemetery',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'beach',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'beach',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'beach',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'medical',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'medical',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'medical',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'shopping',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'shopping',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'shopping',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'commercial_services',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'commercial_services',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'commercial_services',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'food_and_drink',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'food_and_drink',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697'
      }
    ]
  },
  {
    'tags': 'food_and_drink',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'label.icon',
    'types': 'point',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'tertiary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'label.text.fill',
    'types': 'point',
    'stylers': [
      {
        'color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'entrance',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      },
      {
        'hue': '#0091ff'
      }
    ]
  },
  {
    'tags': 'locality',
    'elements': 'label.icon',
    'stylers': [
      {
        'color': '#0091ff'
      },
      {
        'secondary-color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'country',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'opacity': 0.8
      },
      {
        'color': '#0382e2'
      }
    ]
  },
  {
    'tags': 'country',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'region',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0382e2'
      },
      {
        'opacity': 0.8
      }
    ]
  },
  {
    'tags': 'region',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'district',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0382e2'
      },
      {
        'opacity': 0.8
      }
    ]
  },
  {
    'tags': 'district',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': {
      'any': 'admin',
      'none': [
        'country',
        'region',
        'locality',
        'district',
        'address'
      ]
    },
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0382e2'
      }
    ]
  },
  {
    'tags': {
      'any': 'admin',
      'none': [
        'country',
        'region',
        'locality',
        'district',
        'address'
      ]
    },
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'locality',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#025697',
        'zoom': 0
      },
      {
        'color': '#025697',
        'zoom': 1
      },
      {
        'color': '#025697',
        'zoom': 2
      },
      {
        'color': '#025697',
        'zoom': 3
      },
      {
        'color': '#025697',
        'zoom': 4
      },
      {
        'color': '#025493',
        'zoom': 5
      },
      {
        'color': '#02518f',
        'zoom': 6
      },
      {
        'color': '#024f8b',
        'zoom': 7
      },
      {
        'color': '#024d86',
        'zoom': 8
      },
      {
        'color': '#024a82',
        'zoom': 9
      },
      {
        'color': '#02487e',
        'zoom': 10
      },
      {
        'color': '#02487e',
        'zoom': 11
      },
      {
        'color': '#02487e',
        'zoom': 12
      },
      {
        'color': '#02487e',
        'zoom': 13
      },
      {
        'color': '#02487e',
        'zoom': 14
      },
      {
        'color': '#02487e',
        'zoom': 15
      },
      {
        'color': '#02487e',
        'zoom': 16
      },
      {
        'color': '#02487e',
        'zoom': 17
      },
      {
        'color': '#02487e',
        'zoom': 18
      },
      {
        'color': '#02487e',
        'zoom': 19
      },
      {
        'color': '#02487e',
        'zoom': 20
      },
      {
        'color': '#02487e',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'locality',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'label.text.fill',
    'types': 'polyline',
    'stylers': [
      {
        'color': '#0265b1'
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'label.text.outline',
    'types': 'polyline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'geometry.fill.pattern',
    'types': 'polyline',
    'stylers': [
      {
        'scale': 1
      },
      {
        'color': '#1c9bfd'
      }
    ]
  },
  {
    'tags': 'road',
    'elements': 'label.text.fill',
    'types': 'point',
    'stylers': [
      {
        'color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'structure',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0573c7'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'structure',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'address',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0573c7'
      },
      {
        'opacity': 0.9,
        'zoom': 0
      },
      {
        'opacity': 0.9,
        'zoom': 1
      },
      {
        'opacity': 0.9,
        'zoom': 2
      },
      {
        'opacity': 0.9,
        'zoom': 3
      },
      {
        'opacity': 0.9,
        'zoom': 4
      },
      {
        'opacity': 0.9,
        'zoom': 5
      },
      {
        'opacity': 0.9,
        'zoom': 6
      },
      {
        'opacity': 0.9,
        'zoom': 7
      },
      {
        'opacity': 0.9,
        'zoom': 8
      },
      {
        'opacity': 0.9,
        'zoom': 9
      },
      {
        'opacity': 0.9,
        'zoom': 10
      },
      {
        'opacity': 0.9,
        'zoom': 11
      },
      {
        'opacity': 0.9,
        'zoom': 12
      },
      {
        'opacity': 0.9,
        'zoom': 13
      },
      {
        'opacity': 0.9,
        'zoom': 14
      },
      {
        'opacity': 0.9,
        'zoom': 15
      },
      {
        'opacity': 0.9,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'address',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5
      }
    ]
  },
  {
    'tags': 'landscape',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#0382e2',
        'opacity': 1,
        'zoom': 0
      },
      {
        'color': '#0382e2',
        'opacity': 1,
        'zoom': 1
      },
      {
        'color': '#0382e2',
        'opacity': 1,
        'zoom': 2
      },
      {
        'color': '#0382e2',
        'opacity': 1,
        'zoom': 3
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 4
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 5
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 6
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 7
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 8
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 9
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 10
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 11
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 12
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 13
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 14
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 15
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 16
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 17
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 18
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 19
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 20
      },
      {
        'color': '#0573c7',
        'opacity': 0.5,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'landscape',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.5,
        'zoom': 0
      },
      {
        'opacity': 0.5,
        'zoom': 1
      },
      {
        'opacity': 0.5,
        'zoom': 2
      },
      {
        'opacity': 0.5,
        'zoom': 3
      },
      {
        'opacity': 0,
        'zoom': 4
      },
      {
        'opacity': 0,
        'zoom': 5
      },
      {
        'opacity': 0,
        'zoom': 6
      },
      {
        'opacity': 0,
        'zoom': 7
      },
      {
        'opacity': 0,
        'zoom': 8
      },
      {
        'opacity': 0,
        'zoom': 9
      },
      {
        'opacity': 0,
        'zoom': 10
      },
      {
        'opacity': 0,
        'zoom': 11
      },
      {
        'opacity': 0,
        'zoom': 12
      },
      {
        'opacity': 0,
        'zoom': 13
      },
      {
        'opacity': 0,
        'zoom': 14
      },
      {
        'opacity': 0,
        'zoom': 15
      },
      {
        'opacity': 0,
        'zoom': 16
      },
      {
        'opacity': 0,
        'zoom': 17
      },
      {
        'opacity': 0,
        'zoom': 18
      },
      {
        'opacity': 0,
        'zoom': 19
      },
      {
        'opacity': 0,
        'zoom': 20
      },
      {
        'opacity': 0,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'water',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#028df7'
      },
      {
        'opacity': 0.8
      }
    ]
  },
  {
    'tags': 'water',
    'elements': 'label.text.outline',
    'types': 'polyline',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'opacity': 0.2
      }
    ]
  },
  {
    'tags': {
      'any': 'road_1',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 2.97,
        'zoom': 6
      },
      {
        'scale': 3.19,
        'zoom': 7
      },
      {
        'scale': 3.53,
        'zoom': 8
      },
      {
        'scale': 4,
        'zoom': 9
      },
      {
        'scale': 3.61,
        'zoom': 10
      },
      {
        'scale': 3.06,
        'zoom': 11
      },
      {
        'scale': 2.64,
        'zoom': 12
      },
      {
        'scale': 2.27,
        'zoom': 13
      },
      {
        'scale': 2.03,
        'zoom': 14
      },
      {
        'scale': 1.9,
        'zoom': 15
      },
      {
        'scale': 1.86,
        'zoom': 16
      },
      {
        'scale': 1.48,
        'zoom': 17
      },
      {
        'scale': 1.21,
        'zoom': 18
      },
      {
        'scale': 1.04,
        'zoom': 19
      },
      {
        'scale': 0.94,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_1'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#00000000',
        'scale': 3.05,
        'zoom': 6
      },
      {
        'color': '#00000000',
        'scale': 3.05,
        'zoom': 7
      },
      {
        'color': '#cde9fe',
        'scale': 3.15,
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'scale': 3.37,
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'scale': 3.36,
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'scale': 3.17,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'scale': 3,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 2.8,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 2.66,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 2.61,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 2.64,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 2.14,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.79,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.55,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.41,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.35,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_2',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 2.97,
        'zoom': 6
      },
      {
        'scale': 3.19,
        'zoom': 7
      },
      {
        'scale': 3.53,
        'zoom': 8
      },
      {
        'scale': 4,
        'zoom': 9
      },
      {
        'scale': 3.61,
        'zoom': 10
      },
      {
        'scale': 3.06,
        'zoom': 11
      },
      {
        'scale': 2.64,
        'zoom': 12
      },
      {
        'scale': 2.27,
        'zoom': 13
      },
      {
        'scale': 2.03,
        'zoom': 14
      },
      {
        'scale': 1.9,
        'zoom': 15
      },
      {
        'scale': 1.86,
        'zoom': 16
      },
      {
        'scale': 1.48,
        'zoom': 17
      },
      {
        'scale': 1.21,
        'zoom': 18
      },
      {
        'scale': 1.04,
        'zoom': 19
      },
      {
        'scale': 0.94,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_2'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#00000000',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#00000000',
        'scale': 3.05,
        'zoom': 6
      },
      {
        'color': '#00000000',
        'scale': 3.05,
        'zoom': 7
      },
      {
        'color': '#cde9fe',
        'scale': 3.15,
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'scale': 3.37,
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'scale': 3.36,
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'scale': 3.17,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'scale': 3,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 2.8,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 2.66,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 2.61,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 2.64,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 2.14,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.79,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.55,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.41,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.35,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_3',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 2.51,
        'zoom': 9
      },
      {
        'scale': 2.62,
        'zoom': 10
      },
      {
        'scale': 1.68,
        'zoom': 11
      },
      {
        'scale': 1.67,
        'zoom': 12
      },
      {
        'scale': 1.38,
        'zoom': 13
      },
      {
        'scale': 1.19,
        'zoom': 14
      },
      {
        'scale': 1.08,
        'zoom': 15
      },
      {
        'scale': 1.04,
        'zoom': 16
      },
      {
        'scale': 0.91,
        'zoom': 17
      },
      {
        'scale': 0.84,
        'zoom': 18
      },
      {
        'scale': 0.82,
        'zoom': 19
      },
      {
        'scale': 0.84,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_3'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.6,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.29,
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'scale': 4.21,
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'scale': 2.74,
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'scale': 2.04,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'scale': 2.13,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 1.88,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 1.7,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 1.59,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.55,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.37,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.27,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.23,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.26,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.35,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_4',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 1.69,
        'zoom': 10
      },
      {
        'scale': 1.26,
        'zoom': 11
      },
      {
        'scale': 1.41,
        'zoom': 12
      },
      {
        'scale': 1.19,
        'zoom': 13
      },
      {
        'scale': 1.04,
        'zoom': 14
      },
      {
        'scale': 0.97,
        'zoom': 15
      },
      {
        'scale': 1.15,
        'zoom': 16
      },
      {
        'scale': 0.99,
        'zoom': 17
      },
      {
        'scale': 0.89,
        'zoom': 18
      },
      {
        'scale': 0.85,
        'zoom': 19
      },
      {
        'scale': 0.85,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_4'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 1.12,
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'scale': 1.9,
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'scale': 1.62,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'scale': 1.83,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 1.64,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 1.51,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 1.44,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.69,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.47,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.34,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.28,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.28,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.34,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_5',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 1.25,
        'zoom': 12
      },
      {
        'scale': 0.95,
        'zoom': 13
      },
      {
        'scale': 0.81,
        'zoom': 14
      },
      {
        'scale': 0.95,
        'zoom': 15
      },
      {
        'scale': 1.1,
        'zoom': 16
      },
      {
        'scale': 0.93,
        'zoom': 17
      },
      {
        'scale': 0.85,
        'zoom': 18
      },
      {
        'scale': 0.82,
        'zoom': 19
      },
      {
        'scale': 0.84,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_5'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 0.62,
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'scale': 1.61,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 1.36,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 1.22,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 1.41,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.63,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.4,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.27,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.23,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.25,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.34,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_6',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 0,
        'zoom': 12
      },
      {
        'scale': 2.25,
        'zoom': 13
      },
      {
        'scale': 1.27,
        'zoom': 14
      },
      {
        'scale': 1.25,
        'zoom': 15
      },
      {
        'scale': 1.31,
        'zoom': 16
      },
      {
        'scale': 1.04,
        'zoom': 17
      },
      {
        'scale': 0.9,
        'zoom': 18
      },
      {
        'scale': 0.85,
        'zoom': 19
      },
      {
        'scale': 0.85,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_6'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 11
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'scale': 2.31,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 1.7,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 1.76,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.89,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.55,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.36,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.27,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.27,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.34,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_7',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 0,
        'zoom': 12
      },
      {
        'scale': 0,
        'zoom': 13
      },
      {
        'scale': 0.9,
        'zoom': 14
      },
      {
        'scale': 0.78,
        'zoom': 15
      },
      {
        'scale': 0.88,
        'zoom': 16
      },
      {
        'scale': 0.8,
        'zoom': 17
      },
      {
        'scale': 0.78,
        'zoom': 18
      },
      {
        'scale': 0.79,
        'zoom': 19
      },
      {
        'scale': 0.83,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_7'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 11
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 12
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 1.31,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 1.19,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.31,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.21,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.17,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.18,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.23,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.33,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_minor',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 0,
        'zoom': 12
      },
      {
        'scale': 0,
        'zoom': 13
      },
      {
        'scale': 0,
        'zoom': 14
      },
      {
        'scale': 0,
        'zoom': 15
      },
      {
        'scale': 0.9,
        'zoom': 16
      },
      {
        'scale': 0.9,
        'zoom': 17
      },
      {
        'scale': 0.9,
        'zoom': 18
      },
      {
        'scale': 0.9,
        'zoom': 19
      },
      {
        'scale': 0.9,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_minor'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 11
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 12
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 0.4,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 0.4,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.4,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.27,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.27,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.29,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.31,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.32,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_unclassified',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 0,
        'zoom': 12
      },
      {
        'scale': 0,
        'zoom': 13
      },
      {
        'scale': 0,
        'zoom': 14
      },
      {
        'scale': 0,
        'zoom': 15
      },
      {
        'scale': 0.9,
        'zoom': 16
      },
      {
        'scale': 0.9,
        'zoom': 17
      },
      {
        'scale': 0.9,
        'zoom': 18
      },
      {
        'scale': 0.9,
        'zoom': 19
      },
      {
        'scale': 0.9,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'road_unclassified'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 11
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 12
      },
      {
        'color': '#ffffff',
        'scale': 0.4,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 0.4,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 0.4,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 1.4,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 1.27,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 1.27,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.29,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.31,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.32,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'all': 'is_tunnel',
      'none': 'path'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#cde9fe',
        'zoom': 0
      },
      {
        'color': '#cde9fe',
        'zoom': 1
      },
      {
        'color': '#cde9fe',
        'zoom': 2
      },
      {
        'color': '#cde9fe',
        'zoom': 3
      },
      {
        'color': '#cde9fe',
        'zoom': 4
      },
      {
        'color': '#cde9fe',
        'zoom': 5
      },
      {
        'color': '#cde9fe',
        'zoom': 6
      },
      {
        'color': '#cde9fe',
        'zoom': 7
      },
      {
        'color': '#cde9fe',
        'zoom': 8
      },
      {
        'color': '#cde9fe',
        'zoom': 9
      },
      {
        'color': '#cde9fe',
        'zoom': 10
      },
      {
        'color': '#cde9fe',
        'zoom': 11
      },
      {
        'color': '#cde9fe',
        'zoom': 12
      },
      {
        'color': '#cde9fe',
        'zoom': 13
      },
      {
        'color': '#d7edfe',
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'zoom': 15
      },
      {
        'color': '#e3f3fe',
        'zoom': 16
      },
      {
        'color': '#e4f3fe',
        'zoom': 17
      },
      {
        'color': '#e6f4fe',
        'zoom': 18
      },
      {
        'color': '#e8f5fe',
        'zoom': 19
      },
      {
        'color': '#e9f5fe',
        'zoom': 20
      },
      {
        'color': '#ebf6fe',
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'all': 'path',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#77c3fd'
      }
    ]
  },
  {
    'tags': {
      'all': 'path',
      'none': 'is_tunnel'
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'opacity': 0.7
      },
      {
        'color': '#d7edfe',
        'zoom': 0
      },
      {
        'color': '#d7edfe',
        'zoom': 1
      },
      {
        'color': '#d7edfe',
        'zoom': 2
      },
      {
        'color': '#d7edfe',
        'zoom': 3
      },
      {
        'color': '#d7edfe',
        'zoom': 4
      },
      {
        'color': '#d7edfe',
        'zoom': 5
      },
      {
        'color': '#d7edfe',
        'zoom': 6
      },
      {
        'color': '#d7edfe',
        'zoom': 7
      },
      {
        'color': '#d7edfe',
        'zoom': 8
      },
      {
        'color': '#d7edfe',
        'zoom': 9
      },
      {
        'color': '#d7edfe',
        'zoom': 10
      },
      {
        'color': '#d7edfe',
        'zoom': 11
      },
      {
        'color': '#d7edfe',
        'zoom': 12
      },
      {
        'color': '#d7edfe',
        'zoom': 13
      },
      {
        'color': '#e1f1fe',
        'zoom': 14
      },
      {
        'color': '#ebf6fe',
        'zoom': 15
      },
      {
        'color': '#edf7fe',
        'zoom': 16
      },
      {
        'color': '#eef8fe',
        'zoom': 17
      },
      {
        'color': '#f0f8fe',
        'zoom': 18
      },
      {
        'color': '#f2f9ff',
        'zoom': 19
      },
      {
        'color': '#f3faff',
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'road_construction',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'road_construction',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#aad9fe',
        'zoom': 0
      },
      {
        'color': '#aad9fe',
        'zoom': 1
      },
      {
        'color': '#aad9fe',
        'zoom': 2
      },
      {
        'color': '#aad9fe',
        'zoom': 3
      },
      {
        'color': '#aad9fe',
        'zoom': 4
      },
      {
        'color': '#aad9fe',
        'zoom': 5
      },
      {
        'color': '#aad9fe',
        'zoom': 6
      },
      {
        'color': '#aad9fe',
        'zoom': 7
      },
      {
        'color': '#aad9fe',
        'zoom': 8
      },
      {
        'color': '#aad9fe',
        'zoom': 9
      },
      {
        'color': '#aad9fe',
        'zoom': 10
      },
      {
        'color': '#aad9fe',
        'zoom': 11
      },
      {
        'color': '#aad9fe',
        'zoom': 12
      },
      {
        'color': '#aad9fe',
        'zoom': 13
      },
      {
        'color': '#77c3fd',
        'zoom': 14
      },
      {
        'color': '#aad9fe',
        'zoom': 15
      },
      {
        'color': '#b2ddfe',
        'zoom': 16
      },
      {
        'color': '#bbe0fe',
        'zoom': 17
      },
      {
        'color': '#c3e4fe',
        'zoom': 18
      },
      {
        'color': '#cbe8fe',
        'zoom': 19
      },
      {
        'color': '#d4ebfe',
        'zoom': 20
      },
      {
        'color': '#dceffe',
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'ferry'
    },
    'stylers': [
      {
        'color': '#62bafd'
      }
    ]
  },
  {
    'tags': 'transit_location',
    'elements': 'label.icon',
    'stylers': [
      {
        'hue': '#0091ff'
      },
      {
        'saturation': 0
      }
    ]
  },
  {
    'tags': 'transit_location',
    'elements': 'label.text.fill',
    'stylers': [
      {
        'color': '#7a9db8'
      }
    ]
  },
  {
    'tags': 'transit_location',
    'elements': 'label.text.outline',
    'stylers': [
      {
        'color': '#ffffff'
      }
    ]
  },
  {
    'tags': 'transit_schema',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#7a9db8'
      },
      {
        'scale': 0.7
      },
      {
        'opacity': 0.6,
        'zoom': 0
      },
      {
        'opacity': 0.6,
        'zoom': 1
      },
      {
        'opacity': 0.6,
        'zoom': 2
      },
      {
        'opacity': 0.6,
        'zoom': 3
      },
      {
        'opacity': 0.6,
        'zoom': 4
      },
      {
        'opacity': 0.6,
        'zoom': 5
      },
      {
        'opacity': 0.6,
        'zoom': 6
      },
      {
        'opacity': 0.6,
        'zoom': 7
      },
      {
        'opacity': 0.6,
        'zoom': 8
      },
      {
        'opacity': 0.6,
        'zoom': 9
      },
      {
        'opacity': 0.6,
        'zoom': 10
      },
      {
        'opacity': 0.6,
        'zoom': 11
      },
      {
        'opacity': 0.6,
        'zoom': 12
      },
      {
        'opacity': 0.6,
        'zoom': 13
      },
      {
        'opacity': 0.6,
        'zoom': 14
      },
      {
        'opacity': 0.5,
        'zoom': 15
      },
      {
        'opacity': 0.4,
        'zoom': 16
      },
      {
        'opacity': 0.4,
        'zoom': 17
      },
      {
        'opacity': 0.4,
        'zoom': 18
      },
      {
        'opacity': 0.4,
        'zoom': 19
      },
      {
        'opacity': 0.4,
        'zoom': 20
      },
      {
        'opacity': 0.4,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'transit_schema',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'opacity': 0
      }
    ]
  },
  {
    'tags': 'transit_line',
    'elements': 'geometry.fill.pattern',
    'stylers': [
      {
        'color': '#a3b5c2'
      },
      {
        'opacity': 0,
        'zoom': 0
      },
      {
        'opacity': 0,
        'zoom': 1
      },
      {
        'opacity': 0,
        'zoom': 2
      },
      {
        'opacity': 0,
        'zoom': 3
      },
      {
        'opacity': 0,
        'zoom': 4
      },
      {
        'opacity': 0,
        'zoom': 5
      },
      {
        'opacity': 0,
        'zoom': 6
      },
      {
        'opacity': 0,
        'zoom': 7
      },
      {
        'opacity': 0,
        'zoom': 8
      },
      {
        'opacity': 0,
        'zoom': 9
      },
      {
        'opacity': 0,
        'zoom': 10
      },
      {
        'opacity': 0,
        'zoom': 11
      },
      {
        'opacity': 0,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'transit_line',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#a3b5c2'
      },
      {
        'scale': 0.4
      },
      {
        'opacity': 0,
        'zoom': 0
      },
      {
        'opacity': 0,
        'zoom': 1
      },
      {
        'opacity': 0,
        'zoom': 2
      },
      {
        'opacity': 0,
        'zoom': 3
      },
      {
        'opacity': 0,
        'zoom': 4
      },
      {
        'opacity': 0,
        'zoom': 5
      },
      {
        'opacity': 0,
        'zoom': 6
      },
      {
        'opacity': 0,
        'zoom': 7
      },
      {
        'opacity': 0,
        'zoom': 8
      },
      {
        'opacity': 0,
        'zoom': 9
      },
      {
        'opacity': 0,
        'zoom': 10
      },
      {
        'opacity': 0,
        'zoom': 11
      },
      {
        'opacity': 0,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'water',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#95d0fe',
        'zoom': 0
      },
      {
        'color': '#95d0fe',
        'zoom': 1
      },
      {
        'color': '#95d0fe',
        'zoom': 2
      },
      {
        'color': '#95d0fe',
        'zoom': 3
      },
      {
        'color': '#95d0fe',
        'zoom': 4
      },
      {
        'color': '#95d0fe',
        'zoom': 5
      },
      {
        'color': '#95d0fe',
        'zoom': 6
      },
      {
        'color': '#95d0fe',
        'zoom': 7
      },
      {
        'color': '#98d2fe',
        'zoom': 8
      },
      {
        'color': '#9cd3fe',
        'zoom': 9
      },
      {
        'color': '#9fd5fe',
        'zoom': 10
      },
      {
        'color': '#a1d6fe',
        'zoom': 11
      },
      {
        'color': '#a2d6fe',
        'zoom': 12
      },
      {
        'color': '#a4d7fe',
        'zoom': 13
      },
      {
        'color': '#a6d8fe',
        'zoom': 14
      },
      {
        'color': '#a9d9fe',
        'zoom': 15
      },
      {
        'color': '#abdafe',
        'zoom': 16
      },
      {
        'color': '#aedbfe',
        'zoom': 17
      },
      {
        'color': '#b0ddfe',
        'zoom': 18
      },
      {
        'color': '#b3defe',
        'zoom': 19
      },
      {
        'color': '#b5dffe',
        'zoom': 20
      },
      {
        'color': '#b8e0fe',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'water',
    'elements': 'geometry',
    'types': 'polyline',
    'stylers': [
      {
        'opacity': 0.4,
        'zoom': 0
      },
      {
        'opacity': 0.4,
        'zoom': 1
      },
      {
        'opacity': 0.4,
        'zoom': 2
      },
      {
        'opacity': 0.4,
        'zoom': 3
      },
      {
        'opacity': 0.6,
        'zoom': 4
      },
      {
        'opacity': 0.8,
        'zoom': 5
      },
      {
        'opacity': 1,
        'zoom': 6
      },
      {
        'opacity': 1,
        'zoom': 7
      },
      {
        'opacity': 1,
        'zoom': 8
      },
      {
        'opacity': 1,
        'zoom': 9
      },
      {
        'opacity': 1,
        'zoom': 10
      },
      {
        'opacity': 1,
        'zoom': 11
      },
      {
        'opacity': 1,
        'zoom': 12
      },
      {
        'opacity': 1,
        'zoom': 13
      },
      {
        'opacity': 1,
        'zoom': 14
      },
      {
        'opacity': 1,
        'zoom': 15
      },
      {
        'opacity': 1,
        'zoom': 16
      },
      {
        'opacity': 1,
        'zoom': 17
      },
      {
        'opacity': 1,
        'zoom': 18
      },
      {
        'opacity': 1,
        'zoom': 19
      },
      {
        'opacity': 1,
        'zoom': 20
      },
      {
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'bathymetry',
    'elements': 'geometry',
    'stylers': [
      {
        'hue': '#95d0fe'
      }
    ]
  },
  {
    'tags': {
      'any': [
        'industrial',
        'construction_site'
      ]
    },
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#cceaff',
        'zoom': 0
      },
      {
        'color': '#cceaff',
        'zoom': 1
      },
      {
        'color': '#cceaff',
        'zoom': 2
      },
      {
        'color': '#cceaff',
        'zoom': 3
      },
      {
        'color': '#cceaff',
        'zoom': 4
      },
      {
        'color': '#cceaff',
        'zoom': 5
      },
      {
        'color': '#cceaff',
        'zoom': 6
      },
      {
        'color': '#cceaff',
        'zoom': 7
      },
      {
        'color': '#cceaff',
        'zoom': 8
      },
      {
        'color': '#cceaff',
        'zoom': 9
      },
      {
        'color': '#cceaff',
        'zoom': 10
      },
      {
        'color': '#cceaff',
        'zoom': 11
      },
      {
        'color': '#cceaff',
        'zoom': 12
      },
      {
        'color': '#cceaff',
        'zoom': 13
      },
      {
        'color': '#d6eeff',
        'zoom': 14
      },
      {
        'color': '#e0f2ff',
        'zoom': 15
      },
      {
        'color': '#e2f3ff',
        'zoom': 16
      },
      {
        'color': '#e4f4ff',
        'zoom': 17
      },
      {
        'color': '#e5f4ff',
        'zoom': 18
      },
      {
        'color': '#e7f5ff',
        'zoom': 19
      },
      {
        'color': '#e9f6ff',
        'zoom': 20
      },
      {
        'color': '#ebf7ff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': 'transit',
      'none': [
        'transit_location',
        'transit_line',
        'transit_schema',
        'is_unclassified_transit'
      ]
    },
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#cceaff',
        'zoom': 0
      },
      {
        'color': '#cceaff',
        'zoom': 1
      },
      {
        'color': '#cceaff',
        'zoom': 2
      },
      {
        'color': '#cceaff',
        'zoom': 3
      },
      {
        'color': '#cceaff',
        'zoom': 4
      },
      {
        'color': '#cceaff',
        'zoom': 5
      },
      {
        'color': '#cceaff',
        'zoom': 6
      },
      {
        'color': '#cceaff',
        'zoom': 7
      },
      {
        'color': '#cceaff',
        'zoom': 8
      },
      {
        'color': '#cceaff',
        'zoom': 9
      },
      {
        'color': '#cceaff',
        'zoom': 10
      },
      {
        'color': '#cceaff',
        'zoom': 11
      },
      {
        'color': '#cceaff',
        'zoom': 12
      },
      {
        'color': '#cceaff',
        'zoom': 13
      },
      {
        'color': '#d6eeff',
        'zoom': 14
      },
      {
        'color': '#e0f2ff',
        'zoom': 15
      },
      {
        'color': '#e2f3ff',
        'zoom': 16
      },
      {
        'color': '#e4f4ff',
        'zoom': 17
      },
      {
        'color': '#e5f4ff',
        'zoom': 18
      },
      {
        'color': '#e7f5ff',
        'zoom': 19
      },
      {
        'color': '#e9f6ff',
        'zoom': 20
      },
      {
        'color': '#ebf7ff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'fence',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#b8e1ff'
      },
      {
        'opacity': 0.75,
        'zoom': 0
      },
      {
        'opacity': 0.75,
        'zoom': 1
      },
      {
        'opacity': 0.75,
        'zoom': 2
      },
      {
        'opacity': 0.75,
        'zoom': 3
      },
      {
        'opacity': 0.75,
        'zoom': 4
      },
      {
        'opacity': 0.75,
        'zoom': 5
      },
      {
        'opacity': 0.75,
        'zoom': 6
      },
      {
        'opacity': 0.75,
        'zoom': 7
      },
      {
        'opacity': 0.75,
        'zoom': 8
      },
      {
        'opacity': 0.75,
        'zoom': 9
      },
      {
        'opacity': 0.75,
        'zoom': 10
      },
      {
        'opacity': 0.75,
        'zoom': 11
      },
      {
        'opacity': 0.75,
        'zoom': 12
      },
      {
        'opacity': 0.75,
        'zoom': 13
      },
      {
        'opacity': 0.75,
        'zoom': 14
      },
      {
        'opacity': 0.75,
        'zoom': 15
      },
      {
        'opacity': 0.75,
        'zoom': 16
      },
      {
        'opacity': 0.45,
        'zoom': 17
      },
      {
        'opacity': 0.45,
        'zoom': 18
      },
      {
        'opacity': 0.45,
        'zoom': 19
      },
      {
        'opacity': 0.45,
        'zoom': 20
      },
      {
        'opacity': 0.45,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'medical',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#cceaff',
        'zoom': 0
      },
      {
        'color': '#cceaff',
        'zoom': 1
      },
      {
        'color': '#cceaff',
        'zoom': 2
      },
      {
        'color': '#cceaff',
        'zoom': 3
      },
      {
        'color': '#cceaff',
        'zoom': 4
      },
      {
        'color': '#cceaff',
        'zoom': 5
      },
      {
        'color': '#cceaff',
        'zoom': 6
      },
      {
        'color': '#cceaff',
        'zoom': 7
      },
      {
        'color': '#cceaff',
        'zoom': 8
      },
      {
        'color': '#cceaff',
        'zoom': 9
      },
      {
        'color': '#cceaff',
        'zoom': 10
      },
      {
        'color': '#cceaff',
        'zoom': 11
      },
      {
        'color': '#cceaff',
        'zoom': 12
      },
      {
        'color': '#cceaff',
        'zoom': 13
      },
      {
        'color': '#d6eeff',
        'zoom': 14
      },
      {
        'color': '#e0f2ff',
        'zoom': 15
      },
      {
        'color': '#e2f3ff',
        'zoom': 16
      },
      {
        'color': '#e4f4ff',
        'zoom': 17
      },
      {
        'color': '#e5f4ff',
        'zoom': 18
      },
      {
        'color': '#e7f5ff',
        'zoom': 19
      },
      {
        'color': '#e9f6ff',
        'zoom': 20
      },
      {
        'color': '#ebf7ff',
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'beach',
    'elements': 'geometry',
    'stylers': [
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 0
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 1
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 2
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 3
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 4
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 5
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 6
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 7
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 8
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 9
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 10
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 11
      },
      {
        'color': '#cceaff',
        'opacity': 0.3,
        'zoom': 12
      },
      {
        'color': '#cceaff',
        'opacity': 0.65,
        'zoom': 13
      },
      {
        'color': '#d6eeff',
        'opacity': 1,
        'zoom': 14
      },
      {
        'color': '#e0f2ff',
        'opacity': 1,
        'zoom': 15
      },
      {
        'color': '#e2f3ff',
        'opacity': 1,
        'zoom': 16
      },
      {
        'color': '#e4f4ff',
        'opacity': 1,
        'zoom': 17
      },
      {
        'color': '#e5f4ff',
        'opacity': 1,
        'zoom': 18
      },
      {
        'color': '#e7f5ff',
        'opacity': 1,
        'zoom': 19
      },
      {
        'color': '#e9f6ff',
        'opacity': 1,
        'zoom': 20
      },
      {
        'color': '#ebf7ff',
        'opacity': 1,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'all': [
        'is_tunnel',
        'path'
      ]
    },
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#6dbffd'
      },
      {
        'opacity': 0.3
      }
    ]
  },
  {
    'tags': {
      'all': [
        'is_tunnel',
        'path'
      ]
    },
    'elements': 'geometry.outline',
    'stylers': [
      {
        'opacity': 0
      }
    ]
  },
  {
    'tags': 'road_limited',
    'elements': 'geometry.fill',
    'stylers': [
      {
        'color': '#90cefd'
      },
      {
        'scale': 0,
        'zoom': 0
      },
      {
        'scale': 0,
        'zoom': 1
      },
      {
        'scale': 0,
        'zoom': 2
      },
      {
        'scale': 0,
        'zoom': 3
      },
      {
        'scale': 0,
        'zoom': 4
      },
      {
        'scale': 0,
        'zoom': 5
      },
      {
        'scale': 0,
        'zoom': 6
      },
      {
        'scale': 0,
        'zoom': 7
      },
      {
        'scale': 0,
        'zoom': 8
      },
      {
        'scale': 0,
        'zoom': 9
      },
      {
        'scale': 0,
        'zoom': 10
      },
      {
        'scale': 0,
        'zoom': 11
      },
      {
        'scale': 0,
        'zoom': 12
      },
      {
        'scale': 0.1,
        'zoom': 13
      },
      {
        'scale': 0.2,
        'zoom': 14
      },
      {
        'scale': 0.3,
        'zoom': 15
      },
      {
        'scale': 0.5,
        'zoom': 16
      },
      {
        'scale': 0.6,
        'zoom': 17
      },
      {
        'scale': 0.7,
        'zoom': 18
      },
      {
        'scale': 0.79,
        'zoom': 19
      },
      {
        'scale': 0.83,
        'zoom': 20
      },
      {
        'scale': 0.9,
        'zoom': 21
      }
    ]
  },
  {
    'tags': 'road_limited',
    'elements': 'geometry.outline',
    'stylers': [
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 0
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 1
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 2
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 3
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 4
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 5
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 6
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 7
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 8
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 9
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 10
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 11
      },
      {
        'color': '#ffffff',
        'scale': 1.4,
        'zoom': 12
      },
      {
        'color': '#ffffff',
        'scale': 0.1,
        'zoom': 13
      },
      {
        'color': '#e1f2fe',
        'scale': 0.2,
        'zoom': 14
      },
      {
        'color': '#e1f2fe',
        'scale': 0.3,
        'zoom': 15
      },
      {
        'color': '#e5f3fe',
        'scale': 0.5,
        'zoom': 16
      },
      {
        'color': '#e8f5fe',
        'scale': 0.6,
        'zoom': 17
      },
      {
        'color': '#ecf6ff',
        'scale': 0.7,
        'zoom': 18
      },
      {
        'color': '#f0f8ff',
        'scale': 1.18,
        'zoom': 19
      },
      {
        'color': '#f2f9ff',
        'scale': 1.23,
        'zoom': 20
      },
      {
        'color': '#f5fbff',
        'scale': 1.33,
        'zoom': 21
      }
    ]
  },
  {
    'tags': {
      'any': [
        'industrial',
        'construction_site',
        'medical',
        'sports_ground',
        'beach'
      ]
    },
    'types': 'polygon',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': 'transit',
      'none': [
        'transit_location',
        'transit_line',
        'transit_schema',
        'is_unclassified_transit'
      ]
    },
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': 'urban_area',
      'none': [
        'residential',
        'industrial',
        'cemetery',
        'park',
        'medical',
        'sports_ground',
        'beach',
        'construction_site'
      ]
    },
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'traffic_light'
      ]
    },
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'entrance'
      ]
    },
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'road'
      ],
      'none': [
        'road_1',
        'road_2',
        'road_3',
        'road_4',
        'road_5',
        'road_6',
        'road_7'
      ]
    },
    'elements': 'label.icon',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'district'
      ]
    },
    'elements': 'label',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': 'admin',
      'none': [
        'country',
        'region',
        'locality',
        'district',
        'address'
      ]
    },
    'elements': 'label',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'road_5',
        'road_6'
      ]
    },
    'elements': 'label',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': [
        'address',
        'road_7',
        'road_limited',
        'road_unclassified',
        'road_minor',
        'road_construction',
        'path'
      ]
    },
    'elements': 'label',
    'stylers': {
      'visibility': 'off'
    }
  },
  {
    'tags': {
      'any': 'landcover',
      'none': 'vegetation'
    },
    'stylers': {
      'visibility': 'off'
    }
  }
]
