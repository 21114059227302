import { ready } from '../../../js/utils/documentReady'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  const diagramEl = document.querySelectorAll('.js-diagramEl')
  const diagramInfoEl = document.querySelectorAll('.js-diagramInfoEl')
  const progress = document.querySelector('.diagram-circle__progress')
  
  if(diagramEl) {
    const adaptiveConfig = {
      desktop:[
        {r: 318, cx: 333, cy: 333},
        {r: 278, cx: 294, cy: 294},
        {r: 238, cx: 253, cy: 253},
        {r: 198, cx: 213, cy: 213}
      ],
      netbook: [
        {r: 259, cx: 271, cy: 272},
        {r: 226.446, cx: 239, cy: 239},
        {r: 193.893, cx: 206, cy: 206},
        {r: 161.339, cx: 174, cy: 174}
      ],
      tablet: [
        {r: 302.5, cx: 316.5, cy: 316.973},
        {r: 264.477, cx: 279, cy: 279},
        {r: 226.454, cx: 240.5, cy: 241},
        {r: 188.431, cx: 202.5, cy: 202.5}
      ],
      mobile: [
        {r: 137.054, cx: 143.227, cy: 143.173},
        {r: 119.781, cx: 126.228, cy: 126.174},
        {r: 102.509, cx: 110, cy: 110},
        {r: 85.2367, cx: 91, cy: 91}
      ]
    }

    const {isDesktop, isNetbook, isTablet} = getSize()
    let config

    if(isDesktop) {
      config = adaptiveConfig.desktop
    } else if(isNetbook) {
      config = adaptiveConfig.netbook
    } else if(isTablet) {
      config = adaptiveConfig.tablet
    } else {
      config = adaptiveConfig.mobile
    }

    const circleAllEL = document.querySelectorAll('.diagram-circle__item')

    circleAllEL.forEach((el, index) => {

      const circleSvg = el.querySelectorAll('circle')

      circleSvg.forEach((circleEl) => {
        circleEl.setAttribute('r', config[index].r)
        circleEl.setAttribute('cx', config[index].cx)
        circleEl.setAttribute('cy', config[index].cy)
      })
    })
    
    let activeCircleIndex= 0
    let autoSwitchInterval

    const targetElement = document.querySelector('.diagram-circle__list')
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        startAnimation()
        observer.unobserve(targetElement)
      }
    }, { threshold: 0.5 })
    
    if (targetElement) {
      observer.observe(targetElement)
    }
    
    function startAnimation() {
      startDataAnimation('start')
      autoSwitchInterval = setInterval(nextCircle, 4000)
    }

    function disabledAllCircle() {
      diagramEl.forEach((e)  => {
        // отключаем все прогрессбары
        e.classList.add('disable')
      })

      diagramInfoEl.forEach((e)  => {
        // затемняем все инфо бары
        e.classList.add('disable')
      })
    }

    function nextCircle(index, isStop) {
      disabledAllCircle()

      // обнуляем счеткчик если больше 4
      if (activeCircleIndex === 5) {
        activeCircleIndex = 0
      } else {
        
        // if (index !== 0) {
        if (!isStop) {
          activeCircleIndex++
        } else {
          activeCircleIndex = index
        }
        
        // выбираем прогрессбар в соответствии с номером в счетчике
        const circleEL = document.querySelector(`.diagram-circle__item-${activeCircleIndex}`)
        const infoEL = document.querySelector(`.diagram-circle__percent-item-${activeCircleIndex}`)
        if (circleEL) {
          circleEL.classList.remove('disable')
          infoEL.classList.remove('disable')
          circleEL.classList.add(`diagram-circle__item-${activeCircleIndex}`, 'active')
          infoEL.classList.add(`diagram-circle__percent-item-${activeCircleIndex}`, 'active')
          
          circleEL.classList.remove('active')
          infoEL.classList.remove('active')

          if (activeCircleIndex === 4) {
            activeCircleIndex = 0
            startDataAnimation('start')
          }
          
          if (isStop) {
            progress.classList.add('diagram-circle__progress_disabled')
          } else  {
            timerNext()
          }
        }
      }
    }
    
    function timerNext() {
      progress.classList.add('diagram-circle__progress_disabled')
      setTimeout(() => {
        progress.classList.remove('diagram-circle__progress_disabled')
      }, 20)
    }

    diagramInfoEl.forEach((item, index) => {
      item.onmouseenter = function() {
        handleMouseEnter(index)
      }
      item.onmouseleave = handleMouseLeave
    })

    diagramEl.forEach((item, index) => {
      item.onmouseenter = function() {
        handleMouseEnter(index)
      }
      item.onmouseleave = handleMouseLeave
    })

    function handleMouseEnter(index) {
      // const index = Array.prototype.indexOf.call(this.parentNode.children, this);
      stopAutoSwitch(index + 1)
      progress.classList.add('diagram-circle__progress_disabled')
    }

    function handleMouseLeave() {
      startAnimation()
      progress.classList.remove('diagram-circle__progress_disabled')
    }

    function stopAutoSwitch(index) {
      progress.classList.add('diagram-circle__progress_disabled')
      disabledAllCircle()
      nextCircle(index, true)
      clearInterval(autoSwitchInterval)
    }
  }
  
  function startDataAnimation(i) {
    diagramEl.forEach((item) => {
      const realBar = item.querySelector('.js-diagramEl #bar')
      const rad = realBar.getAttribute('r')
      const circle = Math.PI*(rad*2)
      const dataPct = item.getAttribute('data-pct')
      let circleValSize = i === 'start' ? dataPct : 100
      
      setTimeout(() => {
        realBar.style.strokeDashoffset = ((100 - circleValSize) / 100) * circle
      },1000)
    })
  }
})
