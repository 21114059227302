import { ready } from '../../../js/utils/documentReady'
import { renderMonth } from './renderMonth'
import { generateCalendar } from './generateCalendar'

ready(() => {
  const eventProgramElement = document.querySelector('.event-program')

  if (eventProgramElement) {
    const isMultiple = eventProgramElement.dataset.isMultiple
    const tagSelector = eventProgramElement.dataset.tagSelector
    const resultElement = tagSelector
      ? document.querySelector(tagSelector || '')
      : undefined

    const inputElement = eventProgramElement.querySelector(
      '.event-program__input',
    )

    const dates = eventProgramElement.dataset.dates?.split(',')

    const isDropActiveDay = eventProgramElement.dataset.dropActiveElement

    const startDate = stringToDate(dates[0])
    const endDate = stringToDate(dates[dates.length - 1])

    let startDay = null
    let endDay = null

    const adjustedStartDate = adjustDateByDays(startDate, -7)

    const adjustedEndDate = adjustDateByDays(endDate, 7)

    const calendar = generateCalendar(
      adjustedStartDate,
      adjustedEndDate,
      dates,
      isDropActiveDay,
    )

    if (resultElement) {
      resultElement?.addEventListener('click', (event) => {
        if (
          event.target.classList.contains('select-tags__favorites-tag-icon')
        ) {
          const inputName = event.target.getAttribute('data-input')

          if (inputName === 'range-input') {
            event.target.parentElement.remove()

            inputElement.value = ''

            const customEvent = new Event('change', {
              bubbles: true,
              cancelable: true,
            })

            inputElement.dispatchEvent(customEvent)
          }
        }
      })
    }

    const changeEvent = () => {
      const resultElement = document.querySelector(tagSelector)
      if (resultElement) {
        if (inputElement.value) {
          if (tagSelector) {
            const removeElements = resultElement.querySelectorAll(
              '.select-tags__favorites-tag [data-input="range-input"]',
            )

            removeElements?.forEach((element) => {
              element.parentElement.remove()
            })

            const renderTag = () => {
              const tag = document.createElement('div')
              tag.classList.add('select-tags__favorites-tag')

              const title = document.createElement('div')
              title.classList.add('select-tags__favorites-tag-title')

              const values = inputElement.value?.split(' - ')

              if (values?.length > 1 && values[0] === values[1]) {
                title.textContent = values[0]
              } else {
                title.textContent = inputElement.value
              }

              const icon = document.createElement('div')
              icon.classList.add('select-tags__favorites-tag-icon')
              icon.setAttribute('data-input', 'range-input')
              icon.setAttribute('data-value', inputElement.value)

              tag.appendChild(title)
              tag.appendChild(icon)

              return tag
            }

            if (
              !resultElement.querySelector(
                `.select-tags__favorites-tag [data-input="range-input"][data-value="${inputElement.value}"]`,
              )
            ) {
              const newTag = renderTag()
              resultElement.appendChild(newTag)
            }
          }
        } else {
          const daysComponents = document.querySelectorAll(
            '.event-program__day',
          )

          const removeElements = resultElement.querySelectorAll(
            '.select-tags__favorites-tag [data-input="range-input"]',
          )

          removeElements?.forEach((element) => {
            element.parentElement.remove()
          })

          startDay = null
          endDay = null

          daysComponents.forEach((day) => {
            day.classList.remove(
              'event-program__day_active',
              'event-program__day_start-range',
              'event-program__day_end-range',
              'event-program__day_selected',
            )
          })
        }
      }
    }

    inputElement.addEventListener('change', changeEvent)
    changeEvent()

    function stringToDate(dateString) {
      const [day, month, year] = dateString.split('.').map(Number)
      return new Date(year, month - 1, day)
    }

    function adjustDateByDays(date, days) {
      const adjustedDate = new Date(date)
      adjustedDate.setDate(date.getDate() + days)
      return adjustedDate
    }

    setTimeout(() => {

      const prevButtonElement = eventProgramElement.querySelector(
        '.event-program__filter-arrow_prev',
      )
      const nextButtonElement = eventProgramElement.querySelector(
        '.event-program__filter-arrow_next',
      )
      const filterInnerElement = eventProgramElement.querySelector(
        '.event-program__filter-inner',
      )
      const dayElements = eventProgramElement.querySelectorAll(
        '.event-program__day',
      )

      let activeIndex = null

      if (inputElement.value && isMultiple) {
        const [start] = inputElement.value.split(' - ')
        const index = Array.from(dayElements).findIndex(
          (element) => element.getAttribute('data-date') === start,
        )

        if (index !== -1) {
          activeIndex = index
        }
      }

      if (activeIndex === null) {
        for (let i = 0; i < dayElements.length; i++) {
          if (
            dayElements[i].classList.contains('event-program__day_active')
          ) {
            activeIndex = i
            break
          }
        }
      }

      if (activeIndex === null) {
        for (let i = 0; i < dayElements.length; i++) {
          if (dayElements[i].classList.contains('event-program__day_today')) {
            activeIndex = i
            break
          }
        }
      }

      function isElementInViewport(el) {
        const rect = el.getBoundingClientRect()
        const containerRect = filterInnerElement.getBoundingClientRect()
        return (
          rect.left >= containerRect.left && rect.right <= containerRect.right
        )
      }

      function updateActiveIndex() {
        for (let i = 0; i < dayElements.length; i++) {
          if (isElementInViewport(dayElements[i])) {
            activeIndex = i
            break
          }
        }
      }

      function scrollToElement(index) {
        if (index < 0) {
          filterInnerElement.scrollTo({
            left: 0,
            behavior: 'smooth',
          })
          return
        }

        if (index >= dayElements.length) {
          filterInnerElement.scrollTo({
            left: filterInnerElement.scrollWidth,
            behavior: 'smooth',
          })
          return
        }

        const targetElement = dayElements[index]
        filterInnerElement.scrollTo({
          left: targetElement.offsetLeft - filterInnerElement.offsetLeft,
          behavior: 'smooth',
        })

        updateActiveIndex()
      }

      prevButtonElement.addEventListener('click', () => {
        scrollToElement(activeIndex - 10)
      })

      nextButtonElement.addEventListener('click', () => {
        scrollToElement(activeIndex + 10)
      })

      filterInnerElement.addEventListener('scroll', function () {
        updateActiveIndex()
      })

      if(!inputElement.value) {
        scrollToElement(activeIndex || 0)
      }

      if (isMultiple) {
        const daysComponents = document.querySelectorAll('.event-program__day')

        for (let dayComponent of daysComponents) {
          dayComponent.addEventListener('click', async () => {
            if (!startDay || endDay) {
              // Начало нового диапазона
              clearRange()
              startDay = dayComponent
              endDay = null
              startDay.classList.add(
                'event-program__day_active',
                'event-program__day_start-range',
              )

              inputElement.value = `${startDay.dataset.date} - ${startDay.dataset.date}`

              const event = new Event('change', {
                bubbles: true,
                cancelable: true,
              })

              inputElement.dispatchEvent(event)
            } else {
              endDay = dayComponent
              highlightRange(startDay, endDay)

              if (isEndDayValid(startDay, dayComponent)) {
                inputElement.value = `${startDay.dataset.date} - ${endDay.dataset.date}`
              } else {
                inputElement.value = `${endDay.dataset.date} - ${startDay.dataset.date}`
              }

              const event = new Event('change', {
                bubbles: true,
                cancelable: true,
              })

              inputElement.dispatchEvent(event)
            }
          })

          dayComponent.addEventListener('mouseover', () => {
            if (startDay && !endDay) {
              clearRange(false)
              highlightRange(startDay, dayComponent)
            }
          })

          dayComponent.addEventListener('mouseout', () => {
            if (startDay && !endDay) {
              clearRange(false)
              startDay.classList.add(
                'event-program__day_active',
                'event-program__day_start-range',
              )
            }
          })
        }

        function clearRange(clearStart = true, ) {
          if (clearStart) {
            startDay = null
          }
          daysComponents.forEach((day) => {
            day.classList.remove(
              'event-program__day_start-range',
              // 'event-program__day_active',
              'event-program__day_end-range',
              'event-program__day_selected',
            )

            if(day !== startDay) {
              day.classList.remove(
                'event-program__day_active',
              )
            }
          })
        }

        function highlightRange(start, end) {
          let inRange = false
          for (let day of daysComponents) {
            if (day === start || day === end) {
              inRange = !inRange
              day.classList.add(
                'event-program__day_active',
                inRange
                  ? 'event-program__day_start-range'
                  : 'event-program__day_end-range',
              )
            }

            if (start === end && day === start) {
              break
            }

            if (inRange || day === start || day === end) {
              day.classList.add('event-program__day_selected')
            }
          }
        }

        function isEndDayValid(start, end) {
          return (
            Array.from(daysComponents).indexOf(end) >=
            Array.from(daysComponents).indexOf(start)
          )
        }

        if (inputElement.value) {
          const [start, end] = inputElement.value.split(' - ')
          const startElement = document.querySelector(
            `.event-program__day[data-date="${start}"]`,
          )
          const endElement = document.querySelector(
            `.event-program__day[data-date="${end}"]`,
          )
          highlightRange(startElement, endElement)

          const allDays = document.querySelectorAll('.event-program__day')

          let index = -1
          allDays.forEach((element, i) => {
            if (element.getAttribute('data-date') === start) {
              index = i
            }
          })

          scrollToElement(index)
        }
      } else {
        const daysComponents = document.querySelectorAll('.event-program__day')

        for (let dayComponent of daysComponents) {
          dayComponent.addEventListener('click', async () => {
            if (!dayComponent.classList.contains('event-program__day_active')) {
              const activeDay = document.querySelector(
                '.event-program__day_active',
              )

              activeDay?.classList.remove('event-program__day_active')
              dayComponent.classList.add('event-program__day_active')
              const date = dayComponent.dataset.date

              inputElement.value = date

              const event = new Event('change', {
                bubbles: true,
                cancelable: true,
              })

              inputElement.dispatchEvent(event)
            }
          })
        }

        if (inputElement.value) {
          const day = inputElement.value
          const dayElement = document.querySelector(
            `.event-program__day[data-date="${day}"]`,
          )
          dayElement.classList.add('event-program__day_active')

          const allDays = document.querySelectorAll('.event-program__day')

          let index = -1
          allDays.forEach((element, i) => {
            if (element.getAttribute('data-date') === day) {
              index = i
            }
          })

          scrollToElement(index)
        }
      }
    }, 0)

    setTimeout(() => {
      calendar.map((month) => {
        month.days.map((i) => {
          if (i.isActive) {
            if (isMultiple) {
              inputElement.value = `${i.formattedDate}-${i.formattedDate}`
            } else {
              inputElement.value = i.formattedDate
            }

            const event = new Event('change', {
              bubbles: true,
              cancelable: true,
            })

            inputElement.dispatchEvent(event)
          }
        })
      })
    }, 0)

    document.querySelector('.event-program__filter').innerHTML = calendar
      .map((item) => renderMonth(item))
      .join('')
  }
})
