import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'
import {loadImages} from '../../../js/utils/loadImage'

ready(() => {
  const galleries = document.querySelectorAll('.gallery')
  const { isMobile } = getSize()

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px 75px 0px',
    threshold: 0
  }

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const gallery = entry.target
        const swiperContainer = gallery.querySelector('.js-swiperGalleryImages')
        const pagination = gallery.querySelector('.js-swiperGalleryImagesPagination')
        const prevButton = isMobile ? gallery.querySelector('.js-galleryImagesNavigationItemPrev') : gallery.querySelector('.gallery__images-navigation-item_prev')
        const nextButton = isMobile ? gallery.querySelector('.js-galleryImagesNavigationItemNext') : gallery.querySelector('.gallery__images-navigation-item_next')
        const slides = swiperContainer?.querySelectorAll('.swiper-slide')
        const navigation = gallery.querySelector('.gallery__images-navigation_mobile')

        if (slides?.length) {
          const swiperWrapper = swiperContainer.querySelector('.swiper-wrapper')

          if (slides.length <= 3 && slides.length !== 1) {
            const slidesArray = Array.from(slides)
            while (swiperWrapper.children.length < 6) {
              slidesArray.forEach(slide => {
                swiperWrapper.appendChild(slide.cloneNode(true))
              })
            }
          }

          new Swiper(swiperContainer, {
            modules: [Navigation, Pagination],
            loop: true,
            spaceBetween: 40,
            slidesPerView: slides.length === 1 ? 1 : 'auto',
            initialSlide: 1,
            loopAdditionalSlides: 1,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            navigation: {
              prevEl: prevButton,
              nextEl: nextButton,
            },
            speed: 500,
            pagination: {
              el: pagination,
              clickable: true,
              bulletClass: 'gallery__pagination-bullet',
              bulletActiveClass: 'gallery__pagination-bullet_active',
            },
            breakpoints: {
              320: {
                spaceBetween: 20,
                centeredSlides: true,
                initialSlide: 0,
              },
              768: {
                initialSlide: 1,
                spaceBetween: 20,
                slidesPerView: slides.length === 1 ? 1 : 'auto',
                centeredSlides: false,
              },
              1025: {
                spaceBetween: 40,
              },
            },
            on: {
              init() {
                this.update()

                if (this.slides.length === 1) {
                  swiperContainer.classList.add('swiper-single-slide')
                }

                loadImages(this.slides[this.activeIndex])
                if (this.slides[this.activeIndex + 1]) {
                  loadImages(this.slides[this.activeIndex + 1])
                }

                if (this.slides[this.activeIndex - 1]) {
                  loadImages(this.slides[this.activeIndex - 1])
                }

                if (this.slides.length <= 1) {
                  navigation.classList.add('swiper-navigation-lock')
                  prevButton.classList.add('swiper-button-lock')
                  nextButton.classList.add('swiper-button-lock')
                  // pagination.classList.add('swiper-pagination-lock')
                } else {
                  navigation.classList.remove('swiper-navigation-lock')
                  prevButton.classList.remove('swiper-button-lock')
                  nextButton.classList.remove('swiper-button-lock')
                  // pagination.classList.remove('swiper-pagination-lock')
                }
              },
              slideChange() {
                loadImages(this.slides[this.activeIndex])
                if (this.slides[this.activeIndex + 1]) {
                  loadImages(this.slides[this.activeIndex + 1])
                }
                if (this.slides[this.activeIndex - 1]) {
                  loadImages(this.slides[this.activeIndex - 1])
                }
              },
            },
          })
        }

        // Отключаем наблюдатель после инициализации галереи
        observer.unobserve(gallery)
      }
    })
  }

  const observer = new IntersectionObserver(observerCallback, observerOptions)

  galleries.forEach(gallery => {
    observer.observe(gallery)
  })
})
