import { ready } from '../../../../js/utils/documentReady'
import Inputmask from 'inputmask'

ready(() => {
  for(let phoneElement of document.querySelectorAll('.js-phone')) {
    const im = new Inputmask({
      mask: '+7 999 999-99-99',
      placeholder: '_',
      showMaskOnHover: false,
    })

    im.mask(phoneElement)
  }

  $('.js-phone').each(function() {
    $(this).rules('add', {
      stringPhone: true,
    })
  })
})
