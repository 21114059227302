import { animateNumber } from '../../../js/utils/animateNumber'
import { ready } from '../../../js/utils/documentReady'

export function wrapTextLines(element, wrapper) {
  const range = document.createRange()
  const lines = []
  let currentLineTop = null
  let currentLine = []

  for (let i = 0; i < element.childNodes.length; i++) {
    const node = element.childNodes[i]
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent
      for (let j = 0; j < textContent.length; j++) {
        range.setStart(node, j)
        range.setEnd(node, j + 1)
        const rects = range.getClientRects()
        if (rects.length > 0) {
          const rect = rects[0]
          if (currentLineTop === null) {
            currentLineTop = rect.top
          }
          if (rect.top !== currentLineTop) {
            lines.push(currentLine.join(''))
            currentLine = []
            currentLineTop = rect.top
          }
          currentLine.push(textContent[j])
        }
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      wrapTextLines(node)
    }
  }

  if (currentLine.length > 0) {
    lines.push(currentLine.join(''))
  }

  element.innerHTML = ''

  lines.forEach((line) => {
    const span = document.createElement('span')
    const div = document.createElement('div')
    span.textContent = line
    if (wrapper) {
      element.appendChild(div).appendChild(span)
    } else {
      element.appendChild(span)
    }
  })
}

ready(() => {
  const elements = document.querySelectorAll('.js-animation')
  const fadeTypeAnimation = [
    'fade-left-decor',
    'fade-right-decor',
    'fade-in-decor',
    'fade-up-decor',
    'fade-left',
    'fade-right'
  ]

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  }

  const observerCallback = function (entries, observer) {
    const element = this.element

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const delay = element.dataset.delay || '0s'
        const type = element.dataset.type || 'fade-in'
        const duration = element.dataset.duration || '1s'

        element.style.setProperty('--animation-duration', `${duration}`)
        observer.unobserve(entry.target)

        if (type === 'text-fade-in') {
          applyTextFadeInAnimation(element, parseFloat(delay))
        } else if (type === 'text-row-fade-in') {
          applyTextRowFadeInAnimation(element, parseFloat(delay))
        } else if (type === 'text-row-fade-up') {
          applyTextRowFadeUpAnimation(element, parseFloat(delay))
        } else if (fadeTypeAnimation.includes(type)) {
          applyDecorAnimation(element, parseFloat(duration), parseFloat(delay))
        } else if (type === 'scale-anim') {
          scaleAnim(element, parseFloat(duration), parseFloat(delay))
        } else if (type === 'number-fade') {
          setTimeout(() => {
            element.classList.add('visible')

            const animationCounts =
              element.querySelectorAll('.js-animationCount')

            if (animationCounts) {
              animationCounts.forEach((animationCount) => {
                const startValue = 0
                const endValue = animationCount.getAttribute('data-number')

                if (endValue) {
                  animateNumber(animationCount, startValue, endValue)
                }
              })
            }
          }, parseFloat(delay) * 1000)
        } else {
          setTimeout(() => {
            if (type === 'expand') {
              element.classList.add('expand-clip')

              setTimeout(() => {
                element.classList.add('visible')
              }, 10)
            } else {
              element.classList.add('visible')
            }
          }, parseFloat(delay) * 1000)
        }
      }
    })
  }

  elements.forEach((element) => {
    const delayLoad = element.dataset.delayLoad || '0s'
    const types = element.dataset.type || 'fade-in'
    const duration = element.dataset.duration || '1s'

    element.classList.add(types)
    element.style.setProperty('--animation-duration', `${duration}`)

    let elementIntersection = element

    if (element.dataset.elementIntersection) {
      elementIntersection =
        document.getElementById(element.dataset.elementIntersection) || element
    }

    if (elementIntersection.getBoundingClientRect().top < window.innerHeight) {
      if (types === 'text-fade-in') {
        applyTextFadeInAnimation(element, parseFloat(delayLoad))
      } else if (types === 'text-row-fade-in') {
        applyTextRowFadeInAnimation(element, parseFloat(delayLoad))
      } else if (types === 'text-row-fade-up') {
        applyTextRowFadeUpAnimation(element, parseFloat(delayLoad))
      } else if (fadeTypeAnimation.includes(types)) {
        applyDecorAnimation(
          element,
          parseFloat(duration),
          parseFloat(delayLoad),
        )
      } else if (types === 'scale-anim') {
        scaleAnim(element, parseFloat(delayLoad))
      } else if (types === 'number-fade') {
        setTimeout(() => {
          element.classList.add('visible')

          const animationCounts = element.querySelectorAll('.js-animationCount')

          if (animationCounts) {
            animationCounts.forEach((animationCount) => {
              const startValue = 0
              const endValue = animationCount.getAttribute('data-number')

              if (endValue) {
                animateNumber(animationCount, startValue, endValue)
              }
            })
          }
        }, parseFloat(delayLoad) * 1000)
      } else {
        setTimeout(() => {
          if (types === 'expand') {
            element.classList.add('expand-clip')

            setTimeout(() => {
              element.classList.add('visible')
            }, 10)
          } else {
            element.classList.add('visible')
          }
        }, parseFloat(delayLoad) * 1000)
      }
    } else {
      const observer = new IntersectionObserver(
        observerCallback.bind({ element }),
        observerOptions,
      )
      observer.observe(elementIntersection)
    }
  })

  function applyTextFadeInAnimation(element, initialDelay) {
    const wordDelay = element.dataset.wordDelay || '1s'
    const words = element.textContent.split(' ')
    element.textContent = ''

    words.forEach((word, index) => {
      const span = document.createElement('span')
      span.textContent = word + '\u00A0'
      span.classList.add('fade-in')
      span.style.setProperty(
        '--animation-duration',
        element.dataset.duration || '1s',
      )
      span.style.transitionDelay = `${
        initialDelay + index * parseFloat(wordDelay)
      }s`
      element.appendChild(span)
    })

    setTimeout(() => {
      const spans = element.querySelectorAll('span')
      spans.forEach((span) => span.classList.add('visible'))
      element.classList.add('visible')
    }, initialDelay * 1000)
  }

  function applyTextRowFadeInAnimation(element, initialDelay) {
    if (element.querySelector('span')) {
      return
    }
    wrapTextLines(element)

    const rows = element.querySelectorAll('span')
    const rowDelay = element.dataset.rowDelay || '0.3s'

    rows.forEach((row, index) => {
      row.classList.add('fade-in')
      row.style.setProperty(
        '--animation-duration',
        element.dataset.duration || '1s',
      )
      row.style.transitionDelay = `${
        initialDelay + index * parseFloat(rowDelay)
      }s`
    })

    setTimeout(() => {
      rows.forEach((row) => row.classList.add('visible'))
      element.classList.add('visible')
    }, initialDelay * 1000)
  }
  function applyTextRowFadeUpAnimation(element, initialDelay) {
    if (element.querySelector('span')) {
      return
    }

    wrapTextLines(element, true)

    const rows = element.querySelectorAll('span')
    const rowsWrapper = element.querySelectorAll('div')
    const rowDelay = element.dataset.rowDelay || '0.1s'

    rowsWrapper.forEach((wrap) => {
      wrap.classList.add('fade-text-up')
    })

    rows.forEach((row, index) => {
      row.classList.add('text-span-fade-up')
      row.style.setProperty(
        '--animation-duration',
        element.dataset.duration || '1s',
      )
      row.style.transitionDelay = `${
        initialDelay + index * parseFloat(rowDelay)
      }s`
    })

    setTimeout(() => {
      rows.forEach((row) => row.classList.add('visible'))
      element.classList.add('visible')
    }, initialDelay * 1000)
  }

  function applyDecorAnimation(element, duration, delay) {
    setTimeout(() => {
      const type = element.getAttribute('data-type')
      element.classList.add('visible')

      setTimeout(() => {
        element.classList.add(`${type}-anim`)
      }, duration * 1000)
    }, delay * 1000)
  }

  function scaleAnim(element, duration, delay) {
    setTimeout(() => {
      element.classList.add('visible')

      setTimeout(() => {
        element.classList.add('article-decor__scale')
      }, duration * 1000)
    }, delay * 1000)
  }
})
