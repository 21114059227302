import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const tags = document.querySelector('.js-personal-accounts-tags')

  if(tags) {
    const tags2 = document.querySelector('.js-personal-accounts-tags-2')
    const thematicInput = document.querySelector('input[name="thematic-areas"]')
    const areaInput = document.querySelector('input[name="area"]')
    const thematicInput2 = document.querySelector('input[name="thematic-areas-2"]')

    if(thematicInput && areaInput) {
      const renderTags = () => {
        const thematicInputValue = thematicInput.value ? thematicInput.value.split(',') : []
        const areaInputValue = areaInput.value ? areaInput.value.split(',') : []
        const thematicInputValue2 = thematicInput2.value ? thematicInput2.value.split(',') : []

        const renderTag = (i, inputName) => {
          return `
            <div class="personal-account__favorites-tag">
              <div class="personal-account__favorites-tag-title">
                ${i}
              </div>
              <div class="personal-account__favorites-tag-icon" data-input="${inputName}" data-value="${i}"></div>
            </div>`
        }

        tags.innerHTML =`
          ${thematicInputValue.map(i => renderTag(i, 'thematicInput')).join('')}
          ${areaInputValue.map(i => renderTag(i, 'areaInput')).join('')}
          <div class="personal-account__favorites-tag personal-account__favorites-tag_close">
            <div class="personal-account__favorites-tag-title" data-tag-name="tags-1">
              Сбросить все
            </div>
          </div>
        `

        if(!thematicInputValue.length && !areaInputValue.length) {
          tags.classList.add('personal-account__favorites-tags_empty')
        } else {
          tags.classList.remove('personal-account__favorites-tags_empty')
        }

        tags2.innerHTML = `
          ${thematicInputValue2.map(i => renderTag(i, 'thematicInput2')).join('')}
          <div class="personal-account__favorites-tag personal-account__favorites-tag_close">
            <div class="personal-account__favorites-tag-title" data-tag-name="tags-2">
              Сбросить все
            </div>
          </div>
        `

        console.log(tags2)

        if(!thematicInputValue2.length) {
          tags2.classList.add('personal-account__favorites-tags_empty')
        } else {
          tags2.classList.remove('personal-account__favorites-tags_empty')
        }

        document.querySelectorAll('.personal-account__favorites-tag-icon').forEach(icon => {
          icon.addEventListener('click', handleIconClick)
        })
        document.querySelectorAll('.personal-account__favorites-tag_close').forEach(closeTag => {
          closeTag.addEventListener('click', clearTag)
        })
      }

      const clearTag = (event) => {
        console.log('clearTag')
        const icon = event.target
        const inputName = icon.dataset.tagName

        if (inputName === 'tags-1') {
          thematicInput.value = ''

          const changeEvent = new Event('change')
          thematicInput.dispatchEvent(changeEvent)

          areaInput.value = ''
          areaInput.dispatchEvent(changeEvent)
        } else {
          thematicInput2.value = ''

          const changeEvent = new Event('change')
          thematicInput2.dispatchEvent(changeEvent)
        }
      }

      const handleIconClick = (event) => {
        const icon = event.target
        const inputName = icon.dataset.input
        const valueToRemove = icon.dataset.value

        let input
        if (inputName === 'thematicInput') {
          input = thematicInput
        } else if (inputName === 'areaInput') {
          input = areaInput
        } else if (inputName === 'thematicInput2') {
          input = thematicInput2
        }

        if (input) {
          let values = input.value ? input.value.split(',') : []
          values = values.filter(value => value !== valueToRemove)
          input.value = values.join(',')

          const changeEvent = new Event('change')
          input.dispatchEvent(changeEvent)
          renderTags()
        }
      }

      thematicInput.addEventListener('change', renderTags)
      thematicInput2.addEventListener('change', renderTags)
      areaInput.addEventListener('change', renderTags)

      renderTags()
    }
  }
})
