import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'

ready(() => {
  const parallaxElements = document.querySelectorAll('.js-bgParallax')

  function updateParallax() {
    const { isMobile } = getSize()
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    parallaxElements.forEach(element => {
      const elementSpeed = element.dataset.speed
      const elementSpeedMob = element.dataset.speedMob
      let speed = elementSpeed || 0.3

      if (isMobile) {
        speed = elementSpeedMob || 0.8
      }

      const yOffset = scrollPosition * speed
      element.style.transform = `translate3d(0, ${yOffset}px, 0)`
    })
  }

  window.addEventListener('scroll', updateParallax)

  updateParallax()

  setTimeout(() => {
    updateParallax()
  }, 10)
})

ready(() => {
  setTimeout(() => {
    document.querySelector('.body-dark-blue')?.classList.add('body-dark-blue_visible')
  }, 10)
})
