import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const triangleBock = document.querySelector('.triangle-decor')
  const triangleLeft = document.querySelector('.triangle-decor__left')
  const triangleTrigger = document.querySelector('#triangleTrigger')
  const triangleTriggerStop = document.querySelector('#triangleTriggerStop')

  let initialTop = -1
  let finalTop = 210

  const updateInitialTop = () => {
    const width = window.innerWidth

    if (width <= 320) {
      initialTop = 0
      finalTop = 0
    } else if (width <= 768) {
      finalTop = 80
    } else if (width <= 834) {
      finalTop = 90
    } else if (width <= 1210) {
      finalTop = 120
    } else if (width <= 1440) {
      finalTop = 158
    } else if (width <= 1920) {
      finalTop = 160
    } else {
      finalTop = 210
    }
  }

  updateInitialTop()
  window.addEventListener('resize', updateInitialTop)

  const getIntersectionTop = (element1, element2) => {
    const rect1 = element1.getBoundingClientRect()
    const rect2 = element2.getBoundingClientRect()

    if (rect1.top > rect2.bottom || rect1.bottom < rect2.top) {
      return 0
    }

    return Math.max(rect1.bottom - rect2.top, 0)
  }

  const handleScroll = () => {
    if (triangleBock) {
      const triggerRect = triangleTrigger?.getBoundingClientRect()
      const triggerBottom = triggerRect.bottom
      const viewportHeight = window.innerHeight

      const overlap = getIntersectionTop(triangleBock, triangleTriggerStop)
      const progress = 1 - (triggerBottom / viewportHeight)
      const newTop = initialTop + (finalTop - initialTop) * progress * 2
      const raz = Math.max(newTop - overlap, 0)

      if (triggerBottom <= viewportHeight && triggerBottom >= 0) {
        triangleBock.style.top = `${newTop}px`

        if (newTop <= finalTop) {
          triangleLeft.classList.remove('triangle-decor__left_gradient')
        }

        if (viewportHeight >= 1100 && triggerBottom >= 925) {
          triangleBock.style.top = '0px'
          triangleLeft.classList.remove('triangle-decor__left_gradient')
        }
      } else if (raz >= 10) {
        triangleLeft.classList.add('triangle-decor__left_gradient')
      } else {
        triangleBock.style.top = `${initialTop}px`
        triangleLeft.classList.remove('triangle-decor__left_gradient')
      }
    }
  }

  window.addEventListener('scroll', () => {
    requestAnimationFrame(handleScroll)
  })
})
