import { ready } from './documentReady'

ready(() => {
  window.initHorizontalScroll = () => {
    const horizontalScrollElements = document.querySelectorAll('.js-horizontalScroll')

    horizontalScrollElements.forEach(element => {
      if(!element.classList.contains('scroll-init')) {
        element.classList.add('scroll-init')

        let startX, startY, isHorizontalScroll = false, isVerticalScroll = false
        let isDragging = false
        let hasScrolled = false

        element.addEventListener('touchstart', (e) => {
          const touch = e.touches[0]
          startX = touch.clientX
          startY = touch.clientY
          isHorizontalScroll = false
          isVerticalScroll = false
          hasScrolled = false
        })

        element.addEventListener('touchmove', (e) => {
          if (isHorizontalScroll || isVerticalScroll) {
            return
          }

          const touch = e.touches[0]
          const diffX = touch.clientX - startX
          const diffY = touch.clientY - startY

          if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) > 10) {
            isVerticalScroll = true
            return
          }

          if (Math.abs(diffX) > 10) {
            isHorizontalScroll = true
            hasScrolled = true
            element.classList.add('horizontal-scroll-scrolled')
          }
        })

        element.addEventListener('mousedown', (e) => {
          startX = e.clientX
          startY = e.clientY
          isDragging = true
          isHorizontalScroll = false
          isVerticalScroll = false
          hasScrolled = false

          element.style.cursor = 'grabbing'
        })

        element.addEventListener('mousemove', (e) => {
          if (!isDragging) return

          const diffX = e.clientX - startX
          const diffY = e.clientY - startY

          if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) > 10) {
            isVerticalScroll = true
            return
          }

          if (Math.abs(diffX) > 10) {
            isHorizontalScroll = true
            hasScrolled = true
            element.classList.add('horizontal-scroll-scrolled')

            element.scrollLeft -= diffX
            startX = e.clientX

            updateScrollModifiers(element)
          }
        })

        element.addEventListener('mouseup', () => {
          isDragging = false
          element.style.cursor = 'grab'
        })

        element.addEventListener('mouseleave', () => {
          isDragging = false
          element.style.cursor = 'grab'
        })

        element.addEventListener('scroll', () => {
          updateScrollModifiers(element)
        })

        element.addEventListener('click', (e) => {
          if (hasScrolled) {
            e.preventDefault()
            e.stopImmediatePropagation()
            hasScrolled = false
            element.classList.remove('horizontal-scroll-scrolled')
          }
        })

        element.addEventListener('touchend', (e) => {
          if (hasScrolled) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            hasScrolled = false

            element.classList.remove('horizontal-scroll-scrolled')
          }
        })
      }
    })

    function updateScrollModifiers(element) {
      const maxScrollLeft = element.scrollWidth - element.clientWidth

      if (element.scrollLeft === 0) {
        element.classList.add('scroll-start')
      } else {
        element.classList.remove('scroll-start')
      }

      if (element.scrollLeft === maxScrollLeft) {
        element.classList.add('scroll-end')
      } else {
        element.classList.remove('scroll-end')
      }
    }
  }

  window.initHorizontalScroll()
})
