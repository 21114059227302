export function createControlPoints(filteredDescriptions, startPosition, maxPosition) {
  const positionsByYear = {}

  filteredDescriptions.forEach(item => {
    const year = Number(item.title)
    positionsByYear[year] = item.position
  })

  const knownYears = Object.keys(positionsByYear).map(Number).sort((a, b) => a - b)

  const yearsSet = new Set()
  // yearsSet.add(startYear)
  knownYears.forEach(year => yearsSet.add(year))
  yearsSet.add(2031)
  const years = Array.from(yearsSet).sort((a, b) => a - b)

  function getPositionForYear(year) {
    if (positionsByYear[year] !== undefined) {
      return positionsByYear[year]
    } else {
      let beforeYear = null
      let afterYear = null
      let beforePosition = null
      let afterPosition = null

      for (let i = years.indexOf(year) - 1; i >= 0; i--) {
        const y = years[i]
        if (positionsByYear[y] !== undefined || y === 2024) {
          beforeYear = y
          beforePosition = positionsByYear[y] !== undefined ? positionsByYear[y] : startPosition
          break
        }
      }
      if (beforeYear === null) {
        beforeYear = 2024
        beforePosition = startPosition
      }

      for (let i = years.indexOf(year) + 1; i < years.length; i++) {
        const y = years[i]
        if (positionsByYear[y] !== undefined || y === 2031) {
          afterYear = y
          afterPosition = positionsByYear[y] !== undefined ? positionsByYear[y] : maxPosition
          break
        }
      }
      if (afterYear === null) {
        afterYear = 2031
        afterPosition = maxPosition
      }

      const t = (year - beforeYear) / (afterYear - beforeYear)
      return beforePosition + t * (afterPosition - beforePosition)
    }
  }

  const controlPointsUnsorted = years.map((year, index) => {
    const position = getPositionForYear(year)
    const p = index / (years.length - 1)
    const s = (position - startPosition) / (maxPosition - startPosition)
    return { p, s }
  })

  const points = controlPointsUnsorted.sort((a, b) => a.s - b.s)

  points[0] = {
    p: 0,
    s: 0,
  }

  points[points.length - 1] = {
    p: 1,
    s: 1,
  }
  return points
}
