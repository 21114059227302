import { ready } from '../../../js/utils/documentReady'
import {getSize} from '../../../js/utils/breakpoints'
import {createControlPoints} from './utils/createControlPoints'
import {fromScrollbarToProgress} from './utils/fromScrollbarToProgress'
import {fromProgressToScrollbar} from './utils/fromProgressToScrollbar'

ready(() => {
  const timelineElement = document.querySelector('.timeline3d')
  const { isMobile, isTablet } = getSize()

  if(timelineElement && (isMobile || isTablet)) {
    const timelineData = window.timeline
    const isMainPage = timelineElement?.getAttribute('data-is-main')

    function getOS() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('win') !== -1) return 'windows'
      if (userAgent.indexOf('mac') !== -1) return 'mac'
      if (userAgent.indexOf('linux') !== -1) return 'linux'
      return 'unknown'
    }

    const os = getOS()

    if (os === 'mac') {
      timelineElement.classList.add('timeline3d_bg-gradient-mac')
    } else {
      timelineElement.classList.add('timeline3d_bg-gradient')
    }
    
    const showMoreButton = window.showMoreStrategy
    
    timelineElement.innerHTML = `
      <div class="timeline2d timeline2d_preview">
        <div class="timeline2d__bg"><img src="/local/templates/.default/markup/build/images/3dTimeline/road-tab.svg"/></div>
        <div class="timeline2d__preview">
          ${!isMainPage ? '<div class="timeline2d__preview-title MOSCOW2024">Выберите направление</div>' : ''}
          <div class="timeline2d__preview-cards">
            ${timelineData.map((item) => `
              <div class="timeline2d__preview-card">
                <div class="timeline2d__preview-card-image"><img src="${item.image}"/></div>
                <div class="timeline2d__preview-card-title">${item.name}</div>
              </div>
            `).join('')}
          </div>
          ${isMainPage ? `<div class="timeline3d-preview__btn"><a class="button button-theme_blue-dark-with-light text-18 font-600 lh-22 text-tab-16 lh-tab-20 text-mob-14" href=${showMoreButton.link}><span class="button__text">${showMoreButton.name}</span></a></div>` : ''}
        </div>
        <div class="container">
          <div class="timeline2d__back-to-choice"></div>
          <div class="timeline3d__tabs"></div>
          <div class="timeline2d__cards-swiper">
            <div class="timeline2d__cards"></div>
          </div>
        </div>

        <div class="timeline3d__scrollbar">
          <div class="timeline3d__scrollbar-shadow"></div>
          <div class="timeline3d__scrollbar-inner">
            <div class="timeline3d__thumb"></div>
          </div>
          <div class="timeline3d__description">
            <div class="timeline3d__description-years">
          </div>
          <div class="timeline3d__description-hitbox"></div>
        </div>
        </div>
      </div>
    `

    let tabsElements
    let timelineTab
    let updateSwiper
    let activeIndex
    let controlPoints
    // let scrollPosition;
    // let maxScroll;
    let scrollToProgress
    let originalUrl = window.location.href.split('?')[0].split('#')[0]

    if (originalUrl.endsWith('/')) {
      originalUrl = originalUrl.slice(0, -1)
    }

    let isSwiping = false
    let forceAnimate = false
    let maxSpeed = 0.005
    let accelerationFactor = 0.05
    const timeLineInner = timelineElement.querySelector('.timeline2d')
    const tabs = timelineElement.querySelector('.timeline3d__tabs')
    const cardsElement = timelineElement.querySelector('.timeline2d__cards')
    const scrollbarDescription = timelineElement.querySelector('.timeline3d__description')
    const scrollbarDescriptionHitBox = timelineElement.querySelector('.timeline3d__description-hitbox')
    const scrollbarInnerElement = timelineElement.querySelector('.timeline3d__scrollbar-inner')
    const thumbElement = scrollbarInnerElement.querySelector('.timeline3d__thumb')
    const previewCards = timelineElement.querySelectorAll('.timeline2d__preview-card')
    const scrollbarDescriptionYears = timelineElement.querySelector('.timeline3d__description-years')
    const backButton = timelineElement.querySelector('.timeline2d__back-to-choice')
    const defaultActiveIndex = timelineData.findIndex(item => item.url === window.timelineActualUrl)

    if(window.timelineActualUrl) {
      if (originalUrl.endsWith('/' + window.timelineActualUrl)) {
        originalUrl = originalUrl.slice(0, -window.timelineActualUrl.length - 1)
      }
    }

    backButton.addEventListener('click', () => {
      timelineElement.classList.add('timeline3d_preview')
      const urlObject = new URL(window.location.href)
      const query = urlObject.search || ''
      const newPath = `${originalUrl}/${query}`
      window.history.pushState({}, '', newPath)
    })

    document.addEventListener('DOMContentLoaded', function () {
      const hash = window.location.hash
      const target = document.querySelector(hash)
      const header = document.querySelector('.header')
      if (target) {
        setTimeout(() => {
          const headerOffset = header.offsetHeight
          const elementPosition = target.getBoundingClientRect().top + window.scrollY
          window.scrollTo({ top: elementPosition - headerOffset, behavior: 'smooth' })
        }, 500)
      }
    })

    const renderYears = (years) => {
      const generateYearBlock = (year) => {
        const generateHrLines = (count) => {
          return Array(count).fill('<hr>').join('')
        }

        return `
          <div class="timeline3d__description-year-block">
            <div class="timeline3d__description-year-title">${year}</div>
            <div class="timeline3d__description-year-block-lines">
              ${generateHrLines(12)}
            </div>
          </div>`

      }

      const startYear = Math.min(...years)

      scrollbarDescriptionYears.innerHTML = `
         ${years.map(year => generateYearBlock(year)).join('')}
         <div class="timeline3d__description-decor-left">
           ${generateYearBlock(startYear - 2)}
           ${generateYearBlock(startYear - 1)}
           ${generateYearBlock(startYear - 1)}
        </div>
         <div class="timeline3d__description-decor-right">
           ${generateYearBlock(2031)}
           ${generateYearBlock(2032)}
        </div>
      `

      setTimeout(() => {
        const yearBlocks = scrollbarDescriptionYears.querySelectorAll(':scope > .timeline3d__description-year-block')

        const lastYearBlock = yearBlocks[yearBlocks.length - 1]

        scrollbarDescriptionYears.style.setProperty('--width-year-block', `${lastYearBlock.clientWidth}px`)
      }, 1)
    }

    renderYears([2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031])

    const renderTabs = () => {
      tabs.innerHTML = `
        ${timelineData.map((tabData, index) => `
          <div class="timeline3d__tabs-item" data-index="${index}">${tabData.name}</div>
        `).join('')}
      `

      tabsElements = tabs.querySelectorAll('.timeline3d__tabs-item')

      for (let tabElement of tabsElements) {
        tabElement.addEventListener('click', () => {
          const index = tabElement.dataset.index
          if (!tabElement.classList.contains('timeline3d__tabs-item_active')) {
            setActiveTab(index)
          }
        })
      }
    }

    renderTabs()

    const renderCards = () => {
      window.openTimelineVideo = () => {
        window.openVideoPopup(timelineTab.videoIframe, timelineTab.videoTitle, timelineTab.isVertical)
      }

      cardsElement.innerHTML = `
        ${timelineTab.videoIframe || timelineTab.description ? `
          <div class="timeline2d__card timeline2d__card_first">
            <div class="timeline2d__card-inner">
              <div class="timeline2d__card-preview-title">
                ${timelineTab.description}
              </div>
              ${timelineTab.videoIframe ? `
                <div class="timeline2d__card-preview-video" onclick="window.openTimelineVideo()">
                  Смотреть видео
                </div>
              ` : ''}
            </div>
          </div>
        ` : ''}
         
        ${timelineTab.cards.map((card) => `
          <div class="timeline2d__card ${card.image ? 'timeline2d__card_with-image' : ''}">
            <div class="timeline2d__card-inner">
              ${card.image ? `
                <div class="timeline2d__card-image"><img src="${card.image}"/></div>
              ` : ''}
              <div class="timeline2d__card-description">${card.description}</div>
              ${card.open_popup ? `<div class="timeline2d__card-button" onclick="window.openTimelinePopup('${card.id}')">Подробнее</div>` : ''}
            </div>
          </div>
        `).join('')}
      `
    }

    const setActiveTab = (index) => {

      if (tabsElements) {
        timeLineInner.classList.remove('timeline2d_preview')
        if (activeIndex || activeIndex === 0) {
          tabsElements[activeIndex].classList.remove('timeline3d__tabs-item_active')
        }
        tabsElements[index].classList.add('timeline3d__tabs-item_active')
        activeIndex = index
        timelineTab = timelineData[activeIndex]

        const filteredDescriptions = timelineTab.descriptions
          .filter(item => {
            return [
              '2010','2011','2012','2013','2014','2015','2016','2017',
              '2018','2019','2020','2021','2022','2023','2024','2025',
              '2026','2027','2028','2029','2030'
            ].includes(item.title)
          })
          .sort((a, b) => Number(a.title) - Number(b.title))

        const years = filteredDescriptions.map(item => +item.title)

        // filteredDescriptions.shift()

        const cards = timelineTab.cards
        timelineTab.cards = timelineTab.cards.sort((a, b) => a.position - b.position)

        const yearsData = filteredDescriptions
          .map(item => ({ year: +item.title, position: item.position }))
          .sort((a, b) => a.position - b.position)

        const sortedCards = [...[{position: 1}],...cards].sort((a, b) => a.position - b.position)

        const recalculatedYears = yearsData.map(year => {
          const newPosition = sortedCards.filter(card => card.position < year.position).length
          return {
            year: year.year,
            position: newPosition
          }
        })

        controlPoints = createControlPoints(recalculatedYears, 0, sortedCards.length)

        renderYears([...years, ...[2031]])

        setTimeout(() => {
          const maxPosition = sortedCards.length
          const scrollWidth = scrollbarDescription.clientWidth
          const kSpeed = scrollWidth / maxPosition  / 10
          maxSpeed = 0.003 * kSpeed
        }, 10)

        if (timelineTab && timelineTab.url) {
          const urlObject = new URL(window.location.href)
          const query = urlObject.search || ''
          const newPath = `${originalUrl}/${timelineTab.url}/${query}`
          window.history.pushState({}, '', newPath)
        }

        renderCards()
        updateSwiper()
        targetProgress = 0
        realProgress = 0
        forceAnimate = true
        if(!isAnimated) {
          requestAnimationFrame(animate)
        }

        backButton.innerHTML = timelineTab.name

        if(activeIndex === '1' || activeIndex === '2' || activeIndex === '5' || activeIndex === '6') {
          backButton.style.background = 'rgba(0, 202, 241, 1)'
        } else {
          backButton.style.background = 'rgba(174, 115, 255, 1)'
        }
      }
    }

    const changeScroll = (scroll) => {
      targetProgress = scroll
      if(!isAnimated) {
        requestAnimationFrame(animate)
      }
    }

    const initSwiper = () => {
      const swiper = document.querySelector('.timeline2d__cards-swiper')
      const cardsContainer = swiper.querySelector('.timeline2d__cards')
      let cards = Array.from(cardsContainer.querySelectorAll('.timeline2d__card'))
      let isDown = false
      let startX
      let scrollLeft

      function pointerDown(e) {
        isDown = true
        scrollLeft = getTranslateX(cardsContainer)
        startX = e.pageX || e.touches[0].pageX
        setTranslateX(cardsContainer, scrollLeft)
        setTimeout(() => {
          cardsContainer.style.transition = 'none'
        }, 1)
      }

      function pointerMove(e) {
        if (!isDown) return
        e.preventDefault()

        const x = e.pageX || e.touches[0].pageX
        let walk = x - startX

        const swiperWidth = swiper.offsetWidth
        const cardWidths = cards.map(card => card.offsetWidth)
        const totalCardsWidth = cardWidths.reduce((a, b) => a + b, 0)
        const maxNegativeTranslateX = Math.min(0, swiperWidth - totalCardsWidth)

        let newTranslateX = scrollLeft + walk

        if (newTranslateX > 0) {
          const overshoot = newTranslateX
          newTranslateX = overshoot * 0.3
        } else if (newTranslateX < maxNegativeTranslateX) {
          const overshoot = newTranslateX - maxNegativeTranslateX
          newTranslateX = maxNegativeTranslateX + overshoot * 0.3
        }

        setTranslateX(cardsContainer, newTranslateX)

        if (Math.abs(walk) > 5) {
          isSwiping = true
        }

        syncScroll()
      }

      function pointerUp() {
        if (isDown) {
          isDown = false
          if (isSwiping) {
            snapToNearest()
          }
          syncScroll()
        }
      }

      function getTranslateX(elem) {
        const style = window.getComputedStyle(elem)
        const matrix = new DOMMatrixReadOnly(style.transform)
        return matrix.m41 || 0
      }

      function setTranslateX(elem, x) {
        elem.style.transform = `translateX(${x}px)`
      }

      function snapToNearest() {
        const cardPositions = []
        let cumulativeWidth = 0
        const swiperWidth = swiper.offsetWidth
        const cardWidths = cards.map(card => card.offsetWidth)
        const totalCardsWidth = cardWidths.reduce((a, b) => a + b, 0)
        let maxNegativeTranslateX = swiperWidth - totalCardsWidth

        if (maxNegativeTranslateX > 0) {
          maxNegativeTranslateX = 0
        }

        cardWidths.forEach((width, index) => {
          if (index === 0) {
            cardPositions.push(0)
          } else {
            cumulativeWidth += cardWidths[index - 1]
            cardPositions.push(-cumulativeWidth)
          }
        })

        cardPositions.forEach((pos, index) => {
          if (pos < maxNegativeTranslateX) {
            cardPositions[index] = maxNegativeTranslateX
          }
        })

        const currentTranslateX = getTranslateX(cardsContainer)
        let closestPosition = cardPositions.reduce((prev, curr) => {
          return Math.abs(curr - currentTranslateX) < Math.abs(prev - currentTranslateX) ? curr : prev
        })

        if (closestPosition > 0) {
          closestPosition = 0
        }
        if (closestPosition < maxNegativeTranslateX) {
          closestPosition = maxNegativeTranslateX
        }

        cardsContainer.style.transition = 'transform 0.3s ease-out'
        setTranslateX(cardsContainer, closestPosition)
        setTimeout(() => {
          syncScroll(closestPosition)
        }, 10)
      }

      function syncScroll(translateX) {
        const swiperWidth = swiper.offsetWidth
        const cardsWidth = cards.reduce((total, card) => total + card.offsetWidth, 0)
        const maxNegativeTranslateX = Math.min(0, swiperWidth - cardsWidth)

        const currentTranslateX = translateX || getTranslateX(cardsContainer)

        const scrollProgress =
          maxNegativeTranslateX === 0
            ? 0
            : Math.max(0, Math.min(1, -currentTranslateX / -maxNegativeTranslateX))

        changeScroll(scrollProgress)
      }

      function scrollToProgress(progress) {

        const swiperWidth = swiper.offsetWidth
        const cardsWidth = cards.reduce((total, card) => total + card.offsetWidth, 0)
        const maxNegativeTranslateX = Math.min(0, swiperWidth - cardsWidth)

        const targetTranslateX = maxNegativeTranslateX * progress

        cardsContainer.style.transition = 'none'
        setTranslateX(cardsContainer, targetTranslateX)
      }

      swiper.addEventListener('mousedown', pointerDown, { passive: false })
      swiper.addEventListener('touchstart', pointerDown, { passive: false })

      swiper.addEventListener('mousemove', pointerMove, { passive: false })
      swiper.addEventListener('touchmove', pointerMove, { passive: false })

      swiper.addEventListener('mouseleave', pointerUp)
      swiper.addEventListener('mouseup', pointerUp)
      swiper.addEventListener('touchend', pointerUp)
      swiper.addEventListener('touchcancel', pointerUp)

      function updateSwiper() {
        cardsContainer.style.transition = ''
        setTranslateX(cardsContainer, 0)
        cards = Array.from(cardsContainer.querySelectorAll('.timeline2d__card'))
      }

      return { scrollToProgress, updateSwiper }
    }

    const swiper = initSwiper()
    updateSwiper = swiper.updateSwiper
    scrollToProgress = swiper.scrollToProgress

    previewCards.forEach((previewCard, index)=> {
      previewCard.addEventListener('click', () => {
        if (window.isRedirectOn3dTimeline) {
          timelineTab = timelineData[index]
          if (timelineTab && timelineTab.link) {
            window.location.href = `${timelineTab.link}#strategy`
          }
        } else {
          setActiveTab(`${index}`)
          timelineElement.classList.remove('timeline3d_preview')
        }
      })
    })

    let targetProgress = 0
    let realProgress = 0

    let prevRealProgress = 0
    let isAnimated = false

    function updateScrollbarDescription() {
      const innerWidth = timelineElement.offsetWidth
      const descriptionWidth = scrollbarDescription.offsetWidth

      const maxOffset = descriptionWidth - innerWidth

      const translateX = -maxOffset * fromScrollbarToProgress(realProgress, controlPoints)
      scrollbarDescription.style.transform = `translateX(${translateX}px)`
    }

    function animate() {
      let distance = targetProgress - realProgress
      let speed = distance * accelerationFactor
      speed = Math.max(-maxSpeed, Math.min(maxSpeed, speed))
      realProgress += speed
      realProgress = Math.max(0, Math.min(1, realProgress))
      realProgress = parseFloat(realProgress.toFixed(4)) || 0
      updateScrollbar()

      if(realProgress !== prevRealProgress || forceAnimate) {
        // scrollPosition = realProgress * maxScroll
        updateScrollbarDescription()
        requestAnimationFrame(animate)
        isAnimated = true

        if(!isSwiping) {
          scrollToProgress(realProgress)
        }

        if(forceAnimate) {
          forceAnimate = false
        }
      } else {
        isAnimated = false
      }
      prevRealProgress = realProgress
    }

    function updateScrollbar() {
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      const thumbPosition = fromScrollbarToProgress(realProgress, controlPoints) * maxThumbPosition
      thumbElement.style.transform = `translateX(${thumbPosition}px)`
    }

    scrollbarDescriptionHitBox.addEventListener('click', function(e) {
      isSwiping = false
      const rect = scrollbarDescriptionHitBox.getBoundingClientRect()
      const clickX = e.clientX - rect.left
      const scrollbarWidth = scrollbarDescriptionHitBox.clientWidth
      targetProgress = clickX / scrollbarWidth
      targetProgress = fromProgressToScrollbar(Math.max(0, Math.min(1, clickX / scrollbarWidth)), controlPoints)

      if(!isAnimated) {
        requestAnimationFrame(animate)
      }
    })

    let isDragging = false
    let dragStartX = 0
    let thumbStartX = 0

    thumbElement.addEventListener('mousedown', function(e) {
      e.preventDefault()
      isDragging = true
      isSwiping = false
      scrollbarInnerElement.classList.add('timeline3d__scrollbar-inner_drag')
      dragStartX = e.clientX
      const rect = thumbElement.getBoundingClientRect()
      thumbStartX = rect.left - scrollbarInnerElement.getBoundingClientRect().left
      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', onDragEnd)
    })

    function onDrag(e) {
      if (!isDragging) return
      e.preventDefault()
      const deltaX = e.clientX - dragStartX
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      let newThumbPosition = thumbStartX + deltaX
      newThumbPosition = Math.max(0, Math.min(maxThumbPosition, newThumbPosition))
      targetProgress = fromProgressToScrollbar(newThumbPosition / maxThumbPosition, controlPoints)

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    }

    function onDragEnd() {
      scrollbarInnerElement.classList.remove('timeline3d__scrollbar-inner_drag')
      isDragging = false
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', onDragEnd)
    }

    thumbElement.addEventListener('onclick', (e) => {
      e.stopPropagation()
    })

    thumbElement.addEventListener('touchstart', function(e) {
      scrollbarInnerElement.classList.add('timeline3d__scrollbar-inner_drag')
      isDragging = true
      isSwiping = false
      dragStartX = e.touches[0].clientX
      const rect = thumbElement.getBoundingClientRect()
      thumbStartX = rect.left - scrollbarInnerElement.getBoundingClientRect().left
      document.addEventListener('touchmove', onTouchMove, { passive: false })
      document.addEventListener('touchend', onTouchEnd)
    })

    function onTouchMove(e) {
      if (!isDragging) return
      e.preventDefault()
      e.stopPropagation()
      const deltaX = e.touches[0].clientX - dragStartX
      const scrollbarWidth = scrollbarInnerElement.clientWidth
      const thumbWidth = thumbElement.clientWidth
      const maxThumbPosition = scrollbarWidth - thumbWidth
      let newThumbPosition = thumbStartX + deltaX
      newThumbPosition = Math.max(0, Math.min(maxThumbPosition, newThumbPosition))
      targetProgress = fromProgressToScrollbar(newThumbPosition / maxThumbPosition, controlPoints)

      if(!isAnimated) {
        isAnimated = true
        requestAnimationFrame(animate)
      }
    }

    function onTouchEnd() {
      scrollbarInnerElement.classList.remove('timeline3d_scrollbar-inner_drag')
      isDragging = false
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', onTouchEnd)
    }

    if(defaultActiveIndex !== -1) {
      setTimeout(() => {
        timelineElement.classList.remove('timeline3d_preview')
        setActiveTab(defaultActiveIndex)
      }, 10)
    }
  }
})
