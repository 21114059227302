import {getSize} from '../../../../../js/utils/breakpoints'

export const renderContentPanel = ({ref}) => {
  let cluster = null

  ref.panel = {
    setActive: ({active, elements, clusterElement, center}) => {
      ref.panel.active = active
      const { isMobile } = getSize()
      const panelElement = document.querySelector('.map-content-panel')

      if(cluster) {
        cluster.classList.remove('cluster_active')
        document.querySelector('.cluster-active-parent')?.classList?.remove('cluster-active-parent')
        cluster = null
      }

      if(active) {
        panelElement.classList.remove('map-content-panel_hidden')

        if (elements) {
          const result = {}

          for (let i of elements) {
            let place = i.properties.place.toLowerCase()
            console.log(i)
            if(i.properties.type === 'flagship-areas') {
              place = 'Флагманские площадки'
            } else if (i.properties.type === 'common-areas') {
              place = 'подплощадки'
            } else {
              place = 'другие площадки'
            }

            if (result[place]) {
              result[place].push(i)
            } else {
              result[place] = [i]
            }
          }

          for (let key in result) {
            result[key].sort((a, b) => {
              const titleA = a.properties.title.toLowerCase()
              const titleB = b.properties.title.toLowerCase()
              if (titleA < titleB) return -1
              if (titleA > titleB) return 1
              return 0
            })
          }

          const sortedEntries = Object.entries(result).sort((a, b) => {
            const aIsOther = a[0].toLowerCase() === 'другие площадки'
            const bIsOther = b[0].toLowerCase() === 'другие площадки'

            if (aIsOther && !bIsOther) {
              return 1
            }
            if (!aIsOther && bIsOther) {
              return -1
            }

            return b[1].length - a[1].length
          })

          const sortedResult = Object.fromEntries(sortedEntries)

          if(sortedResult['Флагманские площадки'] || sortedResult['подплощадки']) {
            sortedResult['Флагманские площадки'] = [...(sortedResult['Флагманские площадки'] || []), ...(sortedResult['подплощадки'] || [])]
          }

          delete sortedResult['подплощадки']
          const contentElement = document.querySelector('.map-content-panel__content')

          let resultHtml = ''

          for(let i in sortedResult) {
            resultHtml += `
              <div class="map-content-panel__title">
                ${i}
              </div>
            `

            resultHtml += `
              <div class="map-content-panel__list-item">
                ${sortedResult[i].map((i) => `
                  <a class="map-content-panel__item" href="${i.properties.link}" target="_blank">
                    ${i.properties.title}
                    
                    <div class="map-content-panel__item-icon"></div>
                  </a>
                `).join(' ')}
              </div>
            `
          }

          contentElement.innerHTML = resultHtml

          clusterElement.classList.add('cluster_active')
          clusterElement.parentElement.classList.add('cluster-active-parent')
          cluster = clusterElement

          if(isMobile) {
            const contentElement = document.querySelector('.map-content-panel__content')
            contentElement.style.height = ''
            const heightPanel = panelElement.clientHeight
            ref.marginController.setMargins('panel', [0,0,heightPanel + 10,0])
            const mapPanel = document.querySelector('.map-panel')
            if(mapPanel) {
              if(mapPanel.style.height) {
                mapPanel.style.transitionDuration = '0s'
              }
              mapPanel.style.height = `calc(100% - 12px * 2 - ${heightPanel}px)`
            }

            contentElement.style.height =  `calc(-20px + ${heightPanel}px)`
          } else {
            const widthPanel = panelElement.clientWidth
            ref.marginController.setMargins('panel', [0,0,0, widthPanel + 20])
          }
          ref.map.setLocation({center: center, duration: 300})
        }
      } else {
        panelElement.classList.add('map-content-panel_hidden')
        ref.marginController.setMargins('panel', [0,0,0,0])

        if(isMobile) {
          const mapPanel = document.querySelector('.map-panel')
          if(mapPanel) {
            mapPanel.style.transitionDuration = ''
            mapPanel.style.height = ''
          }
        }
      }
    },
    active: false,
  }

  setTimeout(() => {
    const closeElement = document.querySelector('.map-content-panel__close')
    closeElement.addEventListener('click', () => {
      ref.panel.setActive({active: false})
    })
  })

  return `
    <div class="map-content-panel map-content-panel_hidden">
      <div class="map-content-panel__close"></div>
      <div class="map-content-panel__inner">
        <div class="map-content-panel__scroll">
          <div class="map-content-panel__content">
          </div>
        </div>
      </div>
    </div>
  `
}
