import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const documentTypeInput = document.querySelector('.js-document-type')
  const contentElement = document.querySelector('.popup-accreditation__content')

  if (documentTypeInput && contentElement) {
    documentTypeInput.addEventListener('change', function(event) {
      if(event.target.value === 'Паспорт РФ') {
        contentElement.classList.remove('popup-accreditation__content_not-rf-passport')
        contentElement.classList.add('popup-accreditation__content_rf-passport')
      } else {
        contentElement.classList.remove('popup-accreditation__content_rf-passport')
        contentElement.classList.add('popup-accreditation__content_not-rf-passport')
      }
    })

    const toStep1Elements = document.querySelectorAll('.js-to-step-1')

    for(let toStep1Element of toStep1Elements) {
      toStep1Element.addEventListener('click', () => {
        if(!contentElement.classList.contains('popup-accreditation__content_step-1')) {
          contentElement.classList.remove('popup-accreditation__content_step-2')
          contentElement.classList.remove('popup-accreditation__content_step-3')
          contentElement.classList.add('popup-accreditation__content_step-1')
        }
      })
    }

    $('.js-refresh-form').on('click', function() {
      $('.js-validate-first-step').each(function() {
        $(this).trigger('reset')
      })

      $('.js-validate-second-step').each(function() {
        $(this).trigger('reset')
      })

      const customTextFields = document.querySelectorAll('.field-custom-text')

      customTextFields.forEach(function(field) {
        const input = field.querySelector('input')
        if (input === document.activeElement || input.value !== '') {
          field.classList.add('field-custom-text_active')
        } else {
          field.classList.remove('field-custom-text_active')
        }
      })

      const customSelectFields = document.querySelectorAll('.field-custom-select')

      customSelectFields.forEach(function(field) {
        const input = field.querySelector('input')
        if (input === document.activeElement || input.value !== '') {
          field.classList.add('field-custom-select_selected')
        } else {
          field.classList.remove('field-custom-select_selected')
        }
      })

      const notRfPassportElement = document.querySelector('.popup-accreditation__content_not-rf-passport')

      if(notRfPassportElement) {
        notRfPassportElement.classList.remove('popup-accreditation__content_not-rf-passport')
        notRfPassportElement.classList.add('popup-accreditation__content_rf-passport')
      }
    })
  }
})
