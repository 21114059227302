import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const topDescription = document.querySelector('.platforms__top-description')
  const eventMapBlock = document.querySelector('.event-map__description-bottom')

  if (eventMapBlock) {
    if(topDescription) {
      eventMapBlock.innerHTML = topDescription.innerHTML
      topDescription.innerHTML = ''
    } else {
      eventMapBlock.style.display = 'none'
    }
  }
})
