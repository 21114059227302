/* swiper */
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../../js/utils/documentReady'

ready(() => {
  const updateSlideInfo = (swiper) => {
    const currentSlide = String(swiper.realIndex + 1).padStart(2, '0')
    const totalSlides = String(swiper.slides.length).padStart(2, '0')
    const paginationElement = swiper.el.querySelector('.js-swiperPagination')
    if (paginationElement) {
      paginationElement.innerHTML = `<div>${currentSlide}</div> <div>/</div> <div>${totalSlides}</div>`
    }
  }

  const sliderInPopup = () => {
    document.querySelectorAll('.js-bitrixContentSwiper').forEach(swiperElement => {
      new Swiper(swiperElement, {
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: '.js-swiperButtonNext',
          prevEl: '.js-swiperButtonPrev',
          disabledClass: 'bitrix-content-swiper__button-disabled',
        },
        on: {
          init() {
            updateSlideInfo(this)
            checkImageSizes()
          },
          slideChange() {
            updateSlideInfo(this)
          },
          resize: function () {
            checkImageSizes()
          }
        }
      })
    })
    function checkImageSizes() {
      const slides = document.querySelectorAll('.swiper-slide')

      slides.forEach((slide) => {
        const img = slide.querySelector('img')
        if (img) {
          const slideWidth = slide.offsetWidth
          const slideHeight = slide.offsetHeight
          const imgWidth = img.naturalWidth
          const imgHeight = img.naturalHeight

          if (imgWidth < slideWidth && imgHeight < slideHeight) {
            img.style.objectFit = 'none'
          } if (imgWidth < slideWidth && imgHeight > slideHeight) {
            img.style.objectFit = 'none'
          }
        }
      })
    }
  }
  sliderInPopup()
  window.initSliderInPopup = () => sliderInPopup()
})
