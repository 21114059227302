import { ready } from '../../../js/utils/documentReady'

ready(() => {
  function closePopup(popup) {
    popup.classList.remove('popup-warning_open')
    document.body.classList.remove('body-overflow-hidden')
  }

  document.addEventListener('click', function(event) {
    const target = event.target

    if (target.classList.contains('popup-warning__close') || target.classList.contains('popup-warning__bg')) {
      const popup = target.closest('.popup-warning')
      if (popup) {
        closePopup(popup)
      }
    }
  })
})
