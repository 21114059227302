import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const isMain3 = document.querySelector('body.main-3, body.hide-header')
  const header = document.querySelector('.header')
  const headerHeight = header?.innerHeight || 114

  if (isMain3 && header) {
    let prevScrollY = window.scrollY

    function handleScroll() {
      const currentScrollY = window.scrollY
    
      if (currentScrollY > headerHeight) {
        header.classList.add('header_scrolled')
        if (prevScrollY <= currentScrollY) {
          header.classList.add('is-hide-up')
        } else {
          header.classList.remove('is-hide-up')
        }
        prevScrollY = currentScrollY
      } else {
        header.classList.remove('is-hide-up')
        header.classList.remove('header_scrolled')
      }
    }

    window.addEventListener('scroll', handleScroll)
    document.addEventListener('DOMContentLoaded', handleScroll)
    window.addEventListener('load', handleScroll)
    window.addEventListener('pageshow', handleScroll)
  }
})
