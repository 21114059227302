import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const loginButtons = document.querySelectorAll('.js-popupSignInProfileBrandButton')
  const checkbox = document.querySelector('.js-popupSignInProfileCheckbox')
  const checkboxTextRequired = document.querySelector('.js-popupSignInProfileConsentTextRequired')
  const checkmark = document.querySelector('.js-popupSignInProfileCheckmark')
  const isPopupSignInProfile = document.querySelector('.js-popupSigninProfile')

  const addStylesError = () => {
    checkboxTextRequired.classList.add('popup-signin-profile__consent-text-required_checked')
    checkmark.classList.add('popup-signin-profile__checkmark_error')
  }

  const removeStylesError = () => {
    checkboxTextRequired.classList.remove('popup-signin-profile__consent-text-required_checked')
    checkmark.classList.remove('popup-signin-profile__checkmark_error')
  }

  if (isPopupSignInProfile) {
    isPopupSignInProfile.classList.add('popup-signin-profile_hidden')
    checkbox.addEventListener('click', () => {
      if (checkbox.checked) {
        removeStylesError()
      } else {
        addStylesError()
      }
    })

    loginButtons.forEach(button => {
      button.addEventListener('click', (event) => {
        if (!checkbox.checked) {
          event.preventDefault()
          addStylesError()
        }
      })
    })
  }

  for(let container of document.querySelectorAll('.popup-signin-profile, .js-popupLoginRusspass')) {
    const closeButtons = container.querySelectorAll('.js-closePopupSignInProfile')

    closeButtons.forEach(function(button) {
      button.onclick = function() {
        container.classList.remove('popup-signin-profile_open')
        document.body.classList.remove('body-overflow-hidden')

        setTimeout(() => {
          container.classList.remove('popup-signin-profile_hidden')
        }, 300)
      }
    })
  }

  // window.openPopupLogin()
})

window.openPopupLogin = (text) => {
  const popup = document.querySelector('.js-popupSigninProfile')
  if (popup) {
    popup.classList.remove('popup-signin-profile_hidden')
    setTimeout(() => {
      popup.classList.add('popup-signin-profile_open')
      document.body.classList.add('body-overflow-hidden')

      if(text) {
        const textElement = popup.querySelector('.popup-signin-profile__description')
        if(textElement) {
          textElement.innerHTML = text
        }
      }
    })
  }
}

window.openPopupRusspass = () => {
  const popup = document.querySelector('.js-popupLoginRusspass')
  if (popup) {
    popup.classList.add('popup-signin-profile_open')
    document.body.classList.add('body-overflow-hidden')
  }
}
