const defaultForbiddenChars = ['»', '-', '«', '.', ',', '!', '?', ':', ';', '(', ')', '[', ']', '{', '}', '\'', '"', '/', '\\', '|', '@', '#', '$', '%', '^', '&', '*', '_', '+', '=', '<', '>', '`', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

/**
 * Вставляет мягкие переносы в текст.
 *
 * @param {string} text - Исходный текст, в который нужно вставить мягкие переносы.
 * @param {Array<string>} [forbiddenChars=defaultForbiddenChars] - Массив символов, перед которыми не должны вставляться мягкие переносы.
 * @param {number} [allowedWordLength=14] - Минимальная длина слова, для которого будут вставлены мягкие переносы.
 * @returns {string} - Текст со вставленными мягкими переносами.
 */
export const insertSoftHyphens = (text, forbiddenChars = defaultForbiddenChars, allowedWordLength = 14) => {
  return text.split(' ').map(word => {
    if (word.length < allowedWordLength) {
      return word
    }

    let charIndex = 0

    return [...word].map(char => {
      if (forbiddenChars.includes(char)) {
        return char
      }

      charIndex++
      
      if (charIndex > 2 && charIndex % 3 === 0) {
        return `&shy;${char}`
      }

      return char
    }).join('')
  }).join(' ')
}

window.addSoftHyphens = insertSoftHyphens
