import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import {executeOnScroll} from '../../../js/utils/executeOnScroll'
import { loadingSlides } from '../../../js/utils/loadingSlides'

ready(() => {
  const swiperElement = document.querySelector('.js-swiperArtGallery')
  if (swiperElement) {
    const initSwiper = () => {
      new Swiper('.js-swiperArtGallery', {
        modules: [Navigation, Pagination],
        spaceBetween: 40,
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: '.js-swiperArtGalleryButtonNext',
          prevEl: '.js-swiperArtGalleryButtonPrev',
          disabledClass: 'art-gallery__button-disabled',
        },
        pagination: {
          el: '.js-swiperArtGalleryPagination',
          type: 'bullets',
        },
        slidesPerView: 1.88,
        speed: 500,
        breakpoints: {
          320: {
            slidesPerView: 1.17,
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 1.179,
          },
          1440: {
            spaceBetween: 48,
            slidesPerView: 1.731,
          },
          1920: {
            spaceBetween: 40,
            slidesPerView: 1.896,
          }
        },
        on: {
          init() {
            loadingSlides(this.realIndex, this.slides, 1, 3)
          },
          slideChange() {
            loadingSlides(this.realIndex, this.slides, 1, 3)
          }
        },
      })
    }
    executeOnScroll(swiperElement, initSwiper)
  }
})
