import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const descriptionElement = document.querySelector('.event-detail-preview__param-title-dropdown')
  const menuDescriptionElement = document.querySelector('.event-detail-preview__menu-description')

  if (descriptionElement && menuDescriptionElement) {
    const dropDownTitle = menuDescriptionElement.closest('.event-detail-preview__param-title-dropdown')

    descriptionElement.addEventListener('click', (event) => {
      event.stopPropagation()

      if (menuDescriptionElement.classList.contains('event-detail-preview__menu-description_open')) {
        closeMenu()
        if(dropDownTitle) {
          dropDownTitle.classList.remove('event-detail-preview__param-title-dropdown_open')
        }
      } else {
        openMenu()
        if(dropDownTitle) {
          dropDownTitle.classList.add('event-detail-preview__param-title-dropdown_open')
        }
      }
    })

    menuDescriptionElement.addEventListener('click', (e) => e.stopPropagation())

    function openMenu() {
      menuDescriptionElement.classList.add('event-detail-preview__menu-description_open')
      window.addEventListener('click', handleClickOutside)
    }

    function closeMenu() {
      menuDescriptionElement.classList.remove('event-detail-preview__menu-description_open')
      window.removeEventListener('click', handleClickOutside)

      if(dropDownTitle) {
        dropDownTitle.classList.remove('event-detail-preview__param-title-dropdown_open')
      }
    }

    function handleClickOutside(event) {
      if (!menuDescriptionElement.contains(event.target) && !descriptionElement.contains(event.target)) {
        closeMenu()
      }
    }
  }
})
