import { ready } from '../../../../js/utils/documentReady'
import Inputmask from 'inputmask'

ready(() => {
  for(let dateElement of document.querySelectorAll('.js-date')) {
    const im = new Inputmask({
      mask: '99.99.9999',
      placeholder: '_',
      showMaskOnHover: false,
    })

    im.mask(dateElement)
  }

  $('.js-date').each(function() {
    $(this).rules('add', {
      stringDate: true,
    })
  })
})
