export const getEventProgram = async (date) => {
  const ajaxUrl = $('.event-program').data('ajax-url')

  try {
    const response = await fetch(
      `${ajaxUrl}?` +
        new URLSearchParams({
          date,
        }).toString(),
    )

    if (!response.ok) {
      throw new Error('Error occurred!')
    }

    const data = await response.json()

    return data?.data
  } catch (error) {
    console.log(error)
  }
}
