import Hls from 'hls.js'
import { ready } from '../../../js/utils/documentReady'
import { pauseMedia } from '../../../js/utils/pauseMedia'

ready(() => {
  const galleries = document.querySelectorAll('.gallery')

  galleries.forEach((galleryElement) => {
    const imagesButton = galleryElement.querySelector('.gallery__filter-item-images')
    const videoButton = galleryElement.querySelector('.gallery__filter-item-video')

    setTimeout(() => {
      hlsVideoInit(galleryElement)

      if (imagesButton && videoButton) {
        imagesButton.addEventListener('click', () => {
          galleryElement.classList.remove('gallery_video')
          galleryElement.classList.add('gallery_images')
          pauseMedia(galleryElement)
        })

        videoButton.addEventListener('click', () => {
          galleryElement.classList.remove('gallery_images')
          galleryElement.classList.add('gallery_video')
        })
      }

      galleryElement.querySelectorAll('.gallery__preview').forEach((preview) => {
        preview.addEventListener('click', function handler() {
          // this.classList.add('gallery__preview_active')
          //
          // const video = this.querySelector('video')
          // const iframe = this.querySelector('iframe')
          //
          // if (video) {
          //   video.play()
          // } else if (iframe) {
          //   const src = iframe.getAttribute('src')
          //   const newSrc = src.includes('?')
          //     ? `${src}&autoplay=1`
          //     : `${src}?autoplay=1`
          //   iframe.setAttribute('src', newSrc)
          // }
          //
          // preview.removeEventListener('click', handler)
        })
      })
    }, 0)
  })
})

function hlsVideoInit(galleryElement) {
  const galleryVideo = galleryElement.querySelector('.js-swiperGalleryVideo')

  if (galleryVideo) {
    const videoList = galleryVideo.querySelectorAll('video')

    if (videoList && videoList.length) {
      videoList.forEach((video) => {
        const videoSrc = video.getAttribute('data-src')

        if (videoSrc && Hls.isSupported()) {
          const hls = new Hls()
          hls.loadSource(videoSrc)
          hls.attachMedia(video)
        }
      })
    }
  }
}
