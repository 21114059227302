import { ready } from '../../../js/utils/documentReady'
import { getSize } from '../../../js/utils/breakpoints'
import {updateMap} from '../map3/scripts/render-map-content/update-map'
import {areasMapRef} from '../map3/map3'
import {filterAreasData} from '../map3/scripts/filter-data'

ready(() => {
  const select = document.querySelector('.js-selectAreasInputWrapper')
  const buttonModalBack = document.querySelector('.js-selectAreasHeaderButton')
  const buttonModalAccept = document.querySelector('.js-selectAreasAcceptButton')
  const selectMenu = document.querySelector('.js-selectAreasMenu')
  const label = document.querySelector('.js-selectAreasLabel')
  const selectItems = document.querySelectorAll('.js-selectAreasItem')
  const modal = document.querySelector('.js-selectAreasModal')
  const { isMobile } = getSize()
  const isSelectAreas = document.querySelector('.js-selectAreas')

  function disableScroll() {
    document.body.classList.add('body-overflow-hidden')
  }

  function enableScroll() {
    document.body.classList.remove('body-overflow-hidden')
  }

  if (isSelectAreas) {
    function setMaxHeight() {
      const rect = selectMenu.getBoundingClientRect()
      const distanceToBottom = window.innerHeight - rect.top - 20
      const maxHeight = Math.max(200, distanceToBottom)
      selectMenu.style.maxHeight = `${maxHeight}px`
    }

    const onSelect = (item) => {
      const id = item.getAttribute('data-value')
      const defaultLabel = label.getAttribute('data-default-label')
      label.setAttribute('data-current-checked', id)

      // Обновление карты
      if (!isMobile && areasMapRef) {
        const filteredData = filterAreasData(areasMapRef.data, id)
        updateMap(filteredData)
      }

      if (id === 'all-areas') {
        label.textContent = defaultLabel
        return
      }

      label.textContent = item.textContent
    }

    const setPrevCheckedElement = () => {
      const prevCheckedElem = label.getAttribute('data-current-checked')

      selectItems.forEach(item => {
        const currentElement = item.getAttribute('data-value')
        const currentInputElement = item.querySelector('input')

        currentInputElement.checked = currentElement === prevCheckedElem
      })
    }

    selectItems.forEach(item => {
      item.addEventListener('click', () => {
        if (!isMobile) {
          onSelect(item)
        }
      })
    })

    // Главный инпут (сам селект)
    select.addEventListener('click', () => {
      event.stopPropagation()
      select.classList.toggle('active')
      setMaxHeight()

      if (isMobile) {

        disableScroll()
      }
    })

    // Кнопка назад в модалке
    buttonModalBack.addEventListener('click', () => {
      event.stopPropagation()

      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
      enableScroll()
    })

    // Кнопка "выбрать" на модалке
    buttonModalAccept.addEventListener('click', () => {
      event.preventDefault()
      event.stopPropagation()

      selectItems.forEach(item => {
        const currentInputElement = item.querySelector('input')
        const id = item.getAttribute('data-value')

        if (currentInputElement.checked) {
          onSelect(item)

          if (areasMapRef) {
            const filteredData = filterAreasData(areasMapRef.data, id)
            updateMap(filteredData)
          }
        }
      })

      enableScroll()
      select.classList.remove('active')
    })

    selectMenu.addEventListener('click', () => {
      event.stopPropagation()
    })

    // Затемнённая зона модалки
    modal.addEventListener('click', () => {
      event.stopPropagation()

      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
      enableScroll()
    })

    window.addEventListener('click', () => {
      if (isMobile) {
        setPrevCheckedElement()
      }

      select.classList.remove('active')
    })
  }
})
