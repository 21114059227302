/* gallery */
import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import { getSize } from '../../../js/utils/breakpoints'
import {ready} from '../../../js/utils/documentReady'

function getIsRetina() {
  return window.devicePixelRatio > 1
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

ready(() => {
  const galleryElements = document.querySelectorAll('.js-gallery-popup')
  const body = document.querySelector('body')

  for (const galleryElement of galleryElements) {
    const ref = {
      openGallery: () => {
      },
      dynamicGallery: null,
    }
    const imagesArray = []
    const itemsArrayElements = galleryElement.querySelectorAll('img')

    for (const itemsArrayElement of itemsArrayElements) {
      imagesArray.push({
        previewSrc: itemsArrayElement.dataset.previewSrc,
        src: itemsArrayElement.dataset.src,
        srcRetina: itemsArrayElement.dataset.srcRetina,
        srcMobile: itemsArrayElement.dataset.srcMobile,
        srcTablet: itemsArrayElement.dataset.srcTablet,
        download: itemsArrayElement.dataset.downloadLink,
        title: itemsArrayElement.dataset.title,
      })
    }

    const gallery = document.createElement('div')
    gallery.classList.add('gallery-popup')

    gallery.innerHTML = `<div class="gallery-popup__preview ${imagesArray.length <= 5 ? `gallery-popup__preview_${imagesArray.length}-items` : ''}">
      <div class="gallery-popup__preview-main-image">
        <!-- Главное изображение будет вставлено здесь -->
      </div>
      <div class="gallery-popup__preview-thumbnails">
        <!-- Миниатюры будут вставлены здесь -->
      </div>
    </div>

  <div class="gallery-popup__light-gallery-wrapper">
    <div class="gallery-popup__light-gallery">
      
    </div>
  </div>`

    galleryElement.parentNode.insertBefore(gallery, galleryElement)
    galleryElement.remove()

    const mainImageDiv = gallery.querySelector('.gallery-popup__preview-main-image')
    const lightGalleryDiv = gallery.querySelector('.gallery-popup__light-gallery')

    mainImageDiv.innerHTML = ''

    window.openGallery = (index) => {
      ref.openGallery(+index)
    }

    const isRetina = getIsRetina()
    const { isMobile, isTablet } = getSize()
    imagesArray.forEach((image) => {
      let dataSrc

      if (isMobile && image.srcMobile) {
        dataSrc = image.srcMobile
      } else if (isTablet && image.srcTablet) {
        dataSrc = image.srcTablet
      } else if (isRetina && image.srcRetina) {
        dataSrc = image.srcRetina
      } else {
        dataSrc = image.src
      }

      const galleryItemHTML = `<a href="${dataSrc}"><img data-src="${image.previewSrc}"></a>`
      lightGalleryDiv.insertAdjacentHTML('beforeend', galleryItemHTML)
    })

    const updateDynamicGallery = () => {
      ref?.dynamicGallery?.closeGallery(true)

      const createNewGallery = () => {
        ref?.dynamicGallery?.destroy()
        const { isMobile } = getSize()

        const setting = {
          plugins: [lgThumbnail],
          appendSubHtmlTo: '.lg-item',
          addClass: 'sublist-popup',
          controls: true,
          thumbnail: true,
          speed: 300,
          animateThumb: false,
          download: false,
          loop: false,
          thumbMargin: 8,
          thumbHeight: isMobile ? '60px' : '120px',
          thumbWidth: 100,
          mobileSettings: {
            controls: true,
            showCloseIcon: true,
            download: false,
          },
        }

        ref.dynamicGallery = lightGallery(lightGalleryDiv, setting)

        const lgComponent = ref.dynamicGallery.$container.selector.querySelector('.lg-components')

        const counter = document.createElement('div')
        counter.classList.add('lg-components-counter')
        lgComponent.appendChild(counter)

        const thumbContainer = lgComponent.querySelector('.lg-thumb-outer')

        if (!isTouchDevice()) {
          thumbContainer.classList.add('js-horizontalScroll')
          setTimeout(() => {
            window.initHorizontalScroll()
          })
        }

        const thumbItems = thumbContainer.querySelectorAll('.lg-thumb-item')

        for(let i of thumbItems) {
          i.addEventListener('touchend', (e) => e.stopImmediatePropagation())
        }

        const centerActiveSlide = (index) => {
          const activeThumb = thumbItems[index]
          if (activeThumb) {
            const containerWidth = thumbContainer.offsetWidth
            const containerHeight = thumbContainer.offsetHeight
            const thumbHalfWidth = activeThumb.offsetWidth / 2
            const thumbHalfHeight = activeThumb.offsetHeight / 2

            let scrollPositionX = activeThumb.offsetLeft - (containerWidth / 2) + thumbHalfWidth

            let scrollPositionY = activeThumb.offsetTop - (containerHeight / 2) + thumbHalfHeight

            const maxScrollLeft = thumbContainer.scrollWidth - containerWidth
            const maxScrollTop = thumbContainer.scrollHeight - containerHeight

            scrollPositionX = Math.max(0, Math.min(scrollPositionX, maxScrollLeft))
            scrollPositionY = Math.max(0, Math.min(scrollPositionY, maxScrollTop))

            thumbContainer.scrollTo({ left: scrollPositionX, top: scrollPositionY, behavior: 'smooth' })
          }
        }

        lightGalleryDiv.addEventListener('lgBeforeSlide', function(event) {
          centerActiveSlide(event.detail.index)
        })

        centerActiveSlide(0)
      }

      createNewGallery()
    }

    updateDynamicGallery()
    window.addEventListener('resize', updateDynamicGallery)

    lightGalleryDiv.addEventListener('lgBeforeOpen', () => {
      body.classList.add('body-overflow-hidden')

      const thumbImages = ref.dynamicGallery.$container.selector.querySelectorAll('.lg-thumb-item img')
      const sourceThumbImages = lightGalleryDiv.querySelectorAll('img')

      if(thumbImages.length === sourceThumbImages.length) {
        thumbImages.forEach((item, index) => {
          thumbImages[index].setAttribute('src', sourceThumbImages[index].getAttribute('data-src'))
        })
      }
    })

    lightGalleryDiv.addEventListener('lgBeforeOpen', () => {
      const header = document.querySelector('.lg-toolbar')

      if (header) {
        const title = document.querySelector('.lg-title')

        if (!title) {
          const titleDiv = document.createElement('div')
          titleDiv.className = 'lg-title'
          titleDiv.innerHTML = 'Города-суперзвёзды. Уроки успешной трансформации'
          header.appendChild(titleDiv)

          const downloadLink = document.createElement('a')
          downloadLink.className = 'lg-download'

          header.appendChild(downloadLink)
        }
      }
    })

    lightGalleryDiv.addEventListener('lgBeforeClose', () => {
      body.classList.remove('body-overflow-hidden')
    })

    lightGalleryDiv.addEventListener('lgDestroy', () => {
      body.classList.remove('body-overflow-hidden')
    })

    lightGalleryDiv.addEventListener('lgBeforeSlide', function(event) {
      const index = event.detail.index
      const totalItems = ref.dynamicGallery.galleryItems.length
      const item = imagesArray[index]
      const titleElement = document.querySelector('.lg-title')
      const downloadElement = document.querySelector('.lg-download')

      titleElement.innerHTML = item.title
      downloadElement.href = item.download
      downloadElement.setAttribute('download', 'download')

      if (index === 0) {
        document.querySelector('.lg-prev').setAttribute('disabled', 'disabled')
      } else {
        document.querySelector('.lg-prev').removeAttribute('disabled')
      }

      if (index === totalItems - 1) {
        document.querySelector('.lg-next').setAttribute('disabled', 'disabled')
      } else {
        document.querySelector('.lg-next').removeAttribute('disabled')
      }
    })

    ref.openGallery = (index) => {
      ref.dynamicGallery.openGallery(index)
    }
  }
})
