
import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { loadImages } from '../../../js/utils/loadImage'
import {executeOnScroll} from '../../../js/utils/executeOnScroll'
import {getSize} from '../../../js/utils/breakpoints'
import {loadingSlides} from '../../../js/utils/loadingSlides'

ready(() => {
  const swiperElement = document.querySelector('.js-swiperNews2')

  if (!swiperElement) return
  const swiperMainElement = document.querySelector('.js-swiperNews2Main')
  // eslint-disable-next-line no-unused-vars
  let secondSwiper

  const initSwiper = () => {
    const { isMobile, isTablet} = getSize()
    const mainSwiper = new Swiper('.js-swiperNews2Main', {
      modules: [Navigation, Pagination, EffectFade],
      spaceBetween: 24,
      allowTouchMove: isMobile,
      autoHeight: isMobile,
      effect: (!isMobile && !isTablet) ? 'fade' : 'slide',
      initialSlide: 1,
      loopAdditionalSlides: 3,
      loop: true,
      observer: true,
      observeParents: true,
      lazy:true,
      navigation: {
        nextEl: '.js-swiperNewsButtonNext',
        prevEl: '.js-swiperNewsButtonPrev',
        disabledClass: 'swiper-news__button-disabled',
      },
      fadeEffect: {
        crossFade: true
      },
      slidesPerView: 1,
      speed: 500,
      on: {
        init(swiper) {
          loadImages(swiper.slides[this.realIndex])
        },
        slideChange(swiper) {
          loadImages(swiper.slides[this.realIndex])
          if (swiper.slides[swiper.realIndex + 1]) {
            loadImages(swiper.slides[swiper.realIndex + 1])
          }
          loadingSlides(swiper.realIndex, swiper.slides, 4, 4)
        }
      },
    })

    if(!isMobile) {
      secondSwiper = new Swiper('.js-swiperNews2', {
        modules: [Navigation, Pagination],
        spaceBetween: 60,
        speed: 500,
        slidesPerView: 3,
        initialSlide: 1,
        loop: true,
        loopAdditionalSlides: 3,
        navigation: {
          nextEl: '.js-swiperNewsButtonNext',
          prevEl: '.js-swiperNewsButtonPrev',
          disabledClass: 'swiper-news__button-disabled',
        },
        breakpoints: {
          320: {

          },
          768: {
            spaceBetween: 48,
            slidesPerView: 'auto',
          },
          1280: {
            slidesPerView: 3,
          },
          1900: {
            spaceBetween: 60,
          },
        },
        on: {
          init(swiper) {
            loadingSlides(swiper.realIndex, swiper.slides, 4, 4)
          },
          slideChange(swiper) {
            const { isTablet} = getSize()
            const slidesLength = swiper.slides.length
            
            if(!isTablet) {
              if (swiper.realIndex - 1 === slidesLength - 1) {
                mainSwiper.slideToLoop((slidesLength - slidesLength))
              } else if (swiper.realIndex === 0) {
                mainSwiper.slideToLoop((slidesLength - 1))
              } else {
                mainSwiper.slideToLoop(swiper.realIndex - 1)
              }
            } 

            loadingSlides(swiper.realIndex, swiper.slides, 4, 4)
          }
        },
      })
    }

    const newsContainer = document.querySelector('.news-2')

    if (newsContainer) {
      function updateNewsStyles() {
        const { isMobile, isTablet} = getSize()
        if (newsContainer && !isMobile && !isTablet) {
          const newsPreview = document.querySelector('.news-2__preview')
          const newsContent = document.querySelector('.news-2__content')
          const swiper = document.querySelector('.js-swiperNews2')
          const rect = newsContainer.getBoundingClientRect()
          const distanceFromLeft = rect.left

          newsPreview.style.marginLeft = `-${distanceFromLeft}px`
          newsPreview.style.width = `calc(100% + ${distanceFromLeft}px)`
          newsContent.style.width = `calc(100% + ${distanceFromLeft}px)`
          swiper.style.paddingRight = `${distanceFromLeft}px`
        }
      }

      updateNewsStyles()

      window.addEventListener('resize', updateNewsStyles)
    }
  }

  executeOnScroll(swiperMainElement, initSwiper)
})
