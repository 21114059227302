/* cookie */
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  setTimeout(() => {
    const cookieBanner = document.querySelector('.js-cookies')
    const cookieBtn = document.querySelector('.js-cookiesBtn')

    if (cookieBanner && cookieBtn) {
      if (!document.cookie.includes('cookie-moscow2030_accept=true')) {
        cookieBanner.classList.add('cookies_view')
      }

      cookieBtn.addEventListener('click', () => {
        const date = new Date()
        date.setFullYear(date.getFullYear() + 1)
        document.cookie = `cookie-moscow2030_accept=true; path=/; expires=${date.toUTCString()}`
        cookieBanner.classList.remove('cookies_view')
      })
    }
  }, 5000)
})
