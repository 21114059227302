export const getAreasMapData = async () => {
  const ajaxUrl = $('.areas-map').data('ajax-url')

  try {
    const response = await fetch(ajaxUrl)

    if (!response.ok) {
      throw new Error('Error occurred!')
    }

    const data = await response.json()

    return data?.data
  } catch (error) {
    console.log(error)
  }
}
