import { ready } from '../../utils/documentReady'
import { getSize } from '../../utils/breakpoints'

ready(() => {
  const overlay = document.querySelector('.js-eventDetailBgOverlay')

  if (overlay) {
    const previewImage = document.querySelector('.event-detail-preview__image, .event-detail-preview__image-square')
    const tags = document.querySelector('.event-detail-preview__description-dark-side')
    const isSquare = !!document.querySelector('.event-detail-preview__image-square')

    if (previewImage && tags) {
      const calcHeightOverlay = () => {
        overlay.style.setProperty('--force-height', '0px')
        const { isMobile, isTablet } = getSize()
        const overlayRect = overlay.getBoundingClientRect()
        const previewImageRect = previewImage.getBoundingClientRect()
        const tagsRect = tags.getBoundingClientRect()

        const overlayBottom = overlayRect.bottom + window.scrollY
        const previewImageBottom = previewImageRect.bottom + window.scrollY
        const tagsBottom = tagsRect.bottom + window.scrollY

        const distanceToPreviewImage = previewImageBottom - overlayBottom
        const distanceToTags = tagsBottom - overlayBottom

        if(isMobile || isTablet) {
          const cityDayElement = document.querySelector('.city-day-page')
          if(cityDayElement) {
            if(isTablet) {
              overlay.style.setProperty('--force-height', `${-distanceToPreviewImage + previewImageRect.height - 35}px`)
            } else {
              overlay.style.setProperty('--force-height', `${-distanceToPreviewImage + previewImageRect.height * 0.8}px`)
            }
          } else {
            overlay.style.setProperty('--force-height', `${-distanceToPreviewImage + previewImageRect.height / 2}px`)
          }
        } else {
          overlay.style.setProperty('--force-height', `${-distanceToTags - (isSquare ? 80 : 40)}px`)
        }
      }

      calcHeightOverlay()
      window.addEventListener('resize', calcHeightOverlay)
    }
  }
})
