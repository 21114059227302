import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const parentBlock = document.querySelector('.timeline3d_preview')

  if (parentBlock) {
    const polygonsBlock = parentBlock.querySelector('.directions-polygon__wrapper')
    if(polygonsBlock) {
      const polygonsImg = polygonsBlock.querySelector('.directions-polygon__img')
      const polygons = polygonsBlock.querySelectorAll('path')

      if (polygonsBlock) {
        window.cleanupPreview3dTimeline = () => {
          polygons.forEach((polygon) => {
            parentBlock.classList.remove('timeline3d_preview')
            const clone = polygon.cloneNode(true)
            polygon.parentNode.replaceChild(clone, polygon)
          })

          parentBlock.removeEventListener('mousemove', handleMouseMove)

          polygonsBlock.remove()
        }

        const handleMouseMove = (event) => {
          const centerX = window.innerWidth / 2
          const angle = ((event.clientX - centerX) / centerX) * -20
          polygonsImg.style.transform = `rotateY(${angle}deg)`
        }

        parentBlock.addEventListener('mousemove', handleMouseMove)

        polygons.forEach((polygon, index) => {
          polygon.addEventListener('click', () => {
            window?.setActiveTab(index)
            window?.cleanupPreview3dTimeline()
          })
        })
      }
    }
  }
})
