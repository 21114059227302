import Swiper from 'swiper'
import { EffectFade, Navigation, Pagination} from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'
import { loadImages } from '../../../js/utils/loadImage'
import { getSize } from '../../../js/utils/breakpoints'
import { loadingSlides } from '../../../js/utils/loadingSlides'

ready(() => {
  const filter = document.querySelector('.reviews__filter')
  const buttons = document.querySelectorAll('.reviews__filter-item')
  const swiperElement = document.querySelector('.js-swiperReviews')
  const reviewsBlock = document.querySelector('.reviews')
  
  if (reviewsBlock) {
    const swipersInit = reviewsBlock.querySelectorAll('.swiper')
    const filterLabels =  document.querySelectorAll('.js-selectFilterLabel')
    const buttonModalAccept = document.querySelector('.js-selectFilterAcceptButton')
    const buttonModalBack = document.querySelector('.js-selectFilterHeaderButton')
    const select = document.querySelector('.js-selectAreasInputWrapper')
    const arrowNext = document.querySelectorAll('.reviews .js-swiperNewsButtonNextMain')
    const arrowPrev = document.querySelectorAll('.reviews .js-swiperNewsButtonPrevMain')
    const filterWrapper = document.querySelector('.reviews__filter-wrap')

    if (filter) {
      if (filter.childElementCount <= 2) {
        filter.parentElement.style.display = 'none'
      }

      if (buttons) {
        buttons.forEach( item => {
          item.addEventListener('click', function() {
            // Удалить активный класс со всех фильтров
            document.querySelectorAll('.gallery__filter-item').forEach(filter => filter.classList.remove('active'))
            // Добавить активный класс нажатому фильтру
            this.classList.add('active')

            // Скрыть все блоки
            document.querySelectorAll('.news-2').forEach(news => news.style.display = 'none')

            // Отобразить соответствующий блок
            const filterId = this.dataset.activeId
            const newsBlock = document.getElementById(`${filterId}`)
            const { isMobile} = getSize()
            const filterButtonWidth = item.offsetLeft/1.5

            let activeSlider = document.querySelectorAll('.js-swiperReviewsMain')[Number(item.dataset.id)]
            console.log(item.dataset.id)
            console.log(activeSlider)
            const slidesLength = activeSlider.querySelectorAll('.swiper-slide').length
            if(slidesLength <= 3) {
              arrowNext[Number(item.dataset.id)].classList.add('reviews__button-disabled')
              arrowPrev[Number(item.dataset.id)].classList.add('reviews__button-disabled')
            }

            if (isMobile) {
              filterWrapper.scroll({ left: filterButtonWidth, behavior: 'smooth' })
            }

            newsBlock.style.display = 'block'

            filterLabels.forEach(label => {
              label.innerText = item.innerText
            })
            
            updtFunction()
          })
        })

        if (buttonModalAccept) {
          buttonModalAccept.addEventListener('click', () => {
            event.preventDefault()
            event.stopPropagation()

            select.classList.remove('active')
            document.body.classList.remove('body-overflow-hidden')
          })
        }

        if (buttonModalBack) {
          buttonModalBack.addEventListener('click', () => {
            event.stopPropagation()

            select.classList.remove('active')
            document.body.classList.remove('body-overflow-hidden')
          })
        }
      }
    }

    const updtFunction = function () {
      swipersInit.forEach(swiper => {
        const swiperInstance = swiper.swiper
        if (swiperInstance) {
          swiperInstance.destroy()
        }
      })
      initSwiper()
    }

    if (!swiperElement) return
    const swipers = document.querySelectorAll('.reviews .news-2')
    
    swipers.forEach(swiper => {
      const swiperMainSliders = swiper.querySelector('.js-swiperReviewsMain .swiper-wrapper')
      const swiperPreviewsSliders = swiper.querySelector('.js-swiperReviews .swiper-wrapper')
      const originalSlidesMain = swiperMainSliders.querySelectorAll('.swiper-slide')
      const originalSlidesPreviews = swiperPreviewsSliders.querySelectorAll('.swiper-slide')
      
      // клонируем слайды
      if (originalSlidesPreviews.length >= 4) {
        originalSlidesPreviews.forEach(slide => {
          let clone = slide.cloneNode(true)
          swiperPreviewsSliders.appendChild(clone)
        })
        
        originalSlidesMain.forEach(slide => {
          let clone = slide.cloneNode(true)
          swiperMainSliders.appendChild(clone)
        })
      }
    })

    const initSwiper = () => {
      const { isMobile, isTablet } = getSize()

      swipers.forEach((elem) => {
        const secondarySwiper = new Swiper(elem.querySelector('.js-swiperReviews'), {
          spaceBetween: 60,
          speed: 500,
          slidesPerView: 3.5,
          observer: true,
          observeParents: true,
          initialSlide: 1,
          loop: true,
          loopAdditionalSlides: 3,
          breakpoints: {
            768: {
              spaceBetween: 48,
              slidesPerView: 'auto',
            },
            1280: {
              slidesPerView: 3.4,
            },
            1440: {
              spaceBetween: 48
            },
            1900: {
              spaceBetween: 60,
            },
          },
          on: {
            init(swiper) {
              loadingSlides(swiper.realIndex, swiper.slides, 2, 4)
              loadImages(swiper.slides[swiper.realIndex])
            },
            slideChangeTransitionStart(swiper) {
              loadingSlides(swiper.realIndex, swiper.slides, 2, 4)
            }
          },
        })

        const mainSwiper = new Swiper(elem.querySelector('.js-swiperReviewsMain'), {
          modules: [Navigation, Pagination, EffectFade],
          spaceBetween: 24,
          allowTouchMove: isTablet || isMobile,
          autoHeight: isMobile,
          effect: (isTablet || isMobile) ? 'slide' : 'fade',
          loop: true,
          loopAdditionalSlides: 3,
          observer: true,
          observeParents: true,
          lazy:true,
          navigation: {
            nextEl: elem.querySelector('.js-swiperNewsButtonNextMain1'),
            prevEl: elem.querySelector('.js-swiperNewsButtonPrevMain1'),
            disabledClass: 'swiper-news__button-disabled',
          },
          pagination: {
            el: (!isMobile && !isTablet) ? elem.querySelector('.js-reviewsCounter') : elem.querySelector('.js-reviewsCounter1'),
            type: 'bullets',
          },
          fadeEffect: {
            crossFade: true
          },
          slidesPerView: 1,
          speed: 500,
          breakpoints: {
            768: {
              spaceBetween: 60,
            },
          },
          on: {
            init(swiper) {
              loadImages(swiper.slides[swiper.realIndex])
            },
            slideChangeTransitionStart(swiper) {
              loadImages(swiper.slides[swiper.realIndex])
              if (swiper.slides[swiper.realIndex + 1]) {
                loadImages(swiper.slides[swiper.realIndex + 1])
              }
            },
          },
        })

        const slidesLength = secondarySwiper.slides.length

        arrowNext.forEach(el => {
          el.addEventListener('click', () => {
            if (secondarySwiper.realIndex === slidesLength - 1) {
              mainSwiper.slideToLoop(secondarySwiper.realIndex)
              secondarySwiper.slideNext(500)
            } else {
              secondarySwiper.slideNext(500)
              mainSwiper.slideToLoop(secondarySwiper.realIndex-1)
            }
          })
        })
        arrowPrev.forEach(el => {
          el.addEventListener('click', () => {
            if (secondarySwiper.realIndex === 1) {
              mainSwiper.slideToLoop(1)
              secondarySwiper.slidePrev(500)
            } else {
              secondarySwiper.slidePrev(500)
              mainSwiper.slideToLoop(secondarySwiper.realIndex-1)
            }
          })
        })
        
        secondarySwiper.slides.forEach((slide, index) => {
          slide.addEventListener('click', () => {
            mainSwiper.slideTo(index)
            if (index === slidesLength - 1) {
              secondarySwiper.slideToLoop(0)
            } else {
              secondarySwiper.slideToLoop(index+1)
              mainSwiper.slideTo(index)
            }
          })
        })
      })
    }
    initSwiper()
  }
})
