/**
 Этот код реализует функционал кастомного горизонтального скроллбара для блоков с контентом, который может выходить за
 границы его видимой области. Основные возможности и механизмы работы включают:

 1. **Инициализация скроллбара:** Для каждого блока контента создается и стилизуется элемент скроллбара, который
    отображается в его родительском элементе.

 2. **Обновление скроллбара:** При изменении размеров окна браузера или изменении положения прокрутки контента
    обновляется размер и положение скроллбара, чтобы отражать текущее состояние прокрутки.

 3. **Управление скроллом через перетаскивание:** Пользователи могут перетаскивать контент внутри блока
    (или сам скроллбар) мышью или касанием, чтобы прокручивать его горизонтально. В коде предусмотрены механизмы для
    определения начала перетаскивания, отслеживания движения и остановки перетаскивания.

 4. **Предотвращение клика при перетаскивании:** Если пользователь перетаскивал контент, то клик по элементам внутри
    блока будет предотвращен, чтобы избежать случайных нажатий.

 5. **Адаптивность:** Скроллбар автоматически адаптируется к изменениям размера контента и родительского блока,
    обеспечивая корректное отображение и функционирование на различных устройствах и при изменении размеров окна браузера.

 **Применение кода:**

 Чтобы использовать этот функционал, необходимо:

 1. Добавить на страницу HTML-элементы с классом .js-scrollBlockInContainer для контента, который должен поддерживать
    прокрутку с кастомным скроллбаром.

 2.
   На элемент который повесил .js-scrollBlockInContainer нужно повесить следующие стили
   min-width: 100%;
   width: fit-content;

 Этот подход позволяет создавать более удобный и стилизованный интерфейс для прокрутки содержимого, особенно когда
 стандартный скроллбар не соответствует дизайну сайта или приложения.
 */

import { ready } from './documentReady'

ready(() => {
  const scrolledBlocks = document.querySelectorAll('.js-scrollBlockInContainer')
  let initialMarginLeft

  function updateScrollbar(wrapperScrollBar, scrolled) {
    const scrollbar = wrapperScrollBar.querySelector('.custom-scrollbar__inner')
    const scrolledBlock = wrapperScrollBar.querySelector(
      '.js-scrollBlockInContainer',
    )
    const isScrollNeeded = scrolledBlock.scrollWidth > wrapperScrollBar.offsetWidth
    const isDropScrollbar = scrolledBlock.getAttribute('data-drop-scrollbar')

    if (isScrollNeeded) {
      const scrollbarWidth = (wrapperScrollBar.offsetWidth / scrolledBlock.scrollWidth)
          * wrapperScrollBar.offsetWidth
      scrollbar.style.width = `${scrollbarWidth}px`

      const scrollRatio = scrollbarWidth / wrapperScrollBar.offsetWidth
      const scrollbarPosition = -scrollRatio * scrolled
      scrollbar.style.left = `${scrollbarPosition}px`

      if (!isDropScrollbar) {
        wrapperScrollBar.classList.add('scroll-block')
      }
    } else {
      wrapperScrollBar.classList.remove('scroll-block')
    }
  }

  for (const scrolledBlock of scrolledBlocks) {
    let isDragging = false
    let startX
    let initialPageX // Добавляем переменную для хранения начального положения курсора/пальца
    let hasScrolled = false // Флаг, указывающий на проскроливание на 5 пикселей
    const scrollStartGap = 15

    const wrapperScrollBar = scrolledBlock.parentElement
    const scrollBar = document.createElement('div')
    scrollBar.innerHTML = '<div class="custom-scrollbar__inner"></div>'
    scrollBar.classList.add('custom-scrollbar')
    wrapperScrollBar.appendChild(scrollBar)
    const isDropScrollbar = scrolledBlock.getAttribute('data-drop-scrollbar')

    if (!isDropScrollbar) {
      wrapperScrollBar.classList.add('scroll-block')
    }
    updateScrollbar(wrapperScrollBar, 0)
    const scrollBarInner = scrollBar.querySelector('.custom-scrollbar__inner')

    const handleDragStart = (e) => {
      const startXScrollbar = e.type === 'mousedown' ? e.pageX : e.touches[0].pageX
      const initialScrollLeft = scrollBarInner.offsetLeft
      let isDraggingScrollbar = true

      function onDrag(event) {
        if (!isDraggingScrollbar) return
        const deltaX = (event.type === 'mousemove' ? event.pageX : event.touches[0].pageX)
              - startXScrollbar
        let newLeft = initialScrollLeft + deltaX
        const maxLeft = scrollBar.offsetWidth - scrollBarInner.offsetWidth
        newLeft = Math.max(0, Math.min(maxLeft, newLeft))
        scrollBarInner.style.left = `${newLeft}px`
        const scrollRatio = (scrolledBlock.scrollWidth - scrollBar.offsetWidth) / maxLeft
        scrolledBlock.style.marginLeft = `-${newLeft * scrollRatio}px`
        updateScrollbar(wrapperScrollBar, -newLeft * scrollRatio)
      }

      function onDragEnd() {
        isDraggingScrollbar = false
        document.removeEventListener('mousemove', onDrag)
        document.removeEventListener('mouseup', onDragEnd)
        document.removeEventListener('touchmove', onDrag)
        document.removeEventListener('touchend', onDragEnd)
      }

      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', onDragEnd)
      document.addEventListener('touchmove', onDrag)
      document.addEventListener('touchend', onDragEnd)

      e.preventDefault()
    }

    scrollBarInner.addEventListener('mousedown', handleDragStart)
    scrollBarInner.addEventListener('touchstart', handleDragStart)

    scrolledBlock.addEventListener('click', (e) => {
      if (hasScrolled) {
        e.preventDefault()
      }
    })

    scrolledBlock.addEventListener('mousedown', (e) => {
      e.preventDefault()
      isDragging = true
      startX = e.pageX - scrolledBlock.offsetLeft
      initialPageX = e.pageX // Сохраняем начальное положение курсора
      hasScrolled = false // Сбрасываем флаг проскроливания
    })

    scrolledBlock.addEventListener('touchstart', (e) => {
      isDragging = true
      startX = e.touches[0].pageX - scrolledBlock.offsetLeft
      initialPageX = e.touches[0].pageX
      hasScrolled = false
    })

    document.addEventListener('mousemove', (e) => {
      if (!isDragging) return

      if (!hasScrolled) {
        if (Math.abs(e.pageX - initialPageX) < scrollStartGap) return
        hasScrolled = true
        const style = window.getComputedStyle(scrolledBlock)
        initialMarginLeft = parseInt(style.marginLeft, 10) || 0

        startX = e.pageX
      }

      e.preventDefault()

      const scrollSpeedMultiplier = 1.2
      let moveX = (e.pageX - startX) * scrollSpeedMultiplier + initialMarginLeft

      const maxMoveX = 0
      const minMoveX = -(scrolledBlock.offsetWidth - scrolledBlock.parentElement.offsetWidth)

      moveX = Math.min(maxMoveX, moveX)
      moveX = Math.max(minMoveX, moveX)

      scrolledBlock.style.marginLeft = `${moveX}px`
      updateScrollbar(wrapperScrollBar, moveX)
    })

    document.addEventListener('mouseup', () => {
      isDragging = false
    })

    scrolledBlock.addEventListener('touchmove', (e) => {
      if (!isDragging) return

      if (!hasScrolled) {
        if (Math.abs(e.touches[0].pageX - initialPageX) < scrollStartGap) return
        hasScrolled = true
        const style = window.getComputedStyle(scrolledBlock)
        initialMarginLeft = parseInt(style.marginLeft, 10)

        startX = e.touches[0].pageX
      }

      e.preventDefault()

      const scrollSpeedMultiplier = 1.2
      let moveX = (e.touches[0].pageX - startX) * scrollSpeedMultiplier + initialMarginLeft

      const maxMoveX = 0
      const minMoveX = -(scrolledBlock.offsetWidth - scrolledBlock.parentElement.offsetWidth)

      moveX = Math.min(maxMoveX, moveX)
      moveX = Math.max(minMoveX, moveX)

      scrolledBlock.style.marginLeft = `${moveX}px`
      updateScrollbar(wrapperScrollBar, moveX)
    })

    scrolledBlock.addEventListener('touchend', () => {
      isDragging = false
    })

    window.addEventListener('resize', () => {
      const currentMargin = parseInt(scrolledBlock.style.marginLeft, 10) || 0

      const maxMoveX = 0
      const minMoveX = -(
        scrolledBlock.offsetWidth - scrolledBlock.parentElement.offsetWidth
      )
      let correctedMargin = Math.min(maxMoveX, currentMargin)
      correctedMargin = Math.max(minMoveX, correctedMargin)

      scrolledBlock.style.marginLeft = `${correctedMargin}px`
      updateScrollbar(wrapperScrollBar, correctedMargin)
    })
  }
})

