import Hls from 'hls.js'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const videoContainer = document.querySelector('.js-main-video')
  const videoOverlay = document.querySelector('.js-video-overlay')

  if (videoContainer) {
    const video = videoContainer.querySelector('video')
    const voiceToggle = videoContainer.querySelector('.main-video__voice')
    const placeholder = document.querySelector('.js-mainVideoPlaceholder')

    const videoSrc = video.getAttribute('data-src')

    if (videoSrc && Hls.isSupported()) {
      const hls = new Hls()

      setTimeout(() => {
        hls.loadSource(videoSrc)
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play().then(() => {
            placeholder.style.display = 'none'
          })
        })
        // 3600 data-duration='2.5s' data-delay='0.1s' data-delay-load='1s')
      }, 3600)
    } else {
      setTimeout(() => {
        video.src = videoSrc
        video.play().then(() => {
          placeholder.style.display = 'none'
        }).catch((error) => {
          console.log(error)
        })
        // 3600 data-duration='2.5s' data-delay='0.1s' data-delay-load='1s')
      }, 3600)
    }

    voiceToggle.addEventListener('click', function() {
      if (video.muted) {
        video.muted = false
        voiceToggle.classList.add('main-video__voice_active')
      } else {
        video.muted = true
        voiceToggle.classList.remove('main-video__voice_active')
      }
    })
  } else if (videoOverlay) {
    videoOverlay.style.display = 'none'
  }
})
