export const marginsController = ({ ref }) => {
  const margins = {}
  const controller = {
    setMargins: function (blockName, newMargins) {
      margins[blockName] = newMargins
      updateGlobalMargins()
    },
    margins: [0, 0, 0, 0]
  }

  const updateGlobalMargins = () => {
    const { map } = ref
    const maxMargins = [0, 0, 0, 0]

    for (const block in margins) {
      const currentMargins = margins[block]
      for (let i = 0; i < maxMargins.length; i++) {
        maxMargins[i] = Math.max(maxMargins[i], currentMargins[i])
      }
    }

    controller.margins = maxMargins
    map.setMargin(maxMargins)
  }

  return controller
}
