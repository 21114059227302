import { ready } from './documentReady'
import { getSize } from './breakpoints'

ready(() => {
  const bgElement = document.querySelector('.main__bg')
  const { isMobile } = getSize()

  const loadImages = () => {
    if (bgElement) {
      const imgElements = bgElement.querySelectorAll('img')
      const totalImages = imgElements.length
      let loadedImages = 0

      const onImageLoad = () => {
        loadedImages++
        if (loadedImages === totalImages) {
          bgElement.classList.add('main__bg_view')
        }
      }

      imgElements.forEach((imgElement) => {
        if (!imgElement.src) {
          const dataSrc = imgElement.getAttribute('data-src')
          if (dataSrc) {
            imgElement.src = dataSrc
            imgElement.onload = onImageLoad
            imgElement.onerror = onImageLoad
          }
        } else {
          if (imgElement.complete) {
            onImageLoad()
          } else {
            imgElement.onload = onImageLoad
            imgElement.onerror = onImageLoad
          }
        }
      })
    }
  }

  if (!isMobile) {
    loadImages()
  } else {
    const onFirstInteraction = () => {
      loadImages()
      window.removeEventListener('click', onFirstInteraction)
      window.removeEventListener('scroll', onFirstInteraction)
      window.removeEventListener('keydown', onFirstInteraction)
      window.removeEventListener('touchstart', onFirstInteraction)
    }

    window.addEventListener('click', onFirstInteraction, { once: true })
    window.addEventListener('scroll', onFirstInteraction, { once: true })
    window.addEventListener('keydown', onFirstInteraction, { once: true })
    window.addEventListener('touchstart', onFirstInteraction, { once: true })
  }
})
