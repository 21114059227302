export const renderDay = (day) => {
  return `
    <div data-date=${day.formattedDate} class="event-program__day ${day.isWeekend && !day.isDisabled ? 'event-program__day_weekend' : ''} ${day.isDisabled ? 'event-program__day_disabled' : ''} ${day.isActive ? 'event-program__day_active' : ''} ${day.isToday ? 'event-program__day_today' : ''}">
      <div class="event-program__day-title">
        ${day.day}
      </div>
      <div class="event-program__day-type">
        ${getDayOfWeek(day.dayOfWeek)}
      </div>
    </div>
  `
}

function getDayOfWeek(dayNumber) {
  const daysOfWeek = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']

  if (dayNumber < 0 || dayNumber > 6) {
    throw new Error('dayNumber должен быть в диапазоне от 0 до 6')
  }

  return daysOfWeek[dayNumber]
}
