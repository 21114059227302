import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('stringPassword', function(value) {
    const hasUpperCase = /[A-Z]/.test(value)
    const hasLowerCase = /[a-z]/.test(value)
    const hasNumbers = /\d/.test(value)
    const hasSpecialChars = /[\W_]/.test(value)

    return hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars
  }, 'Пароль должен содержать хотя бы одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.')
})
