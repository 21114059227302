export function pauseMedia(element) {
  const videos = element.querySelectorAll('video')
  videos.forEach(video => {
    video.pause()
  })

  const iframes = element.querySelectorAll('iframe')
  iframes.forEach(iframe => {
    const iframeWindow = iframe.contentWindow
    if (iframeWindow) {
      iframeWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    }
  })
}
