import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('checkboxRequired', function(value, element) {

    const errorElement = element.closest('.field-single-checkbox').querySelector('.field-single-checkbox__error');

    if (!!value) {
      errorElement.style.display = 'none';
    } else {
      errorElement.style.display = 'block';
    }

    return !!value;
  }, function(params, element) {
    return `Дайте согласие на обработку персональных данных`;
  });
})
