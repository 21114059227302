export function loadImages(element) {
  const images = element?.querySelectorAll('img[data-src], source[data-srcset]')
  images?.forEach((img) => {
    if (img.tagName === 'IMG') {
      img.setAttribute('src', img.getAttribute('data-src'))
      img.removeAttribute('data-src')
    } else if (img.tagName === 'SOURCE') {
      img.setAttribute('srcset', img.getAttribute('data-srcset'))
      img.removeAttribute('data-srcset')
    }
  })
}
