import { ready } from '../../../../js/utils/documentReady'

ready(() => {
  $('.js-password').each(function() {
    $(this).rules('add', {
      stringPassword: true,
    })
  })

  $('.js-confirmPassword').each(function() {
    $(this).rules('add', {
      equalTo: '.js-password',
    })
  })

})
