window.showNotification = (message = 'Sample Message', toastType = 'danger') => {
  const box = document.createElement('div')

  box.classList.add('toast', `toast-${toastType}`)

  box.innerHTML = ` <div class="toast-content-wrapper"> 
                      <div class="toast-notification__icon_${toastType}"></div>
                      <div class="toast-message text-16 lh-29 font-500 text-mob-12 lh-mob-20 font-mob-400">${message}</div> 
                    </div>`

  let toastAlready = document.body.querySelector('.toast')

  box.addEventListener('click', () => {
    box.classList.add('closing')

    setTimeout(() => {
      box.remove()
    }, 2000)
  })

  if (toastAlready) {
    toastAlready.remove()
  }

  document.body.appendChild(box)
}
