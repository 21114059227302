import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const eventsMasonryElements = document.querySelectorAll('.js-eventsGalleryMasonry')
  const masonryInstances = []

  eventsMasonryElements.forEach((eventsMasonry) => {
    var msnry = new Masonry(eventsMasonry, {
      itemSelector: '.grid-events-masonry-item',
      columnWidth: '.grid-sizer',
      gutter: '.grid-events-masonry-gutter',
    })

    const masonryItems = eventsMasonry.querySelectorAll('.gallery-mansory__image')

    masonryItems.forEach(item => {
      const img = item.querySelector('.lazy-picture img')

      if (img) {
        const handleImageLoad = () => {
          const skeleton = item.querySelector('.gallery-mansory__skeleton-item')

          if(skeleton) {
            skeleton.classList.add('gallery-mansory__skeleton-item_hidden')
          }
        }

        if (!img.src) {
          const observer = new MutationObserver((mutationsList, observer) => {
            mutationsList.forEach(mutation => {
              if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
                if (img.complete) {
                  console.log('complete')
                  handleImageLoad()
                } else {
                  console.log('load')
                  img.addEventListener('load', handleImageLoad)
                }
                observer.disconnect()
              }
            })
          })

          observer.observe(img, { attributes: true })
        } else {
          if (img.complete) {
            handleImageLoad()
          } else {
            img.addEventListener('load', handleImageLoad)
          }
        }
      }
    })

    masonryInstances.push(msnry)
  })

  const gallerySkeletonHide = () => {
    const mansoryElements = document.querySelectorAll('.gallery-mansory__image')

    if (mansoryElements.length) {
      mansoryElements.forEach((item) => {
        const img = item.querySelector('.lazy-picture img')

        if (img) {
          const skeleton = item.querySelector('.gallery-mansory__skeleton-item')

          if (skeleton) {
            skeleton.classList.add('gallery-mansory__skeleton-item_hidden')
          }
        }
      })
    }
  }

  window.masonryGalleryAppend = (newsElements) => {
    masonryInstances.forEach(msnry => {
      msnry.appended(newsElements)
      imagesLoaded(msnry.element).on('progress', function() {
        msnry.layout()
        gallerySkeletonHide()
      })
    })
  }

  window.masonryGalleryInit = (newsElements, id) => {
    masonryInstances.forEach(msnry => {
      if (id) {
        if (msnry.$element[0].id === id) {
          msnry.remove(msnry.getItemElements())
          msnry.appended(newsElements)
          imagesLoaded(msnry.element).on('progress', function() {
            msnry.layout()
            gallerySkeletonHide()
          })
        }
      } else {
        msnry.remove(msnry.getItemElements())
        msnry.appended(newsElements)
        imagesLoaded(msnry.element).on('progress', function() {
          msnry.layout()
          gallerySkeletonHide()
        })
      }
    })
  }

  window.addEventToMansoryElements = () => {
    const mansoryElements = document.querySelectorAll('.gallery-mansory__image')

    for (let i of mansoryElements) {
      i.addEventListener('click', () => {
        const index = i.dataset.index
        window.openGallery(index)
      })
    }
  }

  window.addEventToMansoryElements()
})
