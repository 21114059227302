export function fromScrollbarToProgress(s, controlPoints, allowExtrapolation = false) {
  const n = controlPoints.length
  if (n < 2) {
    throw new Error('At least two control points are required')
  }

  const s_first = controlPoints[0].s
  const s_last = controlPoints[n - 1].s

  if (s <= s_first) {
    if (!allowExtrapolation) {
      return controlPoints[0].p
    }
    const s0 = controlPoints[0].s
    const s1 = controlPoints[1].s
    const p0 = controlPoints[0].p
    const p1 = controlPoints[1].p
    const t = (s - s0) / (s1 - s0)
    const p = p0 + t * (p1 - p0)
    return p
  } else if (s >= s_last) {
    if (!allowExtrapolation) {
      return controlPoints[n - 1].p
    }
    const s0 = controlPoints[n - 2].s
    const s1 = controlPoints[n - 1].s
    const p0 = controlPoints[n - 2].p
    const p1 = controlPoints[n - 1].p
    const t = (s - s0) / (s1 - s0)
    const p = p0 + t * (p1 - p0)
    return p
  } else {
    for (let i = 0; i < n - 1; i++) {
      const s0 = controlPoints[i].s
      const s1 = controlPoints[i + 1].s
      const p0 = controlPoints[i].p
      const p1 = controlPoints[i + 1].p
      if ((s >= s0 && s <= s1) || (s >= s1 && s <= s0)) {
        const t = (s - s0) / (s1 - s0)
        const p = p0 + t * (p1 - p0)
        return p
      }
    }
  }
}
