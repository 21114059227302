import Hls from 'hls.js'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const body = document.querySelector('body')

  window.openVideoPopup = function (iframe, title, isVertical) {
    const verticalClass = isVertical ? 'video-popup_vertical' : ''
    const popupHtml = `
      <div class="video-popup ${verticalClass}">
        <div class="video-popup__bg"></div>
        <div class="video-popup__inner">
          <div class="video-popup__header">
            <div class="video-popup__title">${title}</div>
            <div class="video-popup__close"></div>
          </div>
          <div class="video-popup__content">
            <div class="video-popup__content-video">
              ${iframe}
            </div>
          </div>
        </div>
      </div>`

    document.body.insertAdjacentHTML('beforeend', popupHtml)
    setTimeout(() => {
      document
        .querySelector('.video-popup')
        ?.classList.add('video-popup_visible')
      body.classList.add('body-overflow-hidden')

      playVideoHls()
    }, 1)

    document
      .querySelector('.video-popup__close')
      .addEventListener('click', function () {
        document
          .querySelector('.video-popup')
          ?.classList.remove('video-popup_visible')
        body.classList.remove('body-overflow-hidden')

        setTimeout(() => {
          document.querySelector('.video-popup').remove()
        }, 300)
      })

    function playVideoHls() {
      const videoBlock = document.querySelector('.video-popup__content-video')
      const video = videoBlock.querySelector('video')

      if (video) {
        const videoSrc = video.getAttribute('data-src')

        if (!videoSrc) {
          return
        }

        const ext = videoSrc.split('.').pop()
        const isStreamingSrc = ext === 'm3u8'

        if (isStreamingSrc && Hls.isSupported()) {
          const hls = new Hls()

          setTimeout(() => {
            hls.loadSource(videoSrc)
            hls.attachMedia(video)
          })
        } else {
          video.src = videoSrc
          video
            .play()
            .then(() => {})
            .catch((error) => {
              console.log(error)
            })
        }
      }
    }
  }

  // window.openVideoPopup('<iframe width="720" height="405" src="https://rutube.ru/play/embed/73aa119d03848257b0506d92d9ebf15c" frameborder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>', 'Города-суперзвёзды. Уроки успешной трансформации');
})
