import { renderDay } from './renderDay'

export const renderMonth = (item) => {
  return `
    <div class="event-program__month">
      <div class="event-program__month-title">
        ${getMonthName(item.month)}
      </div>
      
      <div class="event-program__month-content">
        ${item.days.map((day) => renderDay(day)).join('')}
      </div>
    </div>
  `
}

function getMonthName(monthNumber) {
  const monthNames = [
    'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
    'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
  ]

  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error('Номер месяца должен быть в диапазоне от 1 до 12')
  }

  return monthNames[monthNumber - 1]
}
