/* dropdown */
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const toggles = document.querySelectorAll('.js-dropdownToggle')
  const menus = document.querySelectorAll('.js-dropdownMenu')

  function closeAllDropdowns(exceptMenu = null) {
    menus.forEach((menu) => {
      if (menu !== exceptMenu) {
        menu.closest('.js-dropdownToggle')?.classList.remove('dropdown_open')
      }
    })
  }

  toggles.forEach((toggle, index) => {
    toggle.addEventListener('click', (event) => {
      const menu = menus[index]
      const dropdownElement = menu.closest('.js-dropdownToggle')
      const isMenuVisible = dropdownElement.classList.contains('dropdown_open')
      closeAllDropdowns()

      if (isMenuVisible) {
        dropdownElement.classList.remove('dropdown_open')
      } else {
        dropdownElement.classList.add('dropdown_open')
      }

      event.stopPropagation()
    })
  })

  document.addEventListener('click', () => {
    closeAllDropdowns()
  })

  menus.forEach((menu) => {
    menu.addEventListener('click', (event) => {
      event.stopPropagation()
    })
  })

  document.querySelectorAll('.dropdown__item').forEach((item) => {
    item.addEventListener('click', (event) => {
      if (item.dataset.selectable === 'true') {
        event.preventDefault() // Предотвращаем переход по ссылке

        const toggle = item.closest('.js-dropdownToggle')
        const textElement = toggle.querySelector('.js-dropdownText')
        textElement.textContent = item.textContent // Обновляем текст переключателя на выбранный элемент
        closeAllDropdowns()
      }

      if (item.classList.contains('dropdown__item_active')) {
        closeAllDropdowns()
      }
    })
  })
})
