import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const swiperContainers = document.querySelectorAll('.partners')

  swiperContainers.forEach(container => {
    const partnersElement = container
    const navigationElement = container.querySelector('.partners__navigation')

    let checkNavigationVisibility

    const swiperInstance = new Swiper(container.querySelector('.js-swiperPartners'), {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: container.querySelector('.js-swiperPartnersButtonNext'),
        prevEl: container.querySelector('.js-swiperPartnersButtonPrev'),
        disabledClass: 'partners__button-disabled',
      },
      pagination: {
        el: container.querySelector('.js-swiperPartnersPagination'),
        clickable: true,
        bulletClass: 'partners__pagination-bullet',
        bulletActiveClass: 'partners__pagination-bullet-active',
      },
      slidesPerView: 'auto',
      spaceBetween: 40,
      breakpoints: {
        768: {
          spaceBetween: 80,
        },
        1280: {
          spaceBetween: 120,
        },
      },
      on: {
        init(swiper) {
          setTimeout(() => {
            checkNavigationVisibility(swiper)
          })
        },
      },
    })

    checkNavigationVisibility = (swiper) => {
      swiper.update()
      const slides = swiper.slides.length
      const containerWidth = partnersElement.clientWidth + 1
      const spaces = swiper.params.spaceBetween * (slides - 1)

      const slidesWidth = swiper.slides.reduce((total, slide) => {
        total += slide.clientWidth
        return total
      }, spaces)

      if (slidesWidth <= containerWidth) {
        navigationElement.classList.add('partners__navigation_hidden')
        partnersElement.classList.add('partners_center')
      } else {
        navigationElement.classList.remove('partners__navigation_hidden')
        partnersElement.classList.remove('partners_center')
      }
    }

    window.addEventListener('resize', () => checkNavigationVisibility(swiperInstance))
  })
})
