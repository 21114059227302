import { getScrollBarWidth } from '../../../js/utils/getScrollBarWidth'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const bodyPaddingRightOriginal = parseInt(
    window
      .getComputedStyle(document.body, null)
      .getPropertyValue('padding-right'),
  )
  const backdrop = document.createElement('div')

  document.addEventListener('click', (event) => {
    const target = event.target.closest('a[data-modal], button[data-modal]')

    if (target && target.dataset.modal === 'open') {
      showModal(
        document.getElementById(
          (target.hash || target.dataset.modalTarget).slice(1),
        ),
      )
    }

    if (
      (target && target.dataset.modal === 'close') ||
      event.target.matches('[aria-modal]')
    ) {
      closeAllModals()
    }

    function showModal(targetModalNode) {
      if (
        document.body.clientHeight - document.documentElement.clientHeight >
        0
      ) {
        document.body.style.paddingRight =
          bodyPaddingRightOriginal + getScrollBarWidth() + 'px'
      }
      document.body.classList.add('modal-opened')

      targetModalNode.classList.add('modal_visible')
      targetModalNode.style.display = 'block'
      targetModalNode.ariaModal = true
      targetModalNode.ariaHidden = null
      targetModalNode.setAttribute('role', 'dialog')

      backdrop.className = 'modal-backdrop'
      document.body.append(backdrop)
    }

    function closeAllModals() {
      document.body.classList.remove('modal-opened')
      document.body.style.paddingRight = ''

      document.querySelectorAll('.modal').forEach(function(modal) {
        modal.classList.remove('modal_visible')
        modal.style.display = 'none'
        modal.ariaModal = null
        modal.ariaHidden = true
        modal.removeAttribute('role')
      })

      backdrop.remove()
    }
  })
})
