import { ready } from '../../../../js/utils/documentReady'
import { isFileAccepted } from '../../../../js/utils/isFileAccepted'

ready(() => {
  document.querySelectorAll('.field-file').forEach(container => {
    const input = container.querySelector('.js-fileInput')
    const maxFiles = container.getAttribute('data-max-files')
    const previewContainer = container.querySelector('.field-file__preview-container')

    container.querySelector('.field-file__add-file-button').addEventListener('click', () => input.click())

    input.addEventListener('change', function() {
      handleFiles(this.files, container, maxFiles)
    })

    setupDragAndDrop(container, previewContainer, maxFiles)
  })
})

function setupDragAndDrop(container, previewContainer, maxFiles) {
  container.addEventListener('dragover', (event) => {
    event.preventDefault()
    container.classList.add('field-file_drag')
  })

  container.addEventListener('dragleave', (event) => {
    event.preventDefault()

    let relatedTarget = event.relatedTarget
    if (!container.contains(relatedTarget)) {
      container.classList.remove('field-file_drag')
    }
  })

  container.addEventListener('drop', (event) => {
    event.preventDefault()
    container.classList.remove('field-file_drag')

    const files = event.dataTransfer.files
    if (files.length) {
      handleFiles(files, container, maxFiles)
    }
  })
}

function handleFiles(files, container) {
  const previewContainer = container.querySelector('.field-file__preview-container')
  const inputElement = container.querySelector('.js-fileInput')
  const acceptValue = inputElement.getAttribute('accept')
  const acceptedFormats = acceptValue.split(',').map(format => format.trim())
  const maxSize = inputElement.dataset['maxSize']
  const maxSizeInBytes = maxSize * 1024 * 1024
  let messageError = ''

  Array.from(files).forEach(file => {
    if (maxSizeInBytes && (file.size > maxSizeInBytes)) {
      messageError = `Файл ${file.name} превышает максимальный размер: ${file.size / (1024 * 1024)} MB`
    }

    if (!isFileAccepted(file, acceptedFormats)) {
      messageError = `Файл ${file.name} имеет неподдерживаемый формат: ${file.type}`
    }

    const reader = new FileReader()

    reader.onload = function(e) {
      let previewContent
      if (file.type.startsWith('image/')) {
        previewContent =
          `<div class="field-file__preview-image">
            <img src="${e.target.result}" class="field-file__preview-img">
          </div>`
      } else {
        previewContent = `<div class="field-file__preview-text">${file.name}</div>`
      }

      const filePreviewHTML = `
        <div class="field-file__preview ${messageError ? 'field-file__preview_error' : ''}">
          <div class="field-file__preview-content">
            ${previewContent}
            <button class="field-file__preview-remove-button">Удалить</button>
          </div>
          ${messageError ? `<div class="field-file__preview-message-error">${messageError}</div>` : ''}
        </div>`

      previewContainer.insertAdjacentHTML('beforeend', filePreviewHTML)

      const lastAddedDeleteButton = previewContainer.lastElementChild.querySelector('.field-file__preview-remove-button')
      lastAddedDeleteButton.addEventListener('click', function() {
        this.closest('.field-file__preview').remove()
        removeFileFromInput(file, inputElement)
        updatePreviewContainerState(previewContainer, container)
      })

      updatePreviewContainerState(previewContainer, container)
    }

    reader.readAsDataURL(file)
  })
}

function removeFileFromInput(fileToRemove, inputElement) {
  const dataTransfer = new DataTransfer()
  const files = Array.from(inputElement.files)

  files.forEach(file => {
    if (file !== fileToRemove) {
      dataTransfer.items.add(file)
    }
  })

  inputElement.files = dataTransfer.files
}

function updatePreviewContainerState(previewContainer, container) {
  const fileValidationInput = container.querySelector('.js-fileValidation')
  const maxFiles = fileValidationInput.dataset.maxFiles

  const countFiles = previewContainer.querySelectorAll('.field-file__preview').length

  if (countFiles === 0) {
    container.classList.add('field-file_empty')
    fileValidationInput.value = 'empty'
  } else {
    container.classList.remove('field-file_empty')
    const isError = !!container.querySelector('.field-file__preview_error')

    if (isError) {
      fileValidationInput.value = 'true'
    } else {
      if (+countFiles <= +maxFiles) {
        fileValidationInput.value = 'false'
      } else {
        fileValidationInput.value = 'to-many'
      }
    }
  }

  $(fileValidationInput).valid()
}
