import { ready } from '../../utils/documentReady'

ready(() => {
  const searchPage = document.querySelector('.js-searchPage')
  const crossButton = document.querySelector('.js-searchInputCross')
  const searchInput = document.querySelector('.js-searchInput')

  const changeVisibleCrossButton = () => {
    if (searchInput.value) {
      crossButton.style.display = 'block'
    } else {
      crossButton.style.display = 'none'
    }
  }

  if (searchPage) {
    crossButton.addEventListener('click', () => {
      searchInput.value = ''
      crossButton.style.display = 'none'
    })

    searchInput.addEventListener('input', () => {
      changeVisibleCrossButton()
    })

    changeVisibleCrossButton()
  }
})
