import Swiper from 'swiper'
import { Grid, Navigation } from 'swiper/modules'
import { ready } from '../../../js/utils/documentReady'

ready(() => {
  new Swiper('.js-swiperSpeakers', {
    modules: [Navigation, Grid],
    navigation: {
      nextEl: '.js-swiperSpeakersButtonNext',
      prevEl: '.js-swiperSpeakersButtonPrev',
      disabledClass: 'swiper-speakers__button-disabled',
    },
    grid: {
      rows: 2,
      fill: 'row',
    },
    spaceBetween: 64,
    breakpoints: {
      320: {
        slidesPerView: 1,
        grid: {
          rows: 1,
        },
      },
      768: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
      1900: {
        slidesPerView: 4,
      },
    },
    on: {
      init() {
        this.update()
      },
    },
  })
})
