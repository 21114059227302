import { ready } from '../../../../../js/utils/documentReady'

ready(() => {
  $.validator.addMethod('stringEmail', function(value, element) {
    return this.optional(element) || String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9а-яА-ЯёЁ]+\.)+[a-zA-Z0-9а-яА-ЯёЁ]{2,}))$/,
      )
  }, function() {
    return 'Введите корректный email'
  })
})
