
import { ready } from '../../utils/documentReady'

ready(() => {
  const fillContainerElements = document.querySelectorAll('.js-fillContainer')

  if (fillContainerElements.length) {
    function updateElementWidths(elements) {
      for (let element of elements) {
        const container = element.closest('.container')
        if (container) {
          const containerWidth = container.getBoundingClientRect().width
          const computedStyle = window.getComputedStyle(container)
          const paddingLeft = parseFloat(computedStyle.paddingLeft)
          const paddingRight = parseFloat(computedStyle.paddingRight)
          const widthWithoutPadding = containerWidth - paddingLeft - paddingRight

          element.style.width = `${widthWithoutPadding}px`
        }
      }
    }

    updateElementWidths(fillContainerElements)
    window.addEventListener('resize', () => updateElementWidths(fillContainerElements))
  }
})
