import { ready } from '../../../js/utils/documentReady'

function initLazyImage() {
  const lazyPictures = document.querySelectorAll('picture.js-lazyPicture')

  if ('IntersectionObserver' in window) {
    let lazyPictureObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let picture = entry.target
          let sources = picture.querySelectorAll('source')
          let img = picture.querySelector('img')

          sources.forEach(source => {
            source.srcset = source.dataset.srcset
          })
          img.src = img.dataset.src

          picture.classList.remove('js-lazyPicture')
          lazyPictureObserver.unobserve(picture)
        }
      })
    }, { root: null, rootMargin: '75px 75px 75px 75px', threshold: 0  })

    lazyPictures.forEach(picture => {
      lazyPictureObserver.observe(picture)
    })
  } else {
    lazyPictures.forEach(picture => {
      let sources = picture.querySelectorAll('source')
      let img = picture.querySelector('img')

      sources.forEach(source => {
        source.srcset = source.dataset.srcset
      })
      img.src = img.dataset.src

      picture.classList.remove('js-lazyPicture')
    })
  }
}

ready(() => {
  initLazyImage()
})

window.initLazyImage = initLazyImage
