import { getFileExtension } from './getFileExtension'

export function isFileAccepted(file, acceptedFormats) {
  let isAccepted = false

  acceptedFormats.forEach(acceptFormat => {
    if (acceptFormat === 'image/*' && file.type.startsWith('image/')) {
      isAccepted = true
    } else if (acceptFormat === 'audio/*' && file.type.startsWith('audio/')) {
      isAccepted = true
    } else if (acceptFormat.startsWith('.') && getFileExtension(file.name) === acceptFormat.slice(1)) {
      isAccepted = true
    } else if (acceptFormat === file.type) {
      isAccepted = true
    }
  })

  return isAccepted
}
