import { ready } from '../../../js/utils/documentReady'

ready(() => {
  // function alignElements() {
  //   const headerRight = document.querySelector('.header-mock__right')
  //   const bannerTextDescription = document.querySelector('.banner-text__description')
  //
  //   if (headerRight && bannerTextDescription) {
  //     headerRight.style.marginLeft = ''
  //     const bannerTextDescriptionRect = bannerTextDescription.getBoundingClientRect()
  //     const headerRightRect = headerRight.getBoundingClientRect()
  //
  //     const marginLeft = bannerTextDescriptionRect.left - headerRightRect.left
  //     headerRight.style.marginLeft = `${marginLeft}px`
  //   }
  // }
  //
  // alignElements()
  // window.addEventListener('resize', alignElements)
})
