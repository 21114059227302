export const initMapOverlay = () => {
  const mapOverlay = document.querySelector('.js-mapOverlay')
  const mapComponent = document.querySelector('.js-ymap')
  let isMapTouched = false

  const enableScroll = () => {
    if (isMapTouched) {
      document.removeEventListener('touchstart', handleOutsideTouch)
      isMapTouched = false
    }
  }

  const handleOutsideTouch = (event) => {
    if (!event.target.closest('.yandex-map')) {
      mapOverlay.style.display = 'block'
      enableScroll()
    }
  }

  const disabledScroll = () => {
    if (!isMapTouched) {
      document.addEventListener('touchstart', handleOutsideTouch)
      isMapTouched = true
    }
  }

  if (mapComponent) {
    mapComponent.parentElement.parentElement.addEventListener('touchstart', () => {
      disabledScroll()
    })
  }

  if (mapOverlay) {
    mapOverlay.addEventListener('click', () => {
      mapOverlay.style.display = 'none'
      enableScroll()
    })
  }
}
