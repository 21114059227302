export const styleLoader = () => {
  // const originalConsoleLog = console.log;
  //
  // let isListening = true;
  //
  // console.log = function(...args) {
  //   originalConsoleLog.apply(console, args);
  //
  //   if (isListening) {
  //     console.warn(args)
  //     if (args.includes('styles customization config update: 128 blocks')) {
  //       console.warn('style load');
  //
  //       isListening = false;
  //
  //       console.log = originalConsoleLog;
  //     }
  //   }
  // };
}
