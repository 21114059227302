import { ready } from './documentReady'

ready(() => {
  const marginsElement = document.querySelector('.default-margins')

  if (marginsElement) {
    const previousElement = marginsElement.previousElementSibling

    if (previousElement && previousElement.classList.contains('bg-overlay')) {
      const firstSection = marginsElement.querySelector('section')

      if (firstSection) {
        if (!(firstSection.classList.contains('bg-white-light') || firstSection.classList.contains('bg-white'))) {
          previousElement.style.display = 'none'
        } else {
          if(firstSection.classList.contains('bg-white-light')) {
            previousElement.style.backgroundColor = '#E9F1FF'
          } else if(firstSection.classList.contains('bg-white')) {
            previousElement.style.backgroundColor = '#fff'
          }
        }
      }
    }

    const emptyOverlay = marginsElement.querySelectorAll('.bg-overlay_bottom-empty')

    for(let element of emptyOverlay) {
      const nextElement = element.nextElementSibling

      if(nextElement && nextElement.tagName.toLowerCase() === 'section' && !nextElement.classList.contains('bg-none')) {
        element.classList.remove('bg-overlay')
        element.classList.remove('bg-overlay_bottom-empty')
      }
    }

    const overlays = marginsElement.querySelectorAll('.bg-overlay')

    for(let element of overlays) {
      if(!element.classList.contains('bg-overlay_bottom-empty')) {
        const nextElement = element.nextElementSibling

        if(nextElement && nextElement.tagName.toLowerCase() === 'section') {
          if(nextElement.classList.contains('bg-white-light')) {
            element.style.backgroundColor = '#E9F1FF'
          } else if(nextElement.classList.contains('bg-white')) {
            element.style.backgroundColor = '#fff'
          } else if(nextElement.classList.contains('bg-none')) {
            element.style.display = 'none'
          }
        }
      }
    }
  }
})
