export function generateCalendar(startDate, endDate, dates, isDropActiveDay) {
  const calendar = []

  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    throw new Error('Both startDate and endDate should be instances of Date.')
  }

  let currentDate = new Date(startDate.getTime())

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  let foundActiveDay = false
  let firstAvailableDay = null

  while (currentDate <= endDate) {
    const month = []
    const year = currentDate.getFullYear()
    const monthIndex = currentDate.getMonth()

    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate()

    const startDay = currentDate.getMonth() === startDate.getMonth() && currentDate.getFullYear() === startDate.getFullYear()
      ? currentDate.getDate()
      : 1

    const endDay = currentDate.getMonth() === endDate.getMonth() && currentDate.getFullYear() === endDate.getFullYear()
      ? endDate.getDate()
      : daysInMonth

    for (let day = startDay; day <= endDay; day++) {
      const date = new Date(year, monthIndex, day)
      date.setHours(0, 0, 0, 0)
      const dayOfWeek = date.getDay()

      const formattedDate = `${String(day).padStart(2, '0')}.${String(monthIndex + 1).padStart(2, '0')}.${year}`
      const isDisabled = !dates.includes(formattedDate)

      let isActive = false
      if (!foundActiveDay && !isDisabled && date >= today && !isDropActiveDay) {
        isActive = true
        foundActiveDay = true
      }

      if (!isDisabled && !firstAvailableDay) {
        firstAvailableDay = { year, month: monthIndex + 1, day }
      }

      month.push({
        day,
        dayOfWeek,
        isWeekend: dayOfWeek === 0 || dayOfWeek === 6,
        isActive: isActive,
        formattedDate: formattedDate,
        isDisabled: isDisabled,
        isToday: date.getTime() === today.getTime()
      })
    }

    calendar.push({ year, month: monthIndex + 1, days: month })

    // Сначала должны сбросить день, затем месяц.
    currentDate.setDate(1) // Устанавливаем день в начало месяца
    currentDate.setMonth(currentDate.getMonth() + 1)
  }

  if (!foundActiveDay && firstAvailableDay && !isDropActiveDay) {
    const { year, month, day } = firstAvailableDay
    const targetMonth = calendar.find(m => m.year === year && m.month === month)
    if (targetMonth) {
      const targetDay = targetMonth.days.find(d => d.day === day)
      if (targetDay) {
        targetDay.isActive = true
      }
    }
  }

  return calendar
}
