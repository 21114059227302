import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const options = document.querySelectorAll('.js-switchAreasOption')
  const switchBlock = document.querySelector('.js-switchAreas')

  if (switchBlock) {
    options.forEach(option => {
      option.addEventListener('click', () => {
        const currentId = option.getAttribute('data-id')
        const isCurrentActiveElement = switchBlock.getAttribute('data-active-id')

        if (isCurrentActiveElement === currentId) return

        switchBlock.setAttribute('data-active-id', currentId)
      })
    })
  }
})
