import { ready } from '../../../../js/utils/documentReady'

const mainVideo = () => {
  document.querySelectorAll('.bitrix-content video').forEach((video) => {
    console.log(video, 'video')
    const parent = video.parentNode
    const videoWrapper = document.createElement('div')
    videoWrapper.classList.add('bitrix-content-video')

    const previewContainer = document.createElement('div')
    previewContainer.innerHTML = '<div class="bitrix-content-video__button"></div>'
    previewContainer.classList.add('bitrix-content-video__preview')

    const previewImage = document.createElement('img')
    previewImage.src = video.getAttribute('preview')
    previewImage.alt = 'preview'

    previewContainer.appendChild(previewImage)

    const videoClone = video.cloneNode(true)
    videoWrapper.appendChild(videoClone)
    videoWrapper.appendChild(previewContainer)

    parent.replaceChild(videoWrapper, video)

    if (video.getAttribute('preview') === null || video.getAttribute('preview') === undefined || video.getAttribute('preview') === '') {
      previewContainer.classList.add('bitrix-content-video__preview-img-hidden')
    }

    function onPreviewClick() {
      previewContainer.classList.add('bitrix-content-video__preview_hidden')

      videoClone.play()
    }

    previewContainer.addEventListener('click', onPreviewClick)
  })
}

ready(() => {
  mainVideo()
  window.initMainVideo = () => mainVideo()
})
