import Hls from 'hls.js'
import { ready } from '../../utils/documentReady'

ready(() => {
  const videoContainer = document.querySelector('.js-mainGradientVideo')

  if (videoContainer) {
    const video = videoContainer.querySelector('video')

    const videoSrc = video.getAttribute('data-src')

    const onTimeUpdate = () => {
      if (video.currentTime >= 0.1) {
        videoContainer.classList.add('main-gradient_visible')
        video.removeEventListener('timeupdate', onTimeUpdate)
      }
    }

    if (videoSrc && Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(videoSrc)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play().then(() => {
          video.addEventListener('timeupdate', onTimeUpdate)
        })
      })
    } else {
      video.src = videoSrc
      video.play().then(() => {
        video.addEventListener('timeupdate', onTimeUpdate)
      }).catch((error) => {
        console.log(error)
      })
    }
  }
})
