import { ready } from '../../../js/utils/documentReady'
import QRCode from 'qrcode'

ready(() => {
  const generateQR = () => {
    const qrCodeElements = document.querySelectorAll('.ticker-detail__qr-code-image')

    qrCodeElements.forEach(element => {
      const qrData = element.getAttribute('data-qr')

      if (qrData) {

        QRCode.toString(qrData, { type: 'svg', errorCorrectionLevel: 'H' }, (err, svg) => {
          if (err) {
            console.error(err)
            return
          }

          element.innerHTML = svg
        })
      }
    })
  }

  generateQR()

  window.generateQR = generateQR
})
