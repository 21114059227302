import { ready } from '../../../js/utils/documentReady'
import { getEventProgram } from './getEventProgram'
import { renderContent } from './renderContent'

ready(async () => {
  const containerElement = document.querySelector('.event-program-form')

  if (containerElement) {
    const input = containerElement.querySelector('.event-program__input')
    let currentRequestId = 0

    input.onchange = async (e) => {
      currentRequestId++
      const requestId = currentRequestId
      const data = await getEventProgram(e.target.value)

      if (requestId === currentRequestId) {
        renderContent(data, '.event-program-form__content')
      }
    }
  }
})
