import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const rows = document.querySelectorAll('.js-faqQuestionTitleContainer')

  rows.forEach(el => {
    el.addEventListener('click', () => {
      const currentState = el.getAttribute('data-answer-show') === 'true'
      const newState = !currentState
      el.setAttribute('data-answer-show', String(newState))
    })
  })
})
