import { ready } from '../../../js/utils/documentReady'
import flatpickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  const dataPickerInput = document.querySelector('#datepicker')

  if(dataPickerInput) {
    const form = document.querySelector('.program-picker-popup__content')
    const submitButton = form.querySelector('button[type="submit"]') // Найдите кнопку submit
    const resetButton = form.querySelector('button[type="reset"]')
    let flatpickrInstance

    function checkFormValidity() {
      const formData = new FormData(form)
      const formValues = {}
      formData.forEach((value, key) => {
        formValues[key] = value
      })

      if (!formValues.time || !formValues.session) {
        submitButton.disabled = true
      } else {
        submitButton.disabled = false
      }
    }

    form.addEventListener('change', () => {
      checkFormValidity()
    })

    resetButton.addEventListener('click', (e) => {
      e.preventDefault()

      flatpickrInstance.clear()

      renderVisitingTime([])

      const sessionInputs = form.querySelectorAll('input[name="session"]')
      sessionInputs.forEach(input => input.checked = false)

      checkFormValidity()
    })

    const initialFormData = new FormData(form)
    const initialFormValues = {}
    initialFormData.forEach((value, key) => {
      initialFormValues[key] = value
    })

    if (!initialFormValues.time || !initialFormValues.session) {
      submitButton.disabled = true
    } else {
      submitButton.disabled = false
    }

    function isDateEnabled(date, enabledDates) {
      return enabledDates.some(enabledDate => {
        return date.getFullYear() === enabledDate.getFullYear() &&
          date.getMonth() === enabledDate.getMonth() &&
          date.getDate() === enabledDate.getDate()
      })
    }

    const renderVisitingTime = (sessions) => {
      const eventTimeElement = document.querySelector('.program-picker-popup__visiting-time')

      if(sessions?.length) {
        eventTimeElement.classList.remove('program-picker-popup__visiting-time_hidden')
        const radioButtonsHTML = sessions.map(session =>`
        <label class="program-picker-popup__visiting-time-item">
          <input type="radio" name="session" value="${session.value}">
          <div>${session.label}</div>
        </label>`
        ).join('')

        eventTimeElement.innerHTML =`
      <div class="program-picker-popup__visiting-time-title">Время посещения</div>
      <div class="program-picker-popup__visiting-time-list">
        ${radioButtonsHTML}
      </div>`
      } else {
        eventTimeElement.classList.add('program-picker-popup__visiting-time_hidden')
      }
    }

    let enabledDates
    let days

    window.updateEnabledDatesProgramPicker = () => {
      days = JSON.parse(dataPickerInput.dataset.days)

      enabledDates = days.map(day => {
        const [dayPart, monthPart, yearPart] = day.day.split('.')
        return new Date(yearPart, monthPart - 1, dayPart)
      })

      if(flatpickrInstance) {
        flatpickrInstance.set('disable', [
          function(date) {
            return !isDateEnabled(date, enabledDates)
          }
        ])
      }

      renderVisitingTime([])
      submitButton.disabled = true
    }

    window.updateEnabledDatesProgramPicker()

    flatpickrInstance = flatpickr(dataPickerInput, {
      mode: 'single',
      inline: true,
      showMonths: 2,
      minDate: '2024-08-01', // Используйте формат ISO для minDate и maxDate
      maxDate: '2024-09-30',
      locale: Russian,
      yearSelectorType: 'static',
      defaultDate: '01-08-2024',
      dateFormat: 'd.m.Y',
      onChange: function(selectedDates, dateStr) {
        const selectedDay = days.find(day => day.day === dateStr)
        if (selectedDay) {
          renderVisitingTime(selectedDay.sessions)
        }
      },
      disable: [
        function(date) {
          return !isDateEnabled(date, enabledDates)
        }
      ]
    })

    const closeElements = document.querySelectorAll('.js-closePickerProgramPopup')
    const popupElement = document.querySelector('.program-picker-popup')
    const header = document.querySelector('.header')
    const { isMobile } = getSize()

    for(let i of closeElements) {
      i.addEventListener('click', () => {
        popupElement.classList.remove('program-picker-popup_open')
        document.body.classList.remove('body-overflow-hidden')

        if(isMobile) {
          header.classList.remove('header_static')
        }
      })
    }

    window.openPopupPicker = () => {
      popupElement.classList.add('program-picker-popup_open')
      document.body.classList.add('body-overflow-hidden')

      if(isMobile) {
        header.classList.add('header_static')
      }
    }
  }
})
