import { ready } from '../../../js/utils/documentReady'
import {getSize} from '../../../js/utils/breakpoints'

ready(() => {
  const customSelects = document.querySelectorAll('.field-custom-select')

  customSelects.forEach(customSelect => {
    const input = customSelect.querySelector('.field-custom-select__input')
    const inputHitBox = customSelect.querySelector('.field-custom-select__input-hit-box')
    const options = customSelect.querySelector('.field-custom-select__options')
    const optionElements = options.querySelectorAll('.field-custom-select__option')
    const closeIconElement = options.querySelector('.field-custom-select__options-close')
    const closeButtonElement = options.querySelector('.field-custom-select__options-button')

    if (input.value) {
      customSelect.classList.add('field-custom-select_selected')
    }

    closeIconElement.addEventListener('click', () => {
      inputHitBox.click()
    })

    closeButtonElement.addEventListener('click', () => {
      inputHitBox.click()
    })

    inputHitBox.addEventListener('click', () => {
      if (customSelect.classList.contains('field-custom-select_open')) {
        options.style.height = `${options.scrollHeight}px`
        requestAnimationFrame(() => {
          options.style.height = '0'
        })
      } else {
        options.style.height = 'auto'
        const contentHeight = options.scrollHeight
        options.style.height = '0'
        requestAnimationFrame(() => {
          options.style.height = `${contentHeight}px`
        })
      }

      customSelect.classList.toggle('field-custom-select_open')
    })

    optionElements.forEach(option => {
      const trimmedText = option.textContent.trim()

      option.addEventListener('click', (e) => {
        const { isMobile } = getSize()
        e.preventDefault()

        if (input.value === trimmedText) {
          // input.value = '';
          // customSelect.classList.remove('field-custom-select_selected');
        } else {
          options.querySelector('.field-custom-select__option_active')?.classList.remove('field-custom-select__option_active')
          input.value = trimmedText
          customSelect.classList.add('field-custom-select_selected')
          option.classList.add('field-custom-select__option_active')

          const changeEvent = new Event('change')
          input.dispatchEvent(changeEvent)
          $(input).valid()
        }

        if(!isMobile) {
          inputHitBox.click()
        }
      })
    })

    document.addEventListener('click', (event) => {
      const { isMobile } = getSize()
      if (!customSelect.contains(event.target) && !isMobile) {
        customSelect.classList.remove('field-custom-select_open')
      }
    })
  })
})
