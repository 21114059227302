import { ready } from '../../../js/utils/documentReady'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import { executeOnScroll } from '../../../js/utils/executeOnScroll'
import {loadingSlides} from '../../../js/utils/loadingSlides'

ready(() => {
  const swiperContainer = document.querySelector('.article-alfa-slider')

  if (swiperContainer) {
    executeOnScroll(swiperContainer, () => {

      const createSwiper = (selector, additionalOptions = {}) => {
        let startX = 0

        return new Swiper(selector, {
          followFinger: false,
          modules: [Autoplay],
          loop: true,
          allowTouchMove: false,
          speed: 500,
          centeredSlides: true,
          // slidesPerGroup: 1,
          breakpoints: {
            320: {
              spaceBetween: 20,
              slidesPerView: 4.6,
            },
            768: {
              slidesPerView: 5.9,
              spaceBetween: 28,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 32,
            },
            1900: {
              slidesPerView: 6,
              spaceBetween: 32,
            },
          },
          on: {
            init() {
              loadingSlides(this.activeIndex, this.slides, 4, 6)
              this.el.style.cursor = 'grab'
            },
            touchStart() {
              startX = event.pageX
              stopManualAutoplay()
              this.el.style.cursor = 'grabbing'
            },
            touchEnd(swiper, event) {
              const pageX = event.changedTouches?.[0]?.pageX || event.pageX
              this.el.style.cursor = 'grab'
              if(pageX) {
                const diffX = startX - pageX
                if (Math.abs(diffX) > 32) {
                  if (diffX < 0) {
                    this.slidePrev()
                  } else {
                    this.slideNext()
                  }
                }
                startManualAutoplay()
              }
            },
            slideChangeTransitionStart() {
              this.el.style.pointerEvents = 'none'
              loadingSlides(this.activeIndex, this.slides, 4, 6)
            },
            slideChangeTransitionEnd() {
              this.el.style.pointerEvents = ''
            },
          },
          ...additionalOptions,
        })
      }
      const altPage = document.querySelector('.a-hotel')
      const swiper = createSwiper('.js-alfaSlider', {
        breakpoints: {
          320: {
            slidesPerView: 4,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1280: {
            slidesPerView: 4.74,
            spaceBetween: !altPage ? 32 : 24,
          },
          1900: {
            slidesPerView: 6.4,
            spaceBetween: 32,
          },
        },
      })

      let autoplayInterval

      const startManualAutoplay = () => {
        autoplayInterval = setInterval(() => {
          swiper.slideNext()
        }, 3000)
      }

      const stopManualAutoplay = () => {
        clearInterval(autoplayInterval)
      }

      swiper.on('slideChange', () => {
        setTimeout(() => {
          const totalSlides = swiper.slides.length

          let diff = swiper.previousRealIndex - swiper.realIndex

          if (Math.abs(diff) > totalSlides / 2) {
            if (diff > 0) {
              diff = diff - totalSlides
            } else {
              diff = diff + totalSlides
            }
          }
        }, 10)
      })
      
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            startManualAutoplay()
          } else {
            stopManualAutoplay()
          }
        })
      }, { threshold: 0 })

      observer.observe(swiperContainer)
    })
  }
})
