export function fromProgressToScrollbar(p, controlPoints) {
  p = Math.min(Math.max(p, 0), 1)

  for (let i = 0; i < controlPoints.length - 1; i++) {
    const p0 = controlPoints[i].p
    const p1 = controlPoints[i + 1].p
    const s0 = controlPoints[i].s
    const s1 = controlPoints[i + 1].s

    if (p >= p0 && p <= p1) {
      const t = (p - p0) / (p1 - p0)
      const s = s0 + t * (s1 - s0)
      return s
    }
  }
  return controlPoints[controlPoints.length - 1].s
}
