import { ready } from '../../../js/utils/documentReady'

ready(() => {
  const accordions = document.querySelectorAll('.accordion')

  accordions.forEach((accordion) => {
    accordion.addEventListener('click', () => {
      const accordionContent = accordion.querySelector('.accordion__content')

      if (!accordion.classList.contains('animation') && accordionContent) {

        accordion.classList.add('animation')

        if (accordion.classList.contains('accordion_open')) {
          accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`

          setTimeout(() => {
            accordionContent.style.maxHeight = '0px'
          })

          accordion.classList.remove('accordion_open')
        } else {
          accordionContent.style.maxHeight = '0px'

          setTimeout(() => {
            accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`

            accordion.classList.add('accordion_open')
          })
        }

        setTimeout(() => {
          accordionContent.style.height = ''
          accordion.classList.remove('animation')
        }, 300)
      }
    })
  })

  accordions.forEach((accordion) => {
    const accordionContent = accordion.querySelector('.accordion__content')
    accordionContent.addEventListener('click', (e) => {
      e.stopPropagation()
    })
  })
})

